import React, { useEffect } from "react";
import { Navigate, Outlet, useNavigate, useParams } from "react-router-dom";

import { LoadingSpinner } from "@scandotcom/react";
import { Referral } from "@services/scan/types/common";
import Routes from "@utils/Routes";
import { useReferral } from "hooks/useReferral";
import { isReferralDone } from "utils/referralUtils";

export const STEPS = [
  "patient_information",
  "scan_details",
  "imaging-provider",
  "safety-questions",
];

export function getReferralDraftStepLink(referral: Referral) {
  const step = STEPS[referral.completedStep || 0];
  return `/referrals/${referral.id}/${step}`;
}

export const WithCompletedStepRestriction = () => {
  const navigate = useNavigate();
  const { referralId } = useParams();

  const currentStep = STEPS.findIndex((step) =>
    location.pathname.endsWith(step)
  );

  const { data: referral, isLoading } = useReferral({ referralId });

  useEffect(() => {
    if (isReferralDone(referral)) {
      navigate(Routes.referral(referral!.id));
      return;
    }
  }, [referral]);

  if (!referral || isLoading) return <LoadingSpinner className="my-8" />;

  return currentStep <= referral.completedStep ? (
    <Outlet />
  ) : (
    <Navigate to={STEPS[Math.max(referral.completedStep, 0)]} />
  );
};
