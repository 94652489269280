import { StylesObj } from "@reactour/tour";
import { PopoverStylesObj } from "@reactour/popover";
import { MaskStylesObj } from "@reactour/mask";

const opositeSide = {
  top: "bottom",
  bottom: "top",
  right: "left",
  left: "right",
};

function doArrow(position, verticalAlign, horizontalAlign) {
  if (!position || position === "custom") {
    return {};
  }

  const width = 16;
  const height = 16;
  const color = "white";
  const isVertical = position === "top" || position === "bottom";
  const spaceFromSide = 8;

  const obj = {
    [`--rtp-arrow-${
      isVertical ? opositeSide[horizontalAlign] : verticalAlign
    }`]: height + spaceFromSide + "px",
    [`--rtp-arrow-${opositeSide[position]}`]: -height + 2 + "px",
    [`--rtp-arrow-border-${isVertical ? "left" : "top"}`]: `${
      width // 2
    }px solid transparent`,
    [`--rtp-arrow-border-${isVertical ? "right" : "bottom"}`]: `${
      width // 2
    }px solid transparent`,
    [`--rtp-arrow-border-${position}`]: `${height}px solid ${color}`,
  };

  return obj;
}

export const styles = (
  isMobile: boolean
): StylesObj & PopoverStylesObj & MaskStylesObj => ({
  popover: (base, state) => ({
    ...base,
    padding: 0,
    borderRadius: "8px",
    ...doArrow(
      state!.positionSide,
      state!.verticalAlign,
      state!.horizontalAlign
    ),
    transition: isMobile
      ? "transform 300ms ease 0s"
      : "transform 300ms ease 0s, max-width 300ms ease 0s",
  }),
  maskWrapper: (base) => ({ ...base, opacity: 0.25 }),
  maskArea: (base) => ({
    ...base,
    rx: 10,
  }),
});
