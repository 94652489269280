import { client } from "../../client";
import type { RawTimeSlot, RawValidationError } from "../../types/requests";
import { prepValidationErrors } from "../../utils/prepValidationErrors";
import { prepTimeSlots } from "../../utils/prepTimeSlots";

interface RawResponse {
  success: boolean;
  validation_errors: RawValidationError[];
  data: RawTimeSlot[];
}

export async function getTimeSlots(referralId?: string) {
  if (!referralId) {
    throw new Error("No referral ID provided.");
  }

  const request = await client<RawResponse>({
    method: "get",
    url: `/api/v1/referrals/${referralId}/time_slots`,
  });

  const success = Boolean(request?.success);
  const timeSlots = prepTimeSlots(request?.data);
  const errors = prepValidationErrors(request?.validation_errors);

  return { success, timeSlots, errors };
}
