import React from "react";

function UseCase() {
  return (
    <div id="use-case">
      <p className="mt-6 text-xl font-medium">Does your patient need a scan?</p>
      <div className="py-4 text-gray-500">
        <p className="mb-4">
          There are occasions where a physical examination is not comprehensive
          enough to identify or rule out certain disorders or abnormalities.
          Common conditions which can show up in scan results include:
        </p>
        <ul className="list-disc pl-4">
          <li className="mb-4">Tennis elbow, golfer’s elbow</li>
          <li className="mb-4">Rotator cuff tendinopathy, bursitis, tears</li>
          <li className="mb-4">Osteoarthritis</li>
          <li className="mb-4">Hamstring injuries</li>
          <li className="mb-4">
            Wrist and hand tendinopathy, carpal tunnel syndrome
          </li>
          <li className="mb-4">
            Lumps and their composition, such as tumours and cysts
          </li>
          <li className="mb-4">Tissue damage</li>
          <li className="mb-4">Fractures</li>
        </ul>
        <p className="mb-4">
          Our expert clinicians assess every referral we receive. Based on the
          notes you provide, they will let you and your patient know if they
          feel a different scan may be better suited in each circumstance.
        </p>
      </div>
    </div>
  );
}

export default UseCase;
