import React from "react";
import { Heading, TextInput } from "@scandotcom/react";
import { ButtonLink } from "ui/ButtonLink";
import classNames from "classnames";
import Routes from "utils/Routes";
import { IconSearch } from "@tabler/icons";
import { trackEvent } from "services/analytics";

interface ListPageHeaderProps {
  updateSearch?: (query: string) => void;
  className?: string;
  resource?: keyof typeof VariantStrings;
  isEmbedded?: boolean;
}

const clickNewPatient = () => {
  trackEvent("new_patient");
};

const clickNewReferral = () => {
  trackEvent("new_referral");
};

const VariantStrings = {
  patients: {
    ctaDataTest: "new_patient",
    ctaHref: "/patients/new",
    ctaText: "New patient",
    placeholder: "Search by name, phone or email",
    trackEvent: clickNewPatient,
  },
  referrals: {
    ctaDataTest: "new_referral",
    ctaHref: Routes.newReferral,
    ctaText: "New referral",
    placeholder: "Search for Patient or Referral ID",
    trackEvent: clickNewReferral,
  },
} as const;

export const ListPageHeader = ({
  updateSearch,
  className,
  isEmbedded,
  resource = "referrals",
}: ListPageHeaderProps) => {
  const wrapperStyles = classNames(
    "flex w-full flex-col gap-4 md:flex-row justify-end gap-4 mt-8 mb-6 items-center",
    className
  );

  const { placeholder, ctaDataTest, ctaText, ctaHref, trackEvent } =
    VariantStrings[resource];

  return (
    <div className={wrapperStyles}>
      <Heading
        variant="h1"
        size={isEmbedded ? "xl" : "4xl"}
        className="capitalize tracking-tight md:mr-auto"
      >
        {resource}
      </Heading>

      {updateSearch && !isEmbedded && (
        <TextInput
          label="Search"
          rounded="full"
          type="search"
          size="sm"
          leftIcon={<IconSearch />}
          placeholder={placeholder}
          onChange={(e) => updateSearch(e.target.value)}
          className="w-full flex-grow md:max-w-sm"
          hideLabel
        />
      )}

      {!isEmbedded && (
        <ButtonLink
          data-test={ctaDataTest}
          to={ctaHref}
          className="flex w-full items-center justify-center md:w-fit"
          onClick={trackEvent}
        >
          {ctaText}
        </ButtonLink>
      )}
    </div>
  );
};
