import * as React from "react";

import { StepType } from "@reactour/tour";
import { styles as globalStyles } from "./styles";
import FirstStep from "./FirstStep";
import { createStep } from "./createStep";
import LastStep from "./LastStep";

const createSteps = (isMobile: boolean, isBurgerMenu: boolean): StepType[] => {
  const styles = globalStyles(isMobile);

  return [
    {
      selector: "body",
      bypassElem: true,
      content: FirstStep,
      position: (target, popover) => [
        document.body.clientWidth / 2 - (popover.right - popover.left) / 2,
        target.windowHeight / 2 - target.height / 2,
      ],
      styles: {
        ...styles,
        maskArea: () => ({ clipPath: undefined }),
        popover: (base, state) => {
          return {
            ...styles.popover!(base, state),
            transition: "0s",
            maxWidth: "min(500px, 100%)",
          };
        },
      },
    },
    {
      selector: ".ref__history",
      content: (props) =>
        createStep(props)({
          header: "Referral history",
          body: "View the status of your referral here.",
        }),
      styles: {
        ...styles,
        popover: (base, state) => ({
          ...styles.popover!(base, {
            ...state,
            positionSide: isMobile ? "bottom" : "left",
            horizontalAlign: isMobile ? "right" : "left",
            verticalAlign: "top",
          }),

          maxWidth: "min(500px, 100%)",
        }),
      },
      position: (target, popover) => {
        if (!isMobile) return [target.left - target.width - 24, target.y];

        return [
          target.windowWidth / 2 - (popover.right - popover.left) / 2,
          target.windowHeight - target.height - 16,
        ];
      },
    },
    {
      selector: ".ref__details",
      content: (props) =>
        createStep(props)({
          header: "Scan details",
          body: "Here, you'll find a recap of the scan details and your referral notes. You can click 'edit' if you need to make any amendments.",
        }),
      styles: {
        ...styles,
        popover: (base, state) => ({
          ...styles.popover!(base, {
            ...state,
            positionSide: isMobile ? "bottom" : "right",
            horizontalAlign: isMobile ? "right" : "left",
            verticalAlign: "top",
          }),
        }),
      },
      position: (target, popover) => {
        if (!isMobile) return [target.right + 24, target.y];

        return [
          target.windowWidth / 2 - (popover.right - popover.left) / 2,
          target.windowHeight - target.height - 16,
        ];
      },
    },
    {
      selector: ".ref__patient",
      content: (props) =>
        createStep(props)({
          header: "Patient details",
          body: "This section shows the details of the patient you've referred for this scan. Easily update their information by clicking 'edit'.",
        }),
      styles: {
        ...styles,
        popover: (base, state) => ({
          ...styles.popover!(base, {
            ...state,
            positionSide: isMobile ? "bottom" : "right",
            horizontalAlign: isMobile ? "right" : "left",
            verticalAlign: "top",
          }),
        }),
      },
      position: (target, popover) => {
        if (!isMobile) return [target.right + 24, target.y];

        return [
          target.windowWidth / 2 - (popover.right - popover.left) / 2,
          target.windowHeight - target.height - 16,
        ];
      },
    },
    {
      selector: ".ref__docs",
      content: (props) =>
        createStep(props)({
          header: "Documents",
          body: "Find all the important documents for this referral here, including a downloadable referral summary, the imaging report, and raw image files.",
        }),
      styles: {
        ...styles,
        popover: (base, state) => ({
          ...styles.popover!(base, {
            ...state,
            positionSide: isMobile ? "bottom" : "right",
            verticalAlign: "top",
          }),
        }),
      },
      position: (target, popover) => {
        if (!isMobile) return [target.right + 24, target.y];

        return [
          target.windowWidth / 2 - (popover.right - popover.left) / 2,
          target.windowHeight - target.height - 16,
        ];
      },
    },
    {
      selector: ".ref__cancel",
      content: (props) =>
        createStep(props)({
          header: "Cancel your referral",
          body: "Need to cancel your referral? Just click this button.",
        }),
      styles: {
        ...styles,
        maskArea: (base) => ({ ...base, rx: 30 }),
        popover: (base, state) => ({
          ...styles.popover!(base, {
            ...state,
            positionSide: "bottom",
            horizontalAlign: "left",
          }),
        }),
      },
      position: (target, popover) => {
        if (!isMobile) return [target.right - target.width, target.bottom + 24];

        return [
          target.right - (popover.right - popover.left),
          target.bottom + 16,
        ];
      },
    },
    {
      selector: isBurgerMenu
        ? ".mobile-hamburger-menu .ref__centres"
        : ".ref__centres",
      content: (props) =>
        createStep(props)({
          header: "Centres near me",
          body: "Discover which nearby imaging centres offer the type of scan you need.",
        }),
      styles: {
        ...styles,
        maskArea: (base) => ({ ...base, rx: 30 }),
        popover: (base, state) => ({
          ...styles.popover!(base, {
            ...state,
            positionSide: "bottom",
            horizontalAlign: "right",
          }),
        }),
      },
      position: (target, popover) => {
        if (!isBurgerMenu) return [target.left, target.bottom + 24];

        return [
          target.windowWidth / 2 - (popover.right - popover.left) / 2,
          target.bottom + 24,
        ];
      },
    },
    {
      selector: "body",
      bypassElem: true,
      content: LastStep,
      position: (target, popover) => [
        document.body.clientWidth / 2 - (popover.right - popover.left) / 2,
        target.windowHeight / 2 - target.height / 2,
      ],
      styles: {
        ...styles,
        maskArea: () => ({ clipPath: undefined }),
        popover: (base, state) => ({
          ...styles.popover!(base, state),
          maxWidth: "min(500px, 100%)",
        }),
      },
    },
  ];
};

export default createSteps;
