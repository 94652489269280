import notifyError from "@services/notifyError";
import { client } from "../../client";
import type {
  RawReferral,
  RawValidationError,
  ReferralMutationFields,
} from "../../types/requests";
import { prepReferral } from "../../utils/prepReferrals";
import { prepValidationErrors } from "../../utils/prepValidationErrors";

type UpdateReferralProps = Partial<ReferralMutationFields>;

interface RawResponse {
  object: RawReferral;
  success: boolean;
  validation_errors: RawValidationError[];
}

export async function updateReferral({
  referralId,
  ...fields
}: Partial<UpdateReferralProps>) {
  const response = await client<RawResponse>({
    method: "patch",
    url: `/api/v1/referrals/${referralId}`,
    data: fields,
  });

  const success = Boolean(response?.success);
  const errors = prepValidationErrors(response?.validation_errors);
  const referral = prepReferral(response?.object);

  if (!success) {
    notifyError("Error updating referral", { referralId, errors });
  }

  return { referral, success, errors };
}
