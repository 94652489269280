import * as React from "react";
import { SvgWrapper } from "./SvgWrapper";

interface Props {
  className?: string;
}

export const CrossIcon: React.FC<Props> = ({ className }) => {
  return (
    <SvgWrapper
      width="16"
      height="16"
      viewBox="0 0 16 16"
      className={`${className} fill-current`}
    >
      <path
        d="M12 4L4 12"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
      />
      <path
        d="M4 4L12 12"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
      />
    </SvgWrapper>
  );
};
