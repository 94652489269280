import { getButtonStyles, H2, LoadingSpinner } from "@scandotcom/react";
import {
  IconCalendarPlus,
  IconUserCheck,
  IconUserPlus,
  IconUserSearch,
} from "@tabler/icons";
import Routes from "@utils/Routes";
import { PatientsModal } from "components/portal/Modals/PatientsModal";
import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { currentUser } from "@utils/PortalContext";

function PageNewReferral() {
  const [searchParams] = useSearchParams();
  const user = currentUser();
  const [showModal, setShowModal] = useState(false);
  const [patientSelected, setPatientSelected] = useState(false);
  const [patientId, setPatientId] = useState<string | undefined>();

  const navigate = useNavigate();

  useEffect(() => {
    const patientIdInUrl = searchParams.get("patient_id");
    if (patientIdInUrl) {
      onSelectPatient(patientIdInUrl);
    }
  }, [user]);

  function onSelectPatient(selected: string | undefined) {
    if (user && !user?.specialistConsultationsEnabled) {
      return navigate(Routes.newScanReferralPatient(selected));
    }

    setShowModal(false);
    setPatientSelected(true);
    setPatientId(selected);
  }

  if (!user) {
    return <LoadingSpinner className="my-8" />;
  }

  return (
    <>
      {!patientSelected && (
        <div>
          <PatientsModal
            onSelect={(patient) => onSelectPatient(patient.id)}
            onClose={() => setShowModal(false)}
            isOpen={showModal}
          />

          <H2 size="2xl" className="container mb-6 mt-12">
            Who is this referral for?
          </H2>

          <div className="container flex flex-col gap-x-10 gap-y-4 sm:flex-row">
            <button
              data-test="existing_patient"
              onClick={() => setShowModal(true)}
              className={cardStyles}
            >
              <IconUserSearch {...iconProps} />
              <h3 className={titleStyles}> Existing Patient </h3>
              <p className="mb-5 text-base font-normal">
                Previously created a referral for this patient?
              </p>

              <div className={getButtonStyles({ kind: "primary" }, "w-fit")}>
                Choose existing patient
              </div>
            </button>

            <button
              data-test="new_patient"
              onClick={() => onSelectPatient(undefined)}
              className={cardStyles}
            >
              <IconUserPlus {...iconProps} />
              <h3 className={titleStyles}> New Patient </h3>
              <p className="mb-5 text-base font-normal">
                Not referred this patient for a scan before?
              </p>

              <div className={getButtonStyles({ kind: "primary" }, "w-fit")}>
                Create a new patient
              </div>
            </button>
          </div>
        </div>
      )}

      {patientSelected && user?.specialistConsultationsEnabled && (
        <div>
          <H2 size="2xl" className="container mb-6 mt-12">
            What would you like to do?
          </H2>

          <div className="container flex flex-col gap-x-10 gap-y-4 sm:flex-row">
            <button
              data-test="book_a_scan"
              onClick={() => navigate(Routes.newScanReferralPatient(patientId))}
              className={cardStyles}
            >
              <IconCalendarPlus {...iconProps} />
              <h3 className={titleStyles}> Book a Scan </h3>
              <p className="mb-5 text-base font-normal">
                Choose from a range of imaging modalities and refer your
                patient.
              </p>

              <div className={getButtonStyles({ kind: "primary" }, "w-fit")}>
                Book a scan today
              </div>
            </button>

            <button
              data-test="book_a_specialist_consultation"
              onClick={() =>
                navigate(Routes.newSpecialistConsultationPatient(patientId))
              }
              className={cardStyles}
            >
              <IconUserCheck {...iconProps} />
              <h3 className={titleStyles}> Book a Specialist Consultation </h3>
              <p className="mb-5 text-base font-normal">
                Refer your patient to our network of Orthopaedic, Pain
                Management and Physiotherapy specialists.
              </p>

              <div className={getButtonStyles({ kind: "primary" }, "w-fit")}>
                Speak to a specialist
              </div>
            </button>
          </div>
        </div>
      )}
    </>
  );
}

const cardStyles =
  "w-full rounded-xl border border-cream-300 bg-white py-10 px-12 text-cypress-900 block text-start";

const titleStyles =
  "mb-3 font-maison-extended text-2xl font-bold capitalize tracking-tight";

const iconProps = {
  "aria-hidden": true,
  strokeWidth: 1,
  className: "w-16 h-16 mb-8",
};

export default PageNewReferral;
