import { LoadingSpinner } from "@scandotcom/react";
import { currentUser } from "@utils/PortalContext";
import Routes from "@utils/Routes";
import PatientForm, { PatientFormFields } from "components/portal/PatientForm";
import { useMutateReferral } from "hooks/useMutateReferral";
import { useReferral } from "hooks/useReferral";
import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";

function PageEditPatientInformation() {
  const navigate = useNavigate();
  const user = currentUser();
  const { referralId } = useParams();

  const { data: referral, isLoading } = useReferral({ referralId });
  const mutation = useMutateReferral();

  if (isLoading || !referral) {
    return <LoadingSpinner className="my-8" />;
  }

  const onSubmittedPatient = (patient: Partial<PatientFormFields>) => {
    const { address, ...fields } = patient;

    const data = {
      referralId: referral.id,
      patient_information: {
        ...fields,
        address_line_1: address?.line_1,
        address_line_2: address?.line_2,
        address_city: address?.city,
        address_postcode: address?.postcode,
      },
    };

    mutation.mutate(data, {
      onSuccess: (data) => {
        if (!data.success) return;
        navigate(Routes.referral(referral.id), {
          state: { successMessage: "The patient information has been updated" },
        });
      },
    });
  };

  return (
    <>
      <div className="bg-white">
        <div className="flex flex-col items-center justify-between py-4 px-4 sm:px-4 md:flex-row md:px-8">
          <div className="w-full md:w-auto">
            <div className="text-lg font-semibold">
              Edit referral {referral.reference}&apos;s patient information
            </div>
            <span className="text-xs text-gray-600">
              Provide the details of the patient you are referring for a scan.
            </span>
          </div>
        </div>
        <div className="relative inset-x-auto h-1 w-full border-b" />
      </div>
      <div className="px-4 pt-8 sm:px-4 md:px-8">
        <PatientForm
          hasGPDetails={user?.nonPatientLedFlow}
          onSubmittedPatient={onSubmittedPatient}
          submitLabel="Save"
          validationErrors={mutation.data?.errors}
          patient={referral.patient}
        />
      </div>
    </>
  );
}

export default PageEditPatientInformation;
