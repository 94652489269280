export const SortByValues = [
  { value: "price-asc", label: "Price (low to high)" },
  { value: "price-desc", label: "Price (high to low)" },
  { value: "distance-asc", label: "Distance (low to high)" },
  { value: "distance-desc", label: "Distance (high to low)" },
];

export const MachineTypeFilters = [
  { value: "any", label: "Any" },
  { value: "threeT", label: "3T" },
  { value: "openScanner", label: "Open Scanner" },
];

export const SearchRadiusValues = [
  { value: "25.0", label: "25 Miles" },
  { value: "50", label: "50 Miles" },
  { value: "75", label: "75 Miles" },
  { value: "100", label: "100 Miles" },
  { value: "150", label: "150 Miles" },
  { value: "200", label: "200 Miles" },
];
