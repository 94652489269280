import { Button } from "@scandotcom/react";
import { Referral } from "@services/scan/types/common";
import { CancelReferralModal } from "components/portal/Modals/CancelReferralModal";
import { ReferralStatusBadge } from "components/portal/ReferralStatusBadge";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { formatDate } from "utils/dates";
import { isReferralCancellable } from "utils/referralUtils";
import Routes from "utils/Routes";
import { PageInfo } from "../PageInfo";

export interface ReferralHeaderProps {
  referral: Referral;
  isExternal?: boolean;
}

export const ReferralHeader = ({
  referral,
  isExternal,
}: ReferralHeaderProps) => {
  const [openCancelModal, setOpenCancelModal] = useState(false);

  const cancellable = isReferralCancellable(referral);

  const breadcrumbs = isExternal
    ? undefined
    : [
        { name: "Referrals", href: Routes.referrals },
        { name: "View referral", href: Routes.referral(referral.id) },
      ];

  return (
    <PageInfo name={referral.reference} breadcrumbs={breadcrumbs}>
      <CancelReferralModal
        isOpen={openCancelModal}
        onClose={() => setOpenCancelModal(false)}
        id={referral.id}
        reference={referral.reference}
      />

      <PageInfo.Item label="Status">
        <ReferralStatusBadge referral={referral} className="mt-auto" />
      </PageInfo.Item>

      {!isExternal && (
        <PageInfo.Item label="Patient">
          <Link
            to={Routes.patient(referral.patient.id)}
            className="font-semibold text-cypress-500 hover:underline"
          >
            {referral.patient.fullName}
          </Link>
        </PageInfo.Item>
      )}

      <PageInfo.Item label="Created">
        {formatDate(referral.createdAt)}
      </PageInfo.Item>

      <Button
        type="button"
        kind="cream"
        className="ref__cancel w-full sm:ml-auto sm:w-fit"
        disabled={!cancellable}
        onClick={() => setOpenCancelModal(true)}
      >
        Cancel referral
      </Button>
    </PageInfo>
  );
};
