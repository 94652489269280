import Bugsnag from "@bugsnag/js";

const isError = (err: unknown): err is Error => err instanceof Error;

function notifyError(
  error: Error | string,
  metadata?: Record<string, unknown>
) {
  const bugsnagError = isError(error) ? error : new Error(error);
  const bugsnagMetadata = {
    ...(metadata || {}),
    "Date()": Date(),
    AppData: window.AppData,
  };

  Bugsnag.notify(bugsnagError, (event) => {
    event.addMetadata("metadata", bugsnagMetadata);
  });

  console.log(["notifyError", error, JSON.stringify(metadata), metadata]);
}

export default notifyError;
