import { Pagination } from "@scandotcom/react";
import { Table } from "components/common/Table";
import { TextLink } from "components/common/TextLink";
import { EmptyList } from "components/portal/List/EmptyList";
import { ListPageHeader } from "components/portal/ListPageHeader";
import { usePatientList } from "hooks/usePatientList";
import React from "react";
import { useNavigate } from "react-router-dom";
import { formatDate } from "utils/dates";
import Routes from "utils/Routes";
import { shorten } from "utils/strings";

const limit = 10;

function PageListPatients() {
  const navigate = useNavigate();

  const {
    pageInfo,
    patients,
    page,
    hasNoInitialResults,
    setOrder,
    setSearch,
    setPage,
  } = usePatientList({ limit });

  if (hasNoInitialResults) {
    return (
      <div className="container">
        <EmptyList resource="patients" />
      </div>
    );
  }

  function getSortDirection(field: string) {
    return pageInfo?.order === field ? pageInfo?.direction : undefined;
  }

  return (
    <div className="container">
      <ListPageHeader resource="patients" updateSearch={setSearch} />
      <Table>
        <Table.Head>
          <Table.SortableTh
            scope="col"
            onClick={() => setOrder("last_name")}
            title="Sort by patient name"
            direction={getSortDirection("last_name")}
          >
            Name
          </Table.SortableTh>
          <Table.Th scope="col">Phone number</Table.Th>
          <Table.Th scope="col">Alternate number</Table.Th>
          <Table.SortableTh
            scope="col"
            onClick={() => setOrder("email")}
            title="Sort by patient email"
            direction={getSortDirection("email")}
          >
            Email
          </Table.SortableTh>
          <Table.SortableTh
            scope="col"
            onClick={() => setOrder("created_at")}
            title="Sort by patient created at"
            direction={getSortDirection("created_at")}
          >
            Created
          </Table.SortableTh>
        </Table.Head>
        <Table.TBody>
          {patients.map((patient) => {
            const patientHref = Routes.patient(patient.id);
            const patientReferralHref = Routes.newReferralPatient(patient.id);
            return (
              <Table.Tr key={patient.id} onClick={() => navigate(patientHref)}>
                <Table.Td>
                  <TextLink to={patientHref}>
                    {shorten(patient.fullName, 30)}
                  </TextLink>
                </Table.Td>

                <Table.Td>{patient.phone}</Table.Td>

                <Table.Td>{patient.alternatePhone}</Table.Td>

                <Table.Td>{shorten(patient.email, 50)}</Table.Td>

                <Table.Td>{formatDate(patient.createdAt)}</Table.Td>

                <Table.Menu
                  links={[{ name: "View patient", href: patientHref }]}
                  actions={[
                    {
                      name: "Create referral",
                      onClick: () => navigate(patientReferralHref),
                    },
                  ]}
                />
              </Table.Tr>
            );
          })}
        </Table.TBody>
      </Table>

      {pageInfo?.total && pageInfo.total > limit ? (
        <div className="mt-8 flex justify-center">
          <Pagination
            meta={{
              currentPage: page,
              isFirstPage: page === 1,
              isLastPage: Boolean(pageInfo?.hasMore),
              totalPages: Math.ceil((pageInfo?.total || 0) / limit),
            }}
            onClickPage={setPage}
          />
        </div>
      ) : null}
    </div>
  );
}

export default PageListPatients;
