import { TimeSlot } from "@services/scan/types/common";
import { Modal, ModalProps } from "components/common/Modal";
import React from "react";

import { SlotCalendar } from "../SlotSelector/SlotCalendar";

interface ProviderTimeSlotsModalProps extends ModalProps {
  timeSlots: any[];
  onSlotPicked: (slot: TimeSlot) => void;
}

export const ProviderTimeSlotsModal = ({
  onClose,
  timeSlots,
  isOpen,
  onSlotPicked,
}: ProviderTimeSlotsModalProps) => {
  return (
    <Modal onClose={onClose} className="p-10" isOpen={isOpen}>
      <Modal.Close onClick={onClose} className="-mt-6 -mr-6" />
      <SlotCalendar slots={timeSlots} onPickSlot={onSlotPicked} />
    </Modal>
  );
};
