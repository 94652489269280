import { client } from "@services/scan/client";
import {
  PaymentInfoMutationFields,
  RawPaymentInfo,
  RawValidationError,
} from "../../types/requests";
import { prepPaymentInfo } from "../../utils/prepPaymentInfo";
import { prepValidationErrors } from "../../utils/prepValidationErrors";

interface RawResponse {
  success: boolean;
  validation_errors: RawValidationError[];
  object: RawPaymentInfo;
}

export async function updatePaymentInfo(data: PaymentInfoMutationFields) {
  const response = await client<RawResponse>({
    data,
    method: "patch",
    url: "/api/v1/payment_information",
  });

  const success = Boolean(response?.success);
  const errors = prepValidationErrors(response?.validation_errors);
  const info = prepPaymentInfo(response?.object);

  return { success, errors, info };
}
