import React from "react";
import { LoadingSpinner } from "@scandotcom/react";

interface EstimatedPriceProps {
  prices?: number[];
  loading?: boolean;
  isDefaultLocation?: boolean;
}

const EstimatedPrice = ({
  prices = [],
  loading,
  isDefaultLocation,
}: EstimatedPriceProps) => {
  const formattedPrices = prices
    .map((price) => `£${price.toLocaleString()}`)
    .join(" - ");

  if (!prices.length && !loading) return null;
  return (
    <div className="border-slate-300 rounded-lg border bg-white p-6 text-xs">
      <h3 className="font-maison-extended font-bold">
        Estimated patient price
      </h3>
      <span className="border-b pb-3 font-maison-extended text-xl font-bold">
        {loading ? <LoadingSpinner /> : formattedPrices}
      </span>

      <p className="pt-5">
        This price estimate is based on the patient’s nearest sites, and
        includes any contrast material required for the selected scan type. Your
        patient chooses their preferred imaging centre, but you can visit the
        ‘Centres Near Me’ page to see the locations available.
      </p>

      {isDefaultLocation && (
        <p className="pt-5">
          * Prices shown are for London - please enter the patient's address for
          an estimate based on their location.
        </p>
      )}
    </div>
  );
};

export default EstimatedPrice;
