import type { Modality } from "../types/common";
import type { RawModality } from "../types/requests";

export function prepModality(rawModality?: RawModality): Modality | null {
  if (!rawModality) {
    return null;
  }

  return {
    code: rawModality.code,
    label: rawModality.label,
    article: rawModality.article,
    maxBodyParts: rawModality.max_body_parts,
    safetyQuestions: rawModality.safety_questions,
  };
}

export function prepModalities(rawModalities?: RawModality[]) {
  const modalities =
    rawModalities
      ?.map(prepModality)
      ?.filter((modality): modality is Modality => Boolean(modality)) ?? [];

  return modalities;
}
