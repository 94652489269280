import { Button, H3, LoadingSpinner, TextInput } from "@scandotcom/react";
import { getPaymentInfo, updatePaymentInfo } from "@services/scan";
import { PaymentInfoMutationFields } from "@services/scan/types/requests";
import Routes from "@utils/Routes";
import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import buildErrorsObject from "@utils/buildErrorsObject";
import { currentUser } from "utils/PortalContext";

function PageUpdatePaymentInformation() {
  const navigate = useNavigate();
  const userData = currentUser();
  const queryClient = useQueryClient();

  const { data: info } = useQuery(["payment info"], getPaymentInfo);
  const mutation = useMutation(updatePaymentInfo, {
    onSuccess: (data) => {
      if (!data.success || data.errors?.length) {
        setValidationErrors(buildErrorsObject(data.errors));
        return;
      }
      queryClient.setQueryData(["payment info"], data.info);
      navigate(Routes.home, {
        state: { successMessage: "Payment information updated" },
      });
    },
  });

  const infoFields: Partial<PaymentInfoMutationFields> = {
    account_name: info?.accountName,
    account_number: info?.accountNumber,
    vat_number: info?.vatNumber,
    sort_code: info?.sortCode,
    company_address: info?.companyAddress,
  };

  const { handleSubmit, register, watch, reset } =
    useForm<PaymentInfoMutationFields>({
      defaultValues: { ...infoFields },
    });

  const [validationErrors, setValidationErrors] = useState<
    Record<string, string[]>
  >({});

  const onSubmit: SubmitHandler<PaymentInfoMutationFields> = (formInputs) => {
    mutation.mutate(formInputs);
  };

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name) {
        // Removing validation error when user types
        setValidationErrors((old) => ({ ...old, [name]: [] }));
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    reset({ ...infoFields });
  }, [info]);

  if (!userData || !info) return null;

  return (
    <>
      <div className="px-4 sm:px-4 md:px-8">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="m-auto md:-mb-4 lg:max-w-[794px]"
        >
          <div className="grid gap-6 border-b border-b-cream-300 pt-16 pb-10 md:grid-cols-5">
            <H3 className="col-span-2">Bank details</H3>
            <div className="col-span-3 grid gap-6 [&_label]:!normal-case">
              <div>
                <TextInput
                  id="accountName"
                  label="Account name"
                  type="text"
                  {...register("account_name")}
                  errorMessage={validationErrors["account_name"]?.join(",")}
                />
              </div>
              <div className="gap-4 sm:grid sm:grid-cols-3">
                <div className="sm:col-span-2">
                  <div>
                    <TextInput
                      id="accountNumber"
                      label="Account number"
                      type="text"
                      required={true}
                      placeholder="12345678"
                      {...register("account_number")}
                      errorMessage={validationErrors["account_number"]?.join(
                        ". "
                      )}
                    />
                  </div>
                </div>
                <div>
                  <div>
                    <TextInput
                      id="sortCode"
                      label="Sort code"
                      type="text"
                      required={true}
                      placeholder="112233"
                      {...register("sort_code")}
                      errorMessage={validationErrors["sort_code"]?.join(". ")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="grid gap-6 pt-10 pb-16 md:grid-cols-5">
            <H3 className="col-span-2">Company details</H3>
            <div className="col-span-3 grid gap-6 [&_label]:!normal-case">
              <div>
                <TextInput
                  id="companyAddress"
                  label="Address"
                  type="text"
                  required={true}
                  {...register("company_address")}
                  errorMessage={validationErrors["company_address"]?.join(",")}
                />
              </div>
              <div>
                <TextInput
                  id="vatNumber"
                  label="VAT number"
                  type="text"
                  {...register("vat_number")}
                  errorMessage={validationErrors["vat_number"]?.join(",")}
                />
              </div>
            </div>
          </div>
          {!!validationErrors.base?.length && (
            <div className="md:grid md:grid-cols-3">
              <div className="col-span-2 col-start-2 py-4 text-red">
                <h4 className="font-bold">
                  Some errors occurred in your submission:
                </h4>
                <ul className="text-sm">
                  {validationErrors.base.map((error) => (
                    <li key={error}>{error}</li>
                  ))}
                </ul>
              </div>
            </div>
          )}

          <div className="grid grid-cols-1 justify-end gap-6 md:flex">
            <Button data-test="submit" type="submit" className="md:order-2">
              Save
            </Button>
          </div>
        </form>
      </div>
    </>
  );
}

export default PageUpdatePaymentInformation;
