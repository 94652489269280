import { client } from "../../client";
import type { RawSafetyQuestion } from "../../types/requests";
import { prepSafetyQuestions } from "../../utils/prepSafetyQuestions";

interface RawResponse {
  success: boolean;
  errors: string[];
  data: RawSafetyQuestion[];
}

export async function getSafetyQuestions(referralId?: string | null) {
  if (!referralId) {
    throw new Error("no referral ID provided");
  }

  const request = await client<RawResponse>({
    method: "get",
    url: `/api/v1/referrals/${referralId}/safety_questions`,
  });

  const success = Boolean(request?.success);
  const questions = prepSafetyQuestions(request?.data);
  const errors = request?.errors ?? [];

  return { success, questions, errors };
}
