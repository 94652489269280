import { ButtonLink, LoadingSpinner } from "@scandotcom/react";
import { getFullAddress } from "@utils/referralUtils";
import { PageInfo } from "components/common/PageInfo";
import { PatientInformation } from "components/common/ShowReferral/PatientInformation";
import { ReferralList } from "components/portal/List/ReferralList";
import { usePatient } from "hooks/usePatient";
import React from "react";
import { useParams } from "react-router-dom";
import { formatDate } from "utils/dates";
import Routes from "utils/Routes";

function PageShowPatient() {
  const { patientId } = useParams();
  const { data: patient, isLoading } = usePatient(patientId);

  if (isLoading) {
    return <LoadingSpinner className="my-8" />;
  }

  if (!patientId || !patient) return <p>Patient not found</p>;

  const breadcrumbs = [
    { name: "Patients", href: Routes.patients },
    { name: "View patient", href: Routes.patient(patientId) },
  ];

  return (
    <div>
      <PageInfo name={patient.fullName} breadcrumbs={breadcrumbs}>
        <PageInfo.Item label="Created">
          {formatDate(patient.createdAt)}
        </PageInfo.Item>

        <ButtonLink
          data-test="new_referral"
          className="w-full sm:ml-auto sm:w-fit"
          href={Routes.newReferralPatient(patientId)}
        >
          New referral
        </ButtonLink>
      </PageInfo>

      <div className="container">
        <PatientInformation
          id={patient.id}
          name={patient.fullName}
          email={patient.email}
          phone={patient.phone}
          alternate_phone={patient.alternatePhone}
          address={getFullAddress(patient.address)}
          gender={patient.gender}
          DOB={patient.dateOfBirth}
        />
        <ReferralList patientId={patientId} />
      </div>
    </div>
  );
}

export default PageShowPatient;
