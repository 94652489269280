import { Dialog, Transition } from "@headlessui/react";
import { IconX } from "@tabler/icons";
import classNames from "classnames";
import React, { Fragment, ReactNode } from "react";

export interface ModalProps {
  isOpen?: boolean;
  onClose: () => void;
  initialFocus?: any;
  children?: ReactNode;
  className?: string;
}
export const Modal = ({
  onClose,
  isOpen,
  initialFocus,
  children,
  className,
}: ModalProps) => {
  const panelStyles = classNames(
    "mx-auto rounded-xl border border-cream-300 bg-white shadow-2xl",
    className
  );

  return (
    <Transition as={Fragment} show={isOpen}>
      <Dialog onClose={onClose} as="div" initialFocus={initialFocus}>
        <Transition.Child {...backdropTransition}>
          <div
            className="fixed inset-0 bg-white/50 backdrop-blur-sm"
            aria-hidden
          />
        </Transition.Child>
        {/* Full-screen scrollable container */}
        <div className="fixed inset-0 overflow-y-auto">
          {/* Container to center the panel */}
          <div className="flex min-h-full items-center justify-center p-4">
            <Transition.Child {...panelTransition}>
              <Dialog.Panel className={panelStyles}>{children}</Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

const backdropTransition = {
  enter: "ease-out duration-300",
  enterFrom: "opacity-0",
  enterTo: "opacity-100",
  leave: "ease-in duration-200",
  leaveFrom: "opacity-100",
  leaveTo: "opacity-0",
};

const panelTransition = {
  enter: "ease-out duration-300",
  enterFrom: "opacity-0 scale-95",
  enterTo: "opacity-100 scale-100",
  leave: "ease-in duration-200",
  leaveFrom: "opacity-100 scale-100",
  leaveTo: "opacity-0 scale-95",
};

interface CloseButtonProps {
  onClick: () => void;
  className?: string;
}

export const CloseButton = ({ onClick, className }: CloseButtonProps) => {
  const styles = classNames(
    "shadow-xs ml-auto flex items-center rounded-lg border p-2 text-center focus:outline-none focus:ring",
    "border-cream-200 bg-white hover:border-cream-300 hover:bg-cream-50 hover:text-cypress-900 focus:border-pink-500 focus:ring-pink-100",
    className
  );
  return (
    <button type="button" className={styles} onClick={onClick}>
      <span className="sr-only">Close</span>
      <IconX strokeWidth={1.5} className="h-4 w-4" />
    </button>
  );
};

Modal.Close = CloseButton;
