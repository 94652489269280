import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSpecialistConsultation } from "hooks/useSpecialistConsultation";
import { LoadingSpinner } from "@scandotcom/react";
import { ContactUs } from "../ShowReferral/ContactUs";
import { PatientInformation } from "../ShowReferral/PatientInformation";
import { getFullAddress } from "@utils/referralUtils";
import { Panel } from "../Panel";
import Routes from "@utils/Routes";
import { DetailsEntry, DetailsTable } from "../ShowReferral/DetailsTable";
import { SpecialistConsultationHeader } from "./SpecialistConsultationHeader";
import { titleize } from "@utils/strings";

const ShowSpecialistConsultation = () => {
  const navigate = useNavigate();
  const { specialistConsultationId } = useParams() as {
    specialistConsultationId: string;
  };

  const { data: specialistConsultation, isLoading } = useSpecialistConsultation(
    specialistConsultationId
  );

  if (isLoading || !specialistConsultation) {
    return <LoadingSpinner className="my-8" />;
  }

  const editHref =
    specialistConsultation?.status === "In Draft"
      ? Routes.specialistConsultationDetails(specialistConsultationId)
      : null;

  return (
    <div>
      <SpecialistConsultationHeader
        specialistConsultation={specialistConsultation}
      />

      <div className="container grid-cols-12 gap-x-6 lg:grid">
        <div className="col-span-4 col-start-9 row-span-full mb-4 h-fit w-full lg:sticky lg:top-6 lg:mb-0">
          <ContactUs className="mt-4" />
        </div>

        <div className="col-span-8 row-span-full h-fit space-y-4">
          <Panel
            title="Specialist Consultation Details"
            editHref={editHref}
            className={"mt-4"}
          >
            <DetailsTable>
              <DetailsEntry label="Reference">
                {specialistConsultation.reference}
                {specialistConsultation.internalReference && (
                  <>
                    {" "}
                    <span className="font-normal text-cream-800">
                      ({specialistConsultation.internalReference})
                    </span>
                  </>
                )}
              </DetailsEntry>

              <DetailsEntry label="Location">
                {specialistConsultation.specialist?.practiceName}

                {Boolean(specialistConsultation.specialist?.address) && (
                  <div className="text-cream-800">
                    {specialistConsultation.specialist?.address}
                  </div>
                )}
              </DetailsEntry>

              <DetailsEntry label="Consultation">
                {titleize(specialistConsultation.specialistType || "")}
              </DetailsEntry>

              <DetailsEntry label="Referral information">
                {specialistConsultation.referralInformation}
              </DetailsEntry>
            </DetailsTable>
          </Panel>

          <PatientInformation
            id={specialistConsultation.patient.id || ""}
            name={specialistConsultation.patient.fullName}
            DOB={specialistConsultation.patient.dateOfBirth}
            gender={specialistConsultation.patient.gender}
            phone={specialistConsultation.patient.phone}
            alternate_phone={specialistConsultation.patient.alternatePhone}
            email={specialistConsultation.patient.email}
            address={getFullAddress(specialistConsultation.patient.address)}
          />
        </div>
      </div>
    </div>
  );
};

export default ShowSpecialistConsultation;
