import { TourProvider } from "@reactour/tour";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import ReferralWalkthrough from "components/portal/ReferralWalkthrough";
import createSteps from "components/portal/ReferralWalkthrough/steps";
import { styles } from "components/portal/ReferralWalkthrough/styles";
import TourStepContent from "components/portal/ReferralWalkthrough/TourStepContent";
import { useOptimizedWindowSize } from "hooks/useOptimizedWindowSize";
import React, { useState } from "react";

function PageReferralWalkthrough() {
  const disableBody = (target: HTMLElement) => disableBodyScroll(target);
  const enableBody = (target: HTMLElement) => enableBodyScroll(target);

  const { isMobile, isBurgerMenu } = useOptimizedWindowSize({
    isMobile: ({ width }) => width < 960,
    isBurgerMenu: ({ width }) => width < 640,
  });
  const [step, setStep] = useState<number>(0);

  const steps = createSteps(isMobile, isBurgerMenu);

  const onTransition = (
    target: DOMRect & { windowWidth: number; windowHeight: number },
    popover: DOMRect
  ) => {
    if (step === 0 || step === steps.length - 1) return "center";

    return isMobile
      ? [
          target.windowWidth / 2 - (popover.right - popover.left) / 2,
          target.windowHeight - target.height - 16,
        ]
      : [popover.x, popover.y];
  };

  return (
    <TourProvider
      steps={steps}
      afterOpen={disableBody}
      beforeClose={enableBody}
      padding={{ mask: 0 }}
      scrollSmooth
      className="reactour__popover rounded-lg"
      styles={styles(isMobile)}
      ContentComponent={TourStepContent}
      disableInteraction
      onClickMask={() => {}}
      // @ts-ignore
      onTransition={onTransition}
      defaultOpen={true}
      setCurrentStep={setStep}
      currentStep={step}
    >
      <ReferralWalkthrough steps={steps} />
    </TourProvider>
  );
}

export default PageReferralWalkthrough;
