import {
  createSpecialistConsultation,
  updateSpecialistConsultation,
} from "@services/scan";
import { SpecialistConsultationMutationFields } from "@services/scan";
import { useMutation, useQueryClient } from "react-query";

export function useMutateSpecialistConsultation(id: string | undefined) {
  const queryClient = useQueryClient();

  const mutation = useMutation(mutateSpecialistConsultation, {
    onSuccess: (data) => {
      if (!data.success || !data.specialistConsultation) {
        return;
      }

      queryClient.setQueryData(
        ["specialistConsultation", data.specialistConsultation.id],
        data.specialistConsultation
      );
    },
  });

  function mutateSpecialistConsultation(
    fields: Partial<SpecialistConsultationMutationFields>
  ) {
    return id
      ? updateSpecialistConsultation(fields)
      : createSpecialistConsultation(fields);
  }

  return mutation;
}
