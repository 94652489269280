import * as React from "react";
import classNames from "classnames";
import Notifications from "./Notifications";
import { NotificationsEmptyState } from "./NotificationsEmptyState";
import { NotificationItem } from "@services/scan/types/common";

interface Props {
  close: () => void;
  all: NotificationItem[];
  read: NotificationItem[];
  unread: NotificationItem[];
  isMobile?: boolean;
}

export const NotificationsContent: React.FC<Props> = ({
  close,
  all,
  read,
  unread,
  isMobile,
}) => {
  return (
    <div
      className={classNames("bg-white", {
        "flex grow flex-col items-center": isMobile,
        "h-full rounded-xl border border-cream-400 shadow-xl": !isMobile,
        "justify-center": all.length === 0,
        "justify-start": all.length > 0,
      })}
    >
      {all.length > 0 ? (
        <Notifications all={all} read={read} unread={unread} close={close} />
      ) : (
        <NotificationsEmptyState close={close} />
      )}
    </div>
  );
};
