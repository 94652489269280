import notifyError from "@services/notifyError";
import { client } from "../../client";
import type {
  RawSpecialistConsultation,
  RawValidationError,
} from "../../types/requests";
import { prepValidationErrors } from "../../utils/prepValidationErrors";
import { prepSpecialistConsultation } from "../../utils/prepSpecialistConsultation";

interface RawResponse {
  object: RawSpecialistConsultation;
  success: boolean;
  validation_errors: RawValidationError[];
}

export async function submitSpecialistConsultation(id: string) {
  const response = await client<RawResponse>({
    method: "patch",
    url: `/api/v1/specialist_consultations/${id}/submit`,
    data: {},
  });

  const success = Boolean(response?.success);
  const errors = prepValidationErrors(response?.validation_errors);
  const specialistConsultation = prepSpecialistConsultation(response?.object);

  if (!success) {
    notifyError("Error submitting specialist consultation", { errors });
  }

  return { specialistConsultation, success, errors };
}
