import { Button, Heading } from "@scandotcom/react";
import { ImagingProvider } from "@services/scan/types/common";
import classNames from "classnames";
import { Tag } from "components/common/Tag";
import React from "react";
import { Popup } from "react-map-gl";
import { Address } from "./Address";
import { ProviderPrice } from "./ProviderPrice";

interface ProviderPopupProps {
  provider: ImagingProvider;
  onClose: () => void;
  onSelect?: () => void;
}

export const ProviderPopup = ({
  provider,
  onClose,
  onSelect,
}: ProviderPopupProps) => {
  const popupStyles = classNames(
    "!max-w-sm [&>.mapboxgl-popup-content]:rounded-xl [&>.mapboxgl-popup-content]:border [&>.mapboxgl-popup-content]:border-cream-300 [&>.mapboxgl-popup-content]:p-6 [&>.mapboxgl-popup-content]:shadow-xl",
    "[&>.mapboxgl-popup-tip]:-mb-2.5 [&>.mapboxgl-popup-tip]:!border-0 [&>.mapboxgl-popup-tip]:!border-b [&>.mapboxgl-popup-tip]:!border-l [&>.mapboxgl-popup-tip]:!border-cream-300 [&>.mapboxgl-popup-tip]:!bg-white [&>.mapboxgl-popup-tip]:!bg-white [&>.mapboxgl-popup-tip]:!w-5 [&>.mapboxgl-popup-tip]:!h-5 [&>.mapboxgl-popup-tip]:rotate-[135deg]"
  );

  return (
    <Popup
      offset={[0, 5]}
      closeButton={false}
      className={popupStyles}
      anchor="top"
      longitude={Number(provider.longitude)}
      latitude={Number(provider.latitude)}
      onClose={onClose}
    >
      <Heading size="lg" variant="h3" className="mb-2">
        {provider.name}
      </Heading>

      <Address
        address={provider.address}
        distance={provider.distance}
        className="mb-1.5 items-start"
      />

      <div className="mb-4 mt-4 flex flex-wrap gap-x-1 text-xs font-medium">
        {provider.minAge && <Tag>Min. age: {provider.minAge}+</Tag>}
      </div>

      <div className="flex items-center justify-between">
        {provider.price && <ProviderPrice price={provider.price} />}
        {onSelect && (
          <Button
            size="sm"
            data-test="list_select_center"
            className="mt-auto min-w-max"
            type="button"
            onClick={onSelect}
          >
            Select centre
          </Button>
        )}
      </div>
    </Popup>
  );
};
