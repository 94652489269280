import React from "react";

function Decide() {
  return (
    <div id="decide">
      <p className="mt-0 text-xl font-medium md:mt-6">
        How can you decide which scan is best for your patient?
      </p>
      <div className="py-4 text-gray-500">
        <p className="mb-4">
          The modality you should select for your patient depends on their
          symptoms, the body part being scanned, and whether the images need to
          be captured while the body is in motion, at rest, or within an urgent
          timeframe.
        </p>
        <div className="mt-0 mb-0 flex flex-col rounded-lg bg-gray-50 p-6 text-sm md:mt-8 md:mb-4 md:mb-8 md:flex-row md:p-4">
          <svg
            className="mr-2 h-8 w-8 text-gray-600 md:w-20"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
              d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <p className="mb-2 mt-2 text-gray-600 md:mt-0">
            Don&#39;t worry if you&#39;re not completely certain of your
            modality or body part choice. Our expert GP&#39;s and radiologists
            review every referral to ensure your patient receives the most
            suitable scan for their needs.
          </p>
        </div>
      </div>

      <div className="flex flex-col md:flex-row">
        <div className="w-full pt-4 pb-0 font-medium text-gray-900 md:w-1/3 md:pb-4">
          MRI
        </div>
        <div className="w-full text-gray-500 md:w-2/3">
          <div className="py-4 font-medium text-gray-900">Recommended for</div>
          <ul className="Safe and non-invasive list-disc pl-4">
            <li className="mb-2">
              Scanning larger areas, consisting of different types of tissues.
              For example, a scan of the entire hand or foot, in order to assess
              the soft tissues, bones, joints and connective tissues all in one
              scan
            </li>
            <li className="mb-2">
              Deep tissue scans, to provide clearer images of tissue composition
              and health. For example, MRI is particularly useful for assessing
              fluid around joint tears, and abnormalities/growths nearer the
              bones
            </li>
            <li className="mb-2">
              Detecting inflammation, tumours, degeneration, abnormal growth,
              fluid, herniations
            </li>
          </ul>
          <div className="py-4 font-medium text-gray-900">
            Not recommended for
          </div>
          <ul className="Safe and non-invasive list-disc pl-4">
            <li className="mb-2">
              Dynamic scans (while the body is in motion), as the clarity of MRI
              images depends on the patient lying very still for the entire
              process
            </li>
            <li className="mb-2">
              People who have metal within their body, such as certain medical
              implants or shrapnel
            </li>
            <li className="mb-0 md:mb-2">
              People who suffer with claustrophobia, though we do offer open and
              upright MRI scans to suit these patients
            </li>
          </ul>
        </div>
      </div>

      <div className="flex flex-col pt-4 md:flex-row">
        <div className="w-full pt-4 pb-0 font-medium text-gray-900 md:w-1/3 md:pb-4">
          Ultrasound
        </div>
        <div className="w-full text-gray-500 md:w-2/3">
          <div className="py-4 font-medium text-gray-900">Recommended for</div>
          <ul className="Safe and non-invasive list-disc pl-4">
            <li className="mb-2">
              Providing high-resolution images of one body part at a time,
              instead of larger areas
            </li>
            <li className="mb-2">
              Capturing images of the body part while it is in motion (dynamic
              scans), to see whether structures are catching or pinching. This
              can help diagnose conditions such as bursitis
            </li>
            <li className="mb-2">
              Imaging soft tissues under the skin, such as muscles, tendons,
              ligaments and connective tissues, to look for tears, growths,
              tumours, fluid or calcifications
            </li>
            <li className="mb-2">Scanning the exact site of pain</li>
          </ul>
          <div className="py-4 font-medium text-gray-900">
            Not recommended for
          </div>
          <ul className="Safe and non-invasive list-disc pl-4">
            <li className="mb-2">Deep tissue scans</li>
            <li className="mb-2">Bone scans</li>
            <li className="mb-0 md:mb-2">Cartilage scans</li>
          </ul>
        </div>
      </div>

      <div className="flex flex-col pt-4 md:flex-row">
        <div className="w-full pt-4 pb-0 font-medium text-gray-900 md:w-1/3 md:pb-4">
          CT
        </div>
        <div className="w-full text-gray-500 md:w-2/3">
          <div className="py-4 font-medium text-gray-900">Recommended for</div>
          <ul className="Safe and non-invasive list-disc pl-4">
            <li className="mb-2">
              Scanning the bones in detail and assessing complex fractures
            </li>
            <li className="mb-2">
              Locating and assessing tumours. This includes tumours of the bones
              and joints in a musculoskeletal context
            </li>
            <li className="mb-2">Assessing spinal trauma</li>
            <li className="mb-2">
              Finding blood clots, aneurysms and problems with blood flow
            </li>
            <li className="mb-2">
              Scanning large areas of the body very quickly, compared to an MRI
              scan. CT is therefore suitable for patients who cannot comfortably
              lie still for the duration of an MRI scan, or patients in urgent
              care settings
            </li>
          </ul>
          <div className=" py-4 font-medium text-gray-900">
            Not recommended for
          </div>
          <ul className="Safe and non-invasive list-disc pl-4">
            <li className="mb-2">
              Patients who are pregnant, due to the risks of exposing a
              developing baby to the radiation used in CT scanning
            </li>
            <li className="mb-2">
              Patients who don&#39;t have any specific symptoms, as this would
              involve unnecessary radiation exposure
            </li>
            <li className="mb-2">
              Patients who have kidney problems, due to the use of iodine-based
              contrast agents
            </li>
            <li className="mb-0 md:mb-2">
              Capturing detailed images of the soft tissues - an MRI would be
              more suitable in this case
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Decide;
