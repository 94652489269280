import classNames from "classnames";
import React, { ReactNode } from "react";

interface DetailsTableProps {
  className?: string;
  children?: ReactNode;
}

export const DetailsTable = ({ className, children }: DetailsTableProps) => {
  const tableStyles = classNames("table-auto w-full", className);
  return (
    <table className={tableStyles}>
      <tbody>{children}</tbody>
    </table>
  );
};

interface DetailsEntryProps {
  label?: string;
  className?: string;
  children?: ReactNode;
  isTopAligned?: boolean;
}

export const DetailsEntry = ({
  label,
  className,
  children,
  isTopAligned,
}: DetailsEntryProps) => {
  const entryStyles = classNames(
    "flex flex-col sm:table-row text-sm w-full gap-y-1",
    "border border border-x-0 border-t-0 last:border-0",
    "font-maison font-medium text-cypress-900 border-cream-200",
    "[&:first-of-type>:is(th,td)]:pt-0 [&:last-of-type>:is(th,td)]:pb-0",
    className
  );
  const labelStyles = classNames(
    "w-fit whitespace-nowrap pt-4 sm:pb-4 pr-4 text-left font-normal capitalize text-cream-800 sm:min-w-[220px]",
    isTopAligned && "align-top"
  );
  const childStyles = classNames(
    "w-full sm:pt-4 pb-4 [overflow-wrap:anywhere]",
    isTopAligned && "align-top"
  );
  return (
    <tr className={entryStyles}>
      {label && <th className={labelStyles}>{label}</th>}
      <td className={childStyles}>{children}</td>
    </tr>
  );
};
