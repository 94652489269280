import { PopoverContentProps } from "@reactour/tour";
import { Button, H3 } from "@scandotcom/react";
import React from "react";
import { useNavigate } from "react-router-dom";

function FirstStep({ setCurrentStep }: PopoverContentProps) {
  const navigate = useNavigate();

  return (
    <div>
      <div className="flex flex-col gap-2">
        <H3 size="xl" className="text-gray-900">
          Demo referral
        </H3>
        <p className="text-base text-gray-900">
          Welcome to your <u>Scan.com</u> referral portal. Let us give you a
          quick tour to help you find your way around.
        </p>
      </div>
      <div className="mt-5">
        <Button className="mr-1" onClick={() => setCurrentStep((s) => s + 1)}>
          Get started
        </Button>
        <Button
          className="border-none"
          kind="secondary"
          onClick={() => navigate("/")}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
}

export default FirstStep;
