import React from "react";
import { InlineWidget } from "react-calendly";

function Calendly() {
  return (
    <div className="my-12 h-fit rounded-xl border bg-white p-12" id="demo">
      <h2 className="mb-6 text-3xl font-light leading-none tracking-tighter text-gray-900 lg:text-5xl">
        Need more information?
      </h2>
      <p className="my-4 text-base font-normal text-gray-500">
        Book a call with Toby, our Head of Partnerships.
      </p>
      <div className="my-6 flex items-center">
        <img
          className="h-24 w-24 rounded-full object-cover"
          src={AppData.TobyImageUrl}
          alt="Toby Ryan"
        />
        <div className="ml-6">
          <p className="text-base font-medium text-gray-900">Toby Ryan</p>
          <p className="leading-4.5 text-sm font-medium text-primary-700">
            Head of Partnerships
          </p>
        </div>
      </div>
      <InlineWidget
        url="https://calendly.com/toby-100/partnership-terms?hide_event_type_details=1&hide_gdpr_banner=1&text_color=0f172a&primary_color=2c7a7b"
        styles={{
          height: "700px",
        }}
        pageSettings={{
          backgroundColor: "ffffff",
          hideEventTypeDetails: false,
          hideLandingPageDetails: false,
        }}
      />
    </div>
  );
}

export default Calendly;
