import React, { useEffect, useState } from "react";
import Routes from "@utils/Routes";
import { useNavigate, useParams } from "react-router-dom";
import { useMutateSpecialistConsultation } from "../../hooks/useMutateSpecialistConsultation";
import { PageInfo } from "../../components/common/PageInfo";
import {
  getFullAddress,
  getSpecialistConsultationBreadcrumbs,
} from "@utils/referralUtils";
import { useSpecialistConsultation } from "../../hooks/useSpecialistConsultation";
import { Button, Heading, LoadingSpinner, TextInput } from "@scandotcom/react";
import { useSpecialists } from "../../hooks/useSpecialists";
import { IconMapPin } from "@tabler/icons";
import { Address } from "../../components/portal/ImagingProviders/Address";
import { type Specialist } from "@services/scan";
import useSubmitSpecialistConsultation from "../../hooks/useSubmitSpecialistConsultation";
import SpecialistsMap from "../../components/portal/SpecialistsMap";

const PageEnterSpecialistConsultationSpecialist = () => {
  const navigate = useNavigate();

  const { specialistConsultationId } = useParams() as {
    specialistConsultationId: string;
  };

  const {
    data: specialistConsultation,
    isLoading: specialistConsultationIsLoading,
  } = useSpecialistConsultation(specialistConsultationId);

  const [searchArea, setSearchArea] = useState<string>();

  const { data: specialists, isLoading: specialistsIsLoading } = useSpecialists(
    specialistConsultation?.specialistType || undefined,
    searchArea
  );

  const specialistConsultationMutation = useMutateSpecialistConsultation(
    specialistConsultationId
  );

  const submitSpecialistConsultationMutation =
    useSubmitSpecialistConsultation();

  useEffect(() => {
    setSearchArea(getFullAddress(specialistConsultation?.patient.address));
  }, [specialistConsultation]);

  const onSelect = async (specialist: Specialist) => {
    const result = await specialistConsultationMutation.mutateAsync({
      id: specialistConsultationId,
      specialist: { id: specialist.id },
    });

    if (result.success && specialistConsultation) {
      const submitResult =
        await submitSpecialistConsultationMutation.mutateAsync(
          specialistConsultationId
        );

      if (submitResult.success) {
        navigate(
          Routes.specialistConsultationReferred(specialistConsultation.id)
        );
      }
    }
  };

  if (specialistConsultationIsLoading || !specialistConsultation) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <PageInfo
        name="Specialist"
        breadcrumbs={getSpecialistConsultationBreadcrumbs(
          specialistConsultation
        )}
        description="Choose a convenient location for your patient."
      />
      <div className="items-[start] container grid gap-6 lg:grid-cols-2">
        <div className="">
          <TextInput
            label="Postcode or location to search"
            hideLabel
            value={searchArea || ""}
            placeholder="Postcode or location to search"
            rounded="full"
            className="mb-4"
            leftIcon={<IconMapPin strokeWidth={1.5} className="h-6 w-6" />}
            onChange={(e) => setSearchArea(e.currentTarget.value)}
          />

          <ul className="space-y-4">
            {specialists &&
              specialists.map((specialist) => (
                <li
                  key={`specialist-${specialist.id}`}
                  className="gap-x-5 rounded-xl border border-cream-300 bg-white p-5 text-cypress-900 md:flex"
                >
                  <div className="w-full">
                    <Heading className="mb-2" variant="h2" size="lg">
                      {specialist.practiceName}
                    </Heading>

                    <Address
                      address={specialist.address}
                      className="mb-3"
                      distance={specialist.distance || undefined}
                    />
                  </div>
                  <div className="mt-5 flex items-center justify-between md:mt-0 md:flex-col md:items-end">
                    <Button
                      data-test="select_specialist"
                      className="mt-auto min-w-max"
                      type="button"
                      onClick={() => onSelect(specialist)}
                    >
                      Select specialist
                    </Button>
                  </div>
                </li>
              ))}
          </ul>

          {!specialistsIsLoading && specialists && specialists.length === 0 && (
            <p>No specialists were found for your search query.</p>
          )}
        </div>

        <div className="relative md:row-span-2">
          <SpecialistsMap
            specialists={specialists || []}
            onSelectSpecialist={onSelect}
          />
        </div>
      </div>
    </>
  );
};

export default PageEnterSpecialistConsultationSpecialist;
