import { client } from "../../client";
import type { ScanReport } from "../../types/common";
import type { RawScanReport } from "../../types/requests";
import { prepScanReports } from "../../utils/prepScanReports";

interface RawResponse {
  data: RawScanReport[];
}

export async function getScanReports(
  referralId?: string | null
): Promise<ScanReport[]> {
  if (!referralId) {
    return [];
  }

  const response = await client<RawResponse>({
    method: "get",
    url: `/api/v1/referrals/${referralId}/scan_reports`,
  });

  const reports = prepScanReports(response?.data);

  return reports;
}
