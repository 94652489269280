import { client } from "../../client";

interface DeleteReferralDocument {
  referralId: string;
  documentId: string;
}

interface RawResponse {
  success: boolean;
}

export async function deleteReferralDocument({
  referralId,
  documentId,
}: DeleteReferralDocument): Promise<Boolean> {
  const response = await client<RawResponse>({
    method: "DELETE",
    url: `/api/v1/referrals/${referralId}/additional_documents/${documentId}`,
  });

  const success = Boolean(response?.success);

  return success;
}
