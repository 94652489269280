import { Referral } from "@services/scan/types/common";
import { getReferralDraftStepLink } from "hocs/WithCompletedStepRestriction";

const Routes = {
  home: "/",
  guide: "/guide",
  referrals: "/",
  newReferral: "/referrals/new",
  newReferralPatient: (patientId?: string) =>
    `/referrals/new${patientId ? `?patient_id=${patientId}` : ""}`,
  newScanReferralPatient: (patientId?: string) =>
    `/referrals/new/patient${patientId ? `/?patient_id=${patientId}` : ""}`,
  walkthrough: "/referrals/walkthrough",
  referral: (referralId: string) => `/referrals/${referralId}`,
  scanDetails: (referralId: string) => `/referrals/${referralId}/scan_details`,
  editScanDetails: (referralId: string) =>
    `/referrals/${referralId}/edit-scan-details`,
  referralPatientInfo: (referralId: string) =>
    `/referrals/${referralId}/patient_information`,
  safetyQuestions: (referralId: string) =>
    `/referrals/${referralId}/safety-questions`,
  imagingProvider: (referralId: string) =>
    `/referrals/${referralId}/imaging-provider`,
  referralConfirmation: (referralId: string) =>
    `/referrals/${referralId}/confirmed`,
  referralSubmitted: (referralId: string) =>
    `/referrals/${referralId}/submitted`,
  referralFlow: (referral: Referral) => getReferralDraftStepLink(referral),
  patients: "/patients",
  newPatient: "/patients/new",
  editPatient: (patientId: string) => `/patients/${patientId}/edit`,
  patient: (patientId: string) => `/patients/${patientId}`,
  editReferralPatient: (referralId: string) =>
    `/referrals/${referralId}/edit-patient-information`,
  payment: "/account/payment",
  profile: "/account/profile",
  signOut: "/users/sign_out",
  newSpecialistConsultationPatient: (patientId?: string) =>
    `/specialist_consultations/new/patient${
      patientId ? `/?patient_id=${patientId}` : ""
    }`,
  specialistConsultation: (specialistConsultationId: string) =>
    `/specialist_consultations/${specialistConsultationId}`,
  specialistConsultationPatient: (specialistConsultationId: string) =>
    `/specialist_consultations/${specialistConsultationId}/patient`,
  specialistConsultationDetails: (specialistConsultationId: string) =>
    `/specialist_consultations/${specialistConsultationId}/details`,
  specialistConsultationSpecialist: (specialistConsultationId: string) =>
    `/specialist_consultations/${specialistConsultationId}/specialist`,
  specialistConsultationReferred: (specialistConsultationId: string) =>
    `/specialist_consultations/${specialistConsultationId}/referred`,
} as const;

export default Routes;
