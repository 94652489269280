import { LoadingSpinner } from "@scandotcom/react";
import PatientForm, { PatientFormFields } from "components/portal/PatientForm";
import { useMutatePatient } from "hooks/useMutatePatient";
import { usePatient } from "hooks/usePatient";
import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import urlSearchParamsToObj from "utils/urlSearchParamsToObj";

interface Props {
  onSuccess?: (value: string) => void;
  isNewBooking?: boolean;
}

function PageUpdatePatient({ onSuccess, isNewBooking }: Props) {
  const navigate = useNavigate();
  const { patientId } = useParams();
  const { search } = useLocation();
  const { patient_id } = urlSearchParamsToObj(search);
  const patientID = patientId || patient_id;

  const { data: patient, isLoading } = usePatient(patientID);
  const mutation = useMutatePatient();

  if (isLoading) {
    return <LoadingSpinner className="my-8" />;
  }

  if (!patient) return <p>Patient not found</p>;

  function onSubmittedPatient(fields: Partial<PatientFormFields>) {
    mutation.mutate(
      { ...fields, patientId: patient?.id },
      {
        onSuccess: (data) => {
          if (!data.success || !data.patient) {
            return;
          }

          if (onSuccess) {
            onSuccess(`${fields.first_name} ${fields.last_name}`);
            return;
          }

          navigate(`/patients/${patientID}`);
        },
      }
    );
  }

  return (
    <div className="px-4 sm:px-4 md:px-8">
      <div className="border-b border-cream-300 py-6">
        <h1 className="pb-2 font-maison-extended text-2xl font-bold text-cypress-800 ">
          Patient Details
        </h1>
        <p className="text-sm text-neutral-700">Update patient information</p>
      </div>
      <PatientForm
        patient={patient}
        onSubmittedPatient={onSubmittedPatient}
        submitLabel={isNewBooking ? "Continue" : "Save"}
        validationErrors={mutation.data?.errors}
      />
    </div>
  );
}

export default PageUpdatePatient;
