import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { fetchPartner } from "services/partnerApi";
import Nav from "components/terms_review/Nav";
import { LoadingSpinner } from "@scandotcom/react";
import { trackEvent } from "services/analytics";

function PageContract({ partnerId }) {
  const [partnerData, setPartnerData] = useState({
    loading: true,
    partner: null,
  });
  const [showAcceptButton, setShowAcceptButton] = useState(false);

  useEffect(() => {
    fetchPartner(partnerId).then(({ partner }) => {
      if (partner?.partner_contract?.accepted_at) {
        window.location.replace(`/o?affiliate=${partner.public_code}`);
        return;
      }
      setPartnerData({ loading: false, partner });
    });
  }, []);

  if (partnerData.loading) {
    return <LoadingSpinner className="my-8" />;
  }

  const { partner } = partnerData;

  if (!partner) {
    return <div>Partner not found</div>;
  }

  const contract = partner.partner_contract;
  const partnerName = partner.name;

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 100;
    if (bottom) {
      setShowAcceptButton(true);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    axios
      .patch(`/api/v1/partners/${partnerId}/accept_contract`)
      .then(() => {
        trackEvent("contract_signed");
        trackEvent("first_sign_in");
        trackEvent("signed_in");
        window.location.href = "/";
      })
      .catch((error) => {
        if (error?.response?.data?.success === false) {
          const { validation_errors } = error.response.data;
          console.log(validation_errors);
        } else {
          // unexpected error
          console.log(error);
        }
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="min-h-screen bg-gray-50 py-12">
        <div className="mx-auto max-w-5xl">
          <Nav
            step="contract"
            title="Review our Contract"
            subTitle="Step 2 - Check our contract for"
            partnerName={partnerName}
          />
          <div className="rounded-xl border border-gray-200 bg-white shadow-sm">
            <div className="w-full">
              <div className="flex w-full flex-col border-b border-gray-200 py-4 px-6">
                <h2 className="text-lg font-semibold text-gray-900">
                  Contract
                </h2>
              </div>
              <div
                data-test="contract_container"
                className="h-96 overflow-scroll overflow-x-hidden p-6"
                id="contract"
                onScroll={handleScroll}
              >
                <p className="leading-8 text-gray-600">
                  <div
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: contract.contract_copy,
                    }}
                  />
                </p>
              </div>
            </div>
          </div>
          <div className="flex w-full justify-center">
            <p className="mt-4 text-gray-500">
              Please read and scroll to the bottom
            </p>
          </div>
          <div className="mt-6 flex w-full items-center justify-center">
            <div className="flex w-fit flex-col space-y-4">
              {showAcceptButton ? (
                <div className="inline-flex rounded-md">
                  <button
                    data-test="submit"
                    type="submit"
                    className="flex w-full flex-col items-center  justify-center rounded-md border-2 border-primary-600 bg-primary-600 px-5 py-3 text-base font-medium text-white hover:border-primary-700 hover:bg-primary-700 sm:flex-row"
                  >
                    <span>On behalf of&nbsp;</span>

                    <span>
                      {partnerName}
                      ,&nbsp;
                    </span>
                    <span>I accept the terms and conditions</span>
                  </button>
                </div>
              ) : (
                <div className="inline-flex rounded-md">
                  <span className="flex w-full flex-col items-center justify-center rounded-md border-2 border-primary-600 bg-primary-600 px-5 py-3 text-base font-medium text-white opacity-50 sm:flex-row">
                    <span>On behalf of&nbsp;</span>

                    <span>
                      {partnerName}
                      ,&nbsp;
                    </span>
                    <span>I accept the terms and conditions</span>
                  </span>
                </div>
              )}

              <a
                href="https://calendly.com/toby-100/partnership-terms?month=2022-06"
                className="w-full"
                target="_blank"
                rel="noreferrer"
              >
                <button
                  type="button"
                  className="inline-flex w-full items-center justify-center rounded-md border-2 border-primary-700 px-5 py-3 text-base font-medium text-primary-700 hover:bg-primary-700 hover:text-white"
                >
                  I want to discuss the T&Cs
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

PageContract.propTypes = {
  partnerId: PropTypes.string.isRequired,
};

export default PageContract;
