import React, { useEffect } from "react";
import { QueryClient } from "react-query";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import pageReloader from "utils/pageReloader";
import { PortalContextProvider } from "utils/PortalContext";

import PortalLayout from "layouts/PortalLayout";

import PageAhpGuide from "pages/portal/PageAhpGuide";

// Referrals
import PageListReferrals from "pages/portal/PageListReferrals";
import PageShowReferral from "pages/portal/PageShowReferral";
import PageCreateReferral from "pages/portal/PageCreateReferral";
import PageEnterScanDetails from "pages/portal/PageEnterScanDetails";
import PageReferralConfirmed from "pages/portal/PageReferralConfirmed";
import PageSelectImagingProvider from "pages/portal/PageSelectImagingProvider";
import PageSafetyQuestions from "pages/portal/PageSafetyQuestions";
import PageEditScanDetails from "pages/portal/PageEditScanDetails";
import PageEditPatientInformation from "pages/portal/PageEditPatientInformation";

// Patients
import PageListPatients from "pages/portal/PageListPatients";
import PageShowPatient from "pages/portal/PageShowPatient";
import PageUpdatePatient from "pages/portal/PageUpdatePatient";
import PageCreatePatient from "pages/portal/PageCreatePatient";
import PageEnterPatientInformation from "pages/portal/PageEnterPatientInformation";

// "Centres Near Me"
import PageImagingProviders from "pages/portal/PageImagingProviders";

import PageAccount from "pages/portal/PageAccount";
import WithEditPaymentPermission from "hocs/withPaymentInformation";
import { WithCompletedStepRestriction } from "hocs/WithCompletedStepRestriction";
import PageReferralWalkthrough from "pages/portal/PageReferralWalkthrough";
import PageNewReferral from "pages/portal/PageNewReferral";
import PageCreateSpecialistConsultation from "pages/portal/PageCreateSpecialistConsultation";
import PageShowSpecialistConsultation from "pages/portal/PageShowSpecialistConsultation";
import PageEnterSpecialistConsultationDetails from "pages/portal/PageEnterSpecialistConsultationDetails";
import PageEnterSpecialistConsultationSpecialist from "pages/portal/PageEnterSpecialistConsultationSpecialist";
import SpecialistConsultationEditable from "../components/portal/SpecialistConsultationEditable";
import PageEnterSpecialistConsultationPatient from "../pages/portal/PageEnterSpecialistConsultationPatient";
import PageSpecialistConsultationReferred from "../pages/portal/PageSpecialistConsultationReferred";

export default function PortalApp() {
  const location = useLocation();

  useEffect(() => {
    // Reload the page if a new version of the code is released.
    // Or if the user is logged out
    pageReloader({ mustBeSignedIn: true });
  }, [location]);

  return (
    <PortalContextProvider>
      <Routes>
        <Route path="/" element={<PortalLayout />}>
          <Route index element={<PageListReferrals />} />
          <Route path="/guide" element={<PageAhpGuide />} />

          <Route path="/referrals/:referralId" element={<PageShowReferral />} />
          <Route path="/referrals/new" element={<PageNewReferral />} />
          <Route
            path="/referrals/new/patient"
            element={<PageCreateReferral />}
          />
          <Route
            path="/referrals/:referralId"
            element={<WithCompletedStepRestriction />}
          >
            <Route
              path="patient_information"
              element={<PageEnterPatientInformation />}
            />
            <Route path="scan_details" element={<PageEnterScanDetails />} />
            <Route
              path="imaging-provider"
              element={<PageSelectImagingProvider />}
            />
            <Route path="safety-questions" element={<PageSafetyQuestions />} />
          </Route>
          <Route
            path="/referrals/:referralId/confirmed"
            element={<PageReferralConfirmed />}
          />
          <Route
            path="/referrals/:referralId/edit-scan-details"
            element={<PageEditScanDetails />}
          />
          <Route
            path="/referrals/:referralId/edit-patient-information"
            element={<PageEditPatientInformation />}
          />
          <Route path="/patients" element={<PageListPatients />} />
          <Route path="/patients/:patientId" element={<PageShowPatient />} />
          <Route
            path="/patients/:patientId/edit"
            element={<PageUpdatePatient />}
          />
          <Route path="/patients/new" element={<PageCreatePatient />} />
          <Route path="/centres-near-me" element={<PageImagingProviders />} />
          <Route path="/account">
            <Route path="profile" element={<PageAccount />} />
            <Route element={<WithEditPaymentPermission />}>
              <Route
                path="payment"
                element={<PageAccount defautlTabIndex={1} />}
              />
            </Route>
            <Route index element={<Navigate to="profile" />} />
          </Route>
          <Route
            path="/referrals/walkthrough"
            element={<PageReferralWalkthrough />}
          />
          <Route
            path="/specialist_consultations/new/patient"
            element={<PageCreateSpecialistConsultation />}
          />
          <Route
            path="/specialist_consultations/:specialistConsultationId"
            element={<PageShowSpecialistConsultation />}
          />
          <Route
            path="/specialist_consultations/:specialistConsultationId"
            element={<SpecialistConsultationEditable />}
          >
            <Route
              path="patient"
              element={<PageEnterSpecialistConsultationPatient />}
            />
            <Route
              path="details"
              element={<PageEnterSpecialistConsultationDetails />}
            />
            <Route
              path="specialist"
              element={<PageEnterSpecialistConsultationSpecialist />}
            />
          </Route>
          <Route
            path="/specialist_consultations/:specialistConsultationId/referred"
            element={<PageSpecialistConsultationReferred />}
          />
        </Route>
      </Routes>
    </PortalContextProvider>
  );
}
