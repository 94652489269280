import { getImagingProviders, GetImagingProvidersProps } from "@services/scan";
import { debounce } from "@utils/debounce";
import { useCallback, useEffect, useState } from "react";
import { useQuery } from "react-query";

interface QueryOptions {
  radius?: string;
  threeT?: boolean;
  modality?: string;
  bodyPartIds?: number[];
  openScanner?: boolean;
  order?: GetImagingProvidersProps["order"];
  direction?: "ASC" | "DESC";
  includeOffNetwork?: boolean;
  includePrices?: boolean;
}

interface useImagingProvidersProps extends QueryOptions {
  limit?: number;
  referralId?: string;
  area?: string;
  maxResults?: number;
  minResults?: number;
  enabled?: (state: QueryOptions) => boolean;
  includeOffNetwork?: boolean;
  includePrices?: boolean;
}

export function useImagingProviders({
  limit = 5,
  referralId = "",
  area = "London",
  radius = "",
  order = "distance",
  direction = "ASC",
  openScanner = false,
  threeT = false,
  enabled,
  modality,
  bodyPartIds,
  maxResults,
  minResults,
  includeOffNetwork,
  includePrices = true,
}: useImagingProvidersProps) {
  const [searchArea, setSearchArea] = useState(area);
  const [pageNumber, setPageNumber] = useState(0);

  const [options, setOptions] = useState<QueryOptions>({
    radius,
    order,
    direction,
    openScanner,
    threeT,
    modality,
    bodyPartIds,
    includeOffNetwork,
    includePrices,
  });

  const query = useQuery(
    ["imaging provider", { referralId, area: searchArea, ...options }],
    () =>
      getImagingProviders({
        referralId,
        area: searchArea,
        bodyParts: options.bodyPartIds,
        minResults,
        maxResults,
        ...options,
      }),
    {
      enabled: enabled?.call(null, options) ?? true,
      onSuccess: (data) => {
        if (data.success) {
          setPage(0);
          setOptions({ ...options, radius: data.pageInfo.radius });
        }
      },
    }
  );

  const providers = query.data?.providers ?? [];

  const total = providers.length;
  const offset = Math.max(pageNumber * limit - limit, 0);
  const hasMore = total > offset + limit;

  function setPage(page: number) {
    setPageNumber(page);
  }

  function setOrder(by: string) {
    let newOptions: Partial<QueryOptions>;

    switch (by) {
      case "price-asc":
        newOptions = { order: "price", direction: "ASC" };
        break;
      case "price-desc":
        newOptions = { order: "price", direction: "DESC" };
        break;
      case "distance-asc":
        newOptions = { order: "distance", direction: "ASC" };
        break;
      case "distance-desc":
        newOptions = { order: "distance", direction: "DESC" };
        break;
      default:
        newOptions = { order: "distance", direction: "ASC" };
    }

    setFilters(newOptions);
  }

  async function setFilters(filters: Partial<QueryOptions>) {
    const newOptions = { ...options, ...filters };

    if (newOptions.modality?.toLowerCase() !== "mri") {
      newOptions.threeT = undefined;
      newOptions.openScanner = undefined;
    }

    setOptions(newOptions);
  }

  const paginatedProviders = providers.slice(offset, offset + limit);

  const hasPrices = paginatedProviders.some(({ price }) => Boolean(price));

  const debounceSetSearch = useCallback(debounce(setSearchArea, 300), []);

  const showMachineType = options.modality?.toLowerCase() === "mri";

  return {
    area: searchArea,
    showMachineType,
    errors: query.data?.errors,
    isLoading: query.isLoading,
    options,
    pageInfo: {
      hasMore,
      offset,
      limit,
      total,
    },
    hasPrices,
    data: paginatedProviders,
    setPage,
    setOrder,
    setFilters,
    setSearch: debounceSetSearch,
  };
}
