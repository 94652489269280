import { NotificationItem } from "@services/scan/types/common";
import classNames from "classnames";
import { addDays, isAfter, startOfToday } from "date-fns";
import React from "react";
import { formatDate } from "utils/dates";

interface Props {
  onClick: () => void;
  item: NotificationItem;
}

const getDateLabel = (dateString: string) => {
  const date = new Date(dateString);

  const today = startOfToday();
  const yesterday = addDays(today, -1);

  if (isAfter(date, today)) {
    return formatDate(dateString, "'Today at' HH:MM");
  }

  if (isAfter(date, yesterday)) {
    return formatDate(dateString, "'Yesterday at' HH:MM");
  }

  return formatDate(dateString, "d MMM, yyyy");
};

export const Notification = ({ item, onClick }: Props) => {
  return (
    <button
      key={item.id}
      type="button"
      className={`block w-full cursor-pointer rounded-xl py-2 px-3 text-left text-sm leading-[22px] text-cream-800 hover:bg-cream-100`}
      onClick={onClick}
    >
      <div className="flex min-h-[22px] w-full items-center gap-2">
        <div
          className={classNames("h-[10px] w-[10px] rounded-full", {
            "bg-cream-300": Boolean(item.readAt),
            "bg-pink-300": !item.readAt,
          })}
        />
        <p className="font-semibold uppercase text-cypress-900">
          {item.referralReference}
        </p>
        <p className="capitalize">{item.name}</p>
      </div>
      <p className="mb-1 text-cypress-900">{item.message}</p>
      <p className="text-xs leading-5">{getDateLabel(item.createdAt)}</p>
    </button>
  );
};
