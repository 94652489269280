import { Disclosure } from "@headlessui/react";
import classNames from "classnames";
import { bool, string } from "prop-types";
import React from "react";
import { Link, useMatch, useResolvedPath } from "react-router-dom";

interface NavigationLinkProps {
  name: string;
  to: string;
  isMobile?: boolean;
  className?: string;
}

export const NavigationLink = ({
  name,
  to,
  isMobile,
  className,
}: NavigationLinkProps) => {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });

  const ariaCurrent = match ? "page" : undefined;

  const customBgColour =
    window.AppData.PartnerPortalSettings?.header_background_colour;

  const linkStyles = classNames(
    "rounded-lg px-5 py-3.5 transition-colors block focus:outline-none",
    "font-maison-extended text-xs font-bold uppercase tracking-wider text-center",
    match && !customBgColour
      ? "bg-cypress-900 text-pink-300"
      : "text-cream-200",
    className,
    {
      "hover:bg-cypress-900/70 focus:bg-cypress-900/70": !customBgColour,
    }
  );

  if (isMobile) {
    return (
      <Disclosure.Button
        as={Link}
        to={to}
        className={linkStyles}
        aria-current={ariaCurrent}
      >
        {name}
      </Disclosure.Button>
    );
  }

  return (
    <Link to={to} className={linkStyles} aria-current={ariaCurrent}>
      {name}
    </Link>
  );
};

NavigationLink.propTypes = {
  name: string.isRequired,
  to: string.isRequired,
  isMobile: bool,
  className: string,
};
