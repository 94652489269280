import { client } from "../../client";
import type {
  PatientMutationFields,
  RawPatient,
  RawValidationError,
} from "../../types/requests";
import { prepPatient } from "../../utils/prepPatients";
import { prepValidationErrors } from "../../utils/prepValidationErrors";

interface RawResponse {
  object?: RawPatient;
  success: boolean;
  validation_errors: RawValidationError[];
}

export async function updatePatient({
  patientId,
  ...data
}: Partial<PatientMutationFields>) {
  if (!patientId) {
    throw new Error("no patient ID provided.");
  }

  const response = await client<RawResponse>({
    data,
    method: "PATCH",
    url: `/api/v1/patients/${patientId}`,
  });

  const errors = prepValidationErrors(response?.validation_errors);
  const patient = prepPatient(response?.object);
  const success = Boolean(response?.success);

  return { success, patient, errors };
}
