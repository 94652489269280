import { createPatient, updatePatient } from "@services/scan";
import { PatientMutationFields } from "@services/scan/types/requests";
import { useMutation, useQueryClient } from "react-query";

export function useMutatePatient() {
  const queryClient = useQueryClient();

  const mutate = useMutation(mutatePatient, {
    onSuccess: (data) => {
      if (!data.success || !data.patient) {
        return;
      }
      queryClient.setQueryData(["patient", data.patient.id], data.patient);
    },
  });

  function mutatePatient(data: Partial<PatientMutationFields>) {
    return data?.patientId ? updatePatient(data) : createPatient(data);
  }

  return mutate;
}
