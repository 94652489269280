import { getModalities } from "@services/scan";
import { useQuery } from "react-query";

export function useModalities() {
  const query = useQuery(["modalities"], getModalities, {
    cacheTime: Infinity,
    staleTime: 1000 * 60 * 60,
  });

  return query;
}
