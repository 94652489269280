import * as React from "react";
import * as A from "fp-ts/Array";
import * as NEA from "fp-ts/NonEmptyArray";
import { formatInTimeZone } from "date-fns-tz";
import { TimeSlot } from "@services/scan/types/common";

interface Props {
  slots: Array<[Date, TimeSlot[]]>;
  SlotLink: React.FC<{
    children: React.ReactNode;
    slot: TimeSlot;
    className: string;
  }>;
}

const TIME_ZONE = "Europe/London";

export const SlotSelector: React.FC<Props> = ({ slots, SlotLink }) => {
  return (
    <div className="space-y-8">
      {slots.map(([day, slotsForDay]) => (
        <DaySlots
          key={day.toISOString()}
          daySlots={slotsForDay}
          slotDay={day}
          timeZone={TIME_ZONE}
          renderSlot={(slot) => (
            <SlotLink
              slot={slot}
              className={`mx-auto block w-full cursor-pointer rounded-md bg-cream-200 px-1 py-3 text-center text-xs tabular-nums transition-colors hover:bg-cream-300 focus:outline-none focus:ring-2 focus:ring-pink-300`}
            >
              {slot.start.toLocaleTimeString(undefined, {
                timeStyle: "short",
                timeZone: TIME_ZONE,
              })}{" "}
              -{" "}
              {slot.end.toLocaleTimeString(undefined, {
                timeStyle: "short",
                timeZone: TIME_ZONE,
              })}
            </SlotLink>
          )}
        />
      ))}
    </div>
  );
};

interface DaySlotsProps {
  renderSlot: (slot: TimeSlot) => React.ReactNode;
  daySlots: TimeSlot[];
  slotDay: Date;
  timeZone: string;
}

const DaySlots: React.FC<DaySlotsProps> = ({
  daySlots,
  slotDay,
  renderSlot,
  timeZone,
}) => {
  const maxSlotPerDay = 7;

  const [expanded, setExpanded] = React.useState(false);
  const [visibleDaySlots, hiddenDaySlots] = A.splitAt(maxSlotPerDay)(daySlots);

  return (
    <div>
      <div className="mb-2 flex justify-between gap-4">
        <h3 className="font-maison-extended font-bold">
          {formatInTimeZone(slotDay, timeZone, "EEEE, LLL d")}
        </h3>
        {A.isEmpty(daySlots) ? (
          <span className="text-neutral-400">No availability for this day</span>
        ) : null}
      </div>
      {A.isNonEmpty(visibleDaySlots) ? (
        <ul className="grid grid-cols-2 gap-2 sm:grid-cols-4">
          {visibleDaySlots.map((slot) => (
            <li key={slot.start.toISOString()}>{renderSlot(slot)}</li>
          ))}

          {A.isNonEmpty(hiddenDaySlots) ? (
            A.size(hiddenDaySlots) === 1 ? (
              <li>{renderSlot(NEA.head(hiddenDaySlots))}</li>
            ) : expanded ? (
              hiddenDaySlots.map((slot) => (
                <li key={slot.start.toISOString()}>{renderSlot(slot)}</li>
              ))
            ) : (
              <li>
                <button
                  onClick={() => setExpanded(true)}
                  type="button"
                  className="grid h-full w-full place-items-center rounded-md border border-cream-200 bg-white py-3 text-xs text-cypress-800 transition-colors hover:bg-cream-50 focus:outline-none focus:ring-2 focus:ring-pink-300"
                >
                  +{hiddenDaySlots.length} more
                </button>
              </li>
            )
          ) : null}
        </ul>
      ) : null}
    </div>
  );
};
