import { Button, Pagination } from "@scandotcom/react";
import { translatePageInfo } from "@utils/translatePageInfo";
import classNames from "classnames";
import { Table } from "components/common/Table";
import { TextLink } from "components/common/TextLink";
import { ImagesModal } from "components/portal/ImagesModal";
import { ListPageHeader } from "components/portal/ListPageHeader";
import { ReportsModal } from "components/portal/ReportsModal";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { formatDate } from "utils/dates";
import {
  isReferralCancellable,
  isReferralDone,
  isReferralDraft,
} from "utils/referralUtils";
import Routes from "utils/Routes";
import { shorten, titleize } from "utils/strings";
import { CancelReferralModal } from "../Modals/CancelReferralModal";
import { ReferralStatusBadge } from "../ReferralStatusBadge";
import { EmptyList } from "./EmptyList";
import { useScanImages } from "hooks/useScanImages";
import { useScanReports } from "hooks/useScanReports";
import { useReferralList } from "hooks/useReferralList";
import { IconCheck } from "@tabler/icons";
import type { Referral, SpecialistConsultation } from "@services/scan";
import { SpecialistConsultationStatusBadge } from "../../common/ShowSpecialistConsultation/SpecialistConsultationStatusBadge";

const limit = 10;
interface ReferralListProps {
  /**
   * Used to fetch referrals for a specific patient.
   */
  patientId?: string | null;
}

export const ReferralList = ({ patientId = null }: ReferralListProps) => {
  const navigate = useNavigate();
  const images = useScanImages();
  const reports = useScanReports();
  const referrals = useReferralList({ limit, patientId });

  const [referralToCancel, setReferralToCancel] = useState<{
    id: string;
    reference: string;
  } | null>(null);

  const getSortDirection = (field: string) => {
    return referrals.pageInfo?.order === field
      ? referrals.pageInfo?.direction
      : undefined;
  };

  if (referrals.hasNoInitialResults) {
    return patientId ? null : <EmptyList resource="referrals" />;
  }

  return (
    <>
      {images.data && (
        <ImagesModal
          isOpen={images.showModal}
          onClose={images.close}
          images={images.data}
          reference={images.referral?.reference}
        />
      )}

      {reports.data && (
        <ReportsModal
          isOpen={reports.showModal}
          onClose={reports.close}
          reports={reports.data}
          reference={reports.referral?.reference}
        />
      )}

      <CancelReferralModal
        isOpen={Boolean(referralToCancel)}
        onClose={() => setReferralToCancel(null)}
        id={referralToCancel?.id}
        reference={referralToCancel?.reference}
      />

      <ListPageHeader
        resource="referrals"
        updateSearch={referrals.setSearch}
        isEmbedded={Boolean(patientId)}
      />

      <Button
        size="sm"
        onClick={() => referrals.toggleStatusFilter("images_not_ready")}
        className="mb-4 flex items-center gap-x-3 px-3.5"
        kind="secondary"
      >
        Images not ready
        {referrals.statusFilters.includes("images_not_ready") && (
          <IconCheck className="h-5 w-5" />
        )}
      </Button>

      <Table>
        <Table.Head className="rounded-tl-lg">
          <Table.Th scope="col">Referral ID</Table.Th>
          <Table.Th scope="col" className={patientId ? "hidden" : ""}>
            Patient
          </Table.Th>
          <Table.Th scope="col">Location</Table.Th>
          <Table.SortableTh
            scope="col"
            onClick={(e) => referrals.setOrder("modality")}
            title="Sort by modality"
            direction={getSortDirection("modality")}
          >
            Type
          </Table.SortableTh>

          <Table.Th scope="col">Status</Table.Th>
          <Table.SortableTh
            scope="col"
            onClick={(e) => referrals.setOrder("updated_at")}
            title="Sort by updated at date"
            direction={getSortDirection("updated_at")}
          >
            Last updated
          </Table.SortableTh>
        </Table.Head>

        <Table.TBody>
          {referrals.data?.map((referral) => {
            const patientHref = Routes.patient(referral.patient.id);
            const referralHref = referralViewOrEditHref(referral);
            const inDraft = referral.status === "In Draft";

            return (
              <Table.Tr
                key={referral.id}
                onClick={() => navigate(referralHref)}
              >
                <Table.Td>
                  <TextLink
                    to={referralHref}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {referral.reference}
                  </TextLink>
                </Table.Td>

                <Table.Td
                  className={classNames(
                    { hidden: !!patientId },
                    "whitespace-nowrap font-semibold"
                  )}
                >
                  {referral.patient.id &&
                  !(
                    referral.type === "Referral" && referral.patientDeletedAt
                  ) ? (
                    <TextLink
                      to={patientHref}
                      onClick={(e) => e.stopPropagation()}
                    >
                      {shorten(referral.patient.fullName, 30)}
                    </TextLink>
                  ) : (
                    shorten(referral.patient.fullName, 30)
                  )}
                </Table.Td>

                <Table.Td>
                  {isReferral(referral)
                    ? shorten(referral.imagingProvider?.name, 50)
                    : referral.specialist?.practiceName}
                </Table.Td>

                <Table.Td>
                  {isReferral(referral)
                    ? referral.modality
                    : titleize(referral.specialistType || "")}
                </Table.Td>

                <Table.Td>
                  {isReferral(referral) ? (
                    <ReferralStatusBadge referral={referral} />
                  ) : (
                    <SpecialistConsultationStatusBadge
                      specialistConsultation={referral}
                    />
                  )}
                </Table.Td>

                <Table.Td className="whitespace-nowrap">
                  {formatDate(referral.updatedAt)}
                </Table.Td>

                <Table.Menu
                  links={[
                    {
                      name: inDraft ? "Edit referral" : "View referral",
                      href: referralHref,
                    },
                  ]}
                  actions={[
                    referral.type === "Referral" &&
                      referral.availableScanReportsCount > 0 && {
                        name: "Download report",
                        onClick: () =>
                          reports.show(referral.id, referral.reference),
                      },
                    referral.type === "Referral" &&
                      referral.approvedScanImagesCount > 0 && {
                        name: "View images",
                        onClick: () =>
                          images.show(referral.id, referral.reference),
                      },
                    referral.type === "Referral" &&
                      isReferralCancellable(referral) &&
                      !inDraft && {
                        name: "Cancel referral",
                        onClick: () =>
                          setReferralToCancel({
                            id: referral.id,
                            reference: referral.reference,
                          }),
                      },
                  ]}
                />
              </Table.Tr>
            );
          })}
        </Table.TBody>
      </Table>

      {(referrals.pageInfo?.total || 0) > limit ? (
        <div className="container mt-8 flex justify-center">
          <Pagination
            meta={translatePageInfo(referrals.pageInfo)}
            onClickPage={referrals.setPage}
          />
        </div>
      ) : null}
    </>
  );
};

function isReferral(
  referral: Referral | SpecialistConsultation
): referral is Referral {
  return (referral as Referral).type === "Referral";
}

const referralViewOrEditHref = (
  referral: Referral | SpecialistConsultation
) => {
  if (referral.type === "Referral") {
    return isReferralDone(referral)
      ? Routes.referral(referral.id)
      : isReferralDraft(referral)
      ? Routes.referralFlow(referral)
      : Routes.scanDetails(referral.id);
  } else if (
    referral.type === "SpecialistConsultation" &&
    referral.status === "In Draft"
  ) {
    return Routes.specialistConsultationPatient(referral.id);
  } else if (
    referral.type === "SpecialistConsultation" &&
    referral.status === "Referred"
  ) {
    return Routes.specialistConsultation(referral.id);
  } else {
    return Routes.referrals;
  }
};
