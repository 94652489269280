import React from "react";
import PropTypes from "prop-types";

export const style = {
  default:
    "inline-flex justify-center font-medium border rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors",
  disabled: "opacity-50 cursor-not-allowed",
  sizes: {
    small: "px-2 py-1 text-xs",
    regular: "px-5 py-2 text-sm",
    large: "px-10 py-4 text-lg",
  },
  colors: {
    primary:
      "text-white bg-primary-600 border-primary-600 hover:bg-primary-700 hover:border-primary-700 focus:ring-primary-500",
    white:
      "text-gray-500 bg-white border-gray-300 hover:text-gray-900 hover:bg-gray-50 focus:ring-0 shadow-sm",
  },
};

function Button({
  children,
  className,
  color,
  disabled,
  size,
  type,
  ...props
}) {
  return (
    <button
      {...props}
      // eslint-disable-next-line react/button-has-type
      type={type}
      disabled={disabled}
      className={`${className} ${style.default} ${style.sizes[size]} ${
        style.colors[color]
      } ${disabled ? style.disabled : ""}`}
    >
      {children}
    </button>
  );
}

Button.defaultProps = {
  className: "",
  color: "primary",
  disabled: false,
  type: "button",
  size: "regular",
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  size: PropTypes.string,
};

export default Button;
