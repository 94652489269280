import {
  H1,
  LoadingSpinner,
  MultiSelect,
  Pagination,
  SelectInput,
  TextInput,
} from "@scandotcom/react";
import { IconMapPin } from "@tabler/icons";
import Routes from "@utils/Routes";
import { translatePageInfo } from "@utils/translatePageInfo";
import {
  MachineTypeFilters,
  SearchRadiusValues,
  SortByValues,
} from "components/portal/ImagingProviders/constants";
import { ImagingProviderCard } from "components/portal/ImagingProviders/ImagingProviderCard";
import { Map } from "components/portal/ImagingProviders/Map";
import { SortBy } from "components/portal/ImagingProviders/SortBy";
import { useBodyParts } from "hooks/useBodyParts";
import { useImagingProviders } from "hooks/useImagingProviders";
import { useModalities } from "hooks/useModalities";
import React, { useEffect, useMemo, useState } from "react";
import { ButtonLink } from "ui/ButtonLink";
import { Modality, BodyPart } from "@services/scan/types/common";

function PageImagingProviders() {
  const [selectedModality, setSelectedModality] = useState<Modality | null>(
    null
  );
  const [selectedBodyParts, setSelectedBodyParts] = useState<BodyPart[] | null>(
    null
  );

  const { data: modalities, isSuccess: modalitiesLoaded } = useModalities();
  const { bodyParts, isSuccess: bodyPartsLoaded } = useBodyParts({
    modalityId: selectedModality?.label,
    enabled: modalitiesLoaded,
  });

  if (modalitiesLoaded && selectedModality === null) {
    setSelectedModality(modalities[0]);
  }

  if (bodyPartsLoaded && selectedBodyParts === null && bodyParts?.[0]) {
    setSelectedBodyParts([bodyParts[0]]);
  }

  const providers = useImagingProviders({
    includeOffNetwork: true,
    enabled: (options) => !!options.modality && !!options.bodyPartIds?.length,
  });

  useEffect(() => {
    providers.setFilters({
      modality: selectedModality?.label,
      bodyPartIds: selectedBodyParts?.map((bp) => bp.id),
    });
  }, [selectedModality, selectedBodyParts]);

  function changeModality(e) {
    const newModality =
      modalities?.find(
        ({ label }) => label == (e.target as HTMLInputElement).value
      ) || null;
    setSelectedModality(newModality);
    setSelectedBodyParts(null);
  }

  function changeBodyParts(ids: number[]) {
    const selectedBodyParts = bodyParts?.filter((bp) => ids.includes(bp.id));
    setSelectedBodyParts(selectedBodyParts);
  }

  return (
    <div className="container">
      <PageHeader />

      <div className="grid grid-rows-[auto,1fr] gap-6 md:grid-cols-2">
        <div className="flex flex-wrap gap-2">
          <SelectInput
            hideLabel
            label="Scan type"
            value={selectedModality?.label}
            onChange={changeModality}
            className="w-full lg:w-fit lg:max-w-[12rem]"
            options={
              modalities?.map(({ label }) => ({ label, value: label })) ?? []
            }
          />

          <div className="w-full max-w-full flex-grow md:w-auto lg:max-w-[70%]">
            <MultiSelect
              predicate={!selectedModality ? "Modality" : undefined}
              hideLabel
              label="Body parts"
              placeholder="Body parts"
              maxSelection={selectedModality?.maxBodyParts}
              items={bodyParts}
              selected={selectedBodyParts?.map((bp) => bp.id) || []}
              setSelected={changeBodyParts}
              collection="Body parts"
            />
          </div>

          <TextInput
            defaultValue={providers.area}
            label="Location Search"
            hideLabel
            className="mb-5 w-full"
            leftIcon={<IconMapPin strokeWidth={1.5} className="h-6 w-6" />}
            onChange={(e) => providers.setSearch(e.target.value)}
          />

          <SelectInput
            size="sm"
            hideLabel
            label="Search Radius"
            value={providers.options.radius}
            options={SearchRadiusValues}
            className="w-[calc(50%-0.25rem)] md:w-fit"
            onChange={(e) =>
              providers.setFilters({ radius: e.currentTarget.value })
            }
          />

          {providers.showMachineType && (
            <SelectInput
              hideLabel
              size="sm"
              label="Type of MRI machine"
              placeholder="Type of machine"
              options={MachineTypeFilters}
              className="w-[calc(50%-0.25rem)] md:w-fit"
              onChange={(e) =>
                providers.setFilters({
                  threeT: e.currentTarget.value === "threeT",
                  openScanner: e.currentTarget.value === "openScanner",
                })
              }
            />
          )}

          {providers.hasPrices && (
            <SortBy
              onChange={(value) => providers.setOrder(value)}
              label="Sort by:"
              order={providers.options.order}
              direction={providers.options.direction}
              options={SortByValues}
              className="mt-3 min-w-fit md:ml-auto md:mt-0"
            />
          )}
        </div>

        <div className="col-span-full md:col-span-1 md:col-start-1">
          {providers.isLoading && <LoadingSpinner />}

          {!providers.isLoading && !providers.data.length && (
            <p>
              No imaging providers were found for your search query. Try a
              different search or increasing the search radius.
            </p>
          )}
          {!providers.isLoading && (
            <ul className="space-y-4">
              {providers.data.map((provider) => (
                <ImagingProviderCard
                  key={provider.id}
                  name={provider.name}
                  address={provider.address}
                  tier={provider.tier}
                  price={provider.price}
                  distance={provider.distance}
                  minAge={provider.minAge}
                  openScanner={provider.openScanner}
                  threeT={provider.threeT}
                  offNetwork={provider.offNetwork}
                />
              ))}
            </ul>
          )}
        </div>

        <Map
          className="top-6 col-span-full md:sticky md:col-span-1 md:col-start-2 md:row-span-full"
          providers={providers.data}
        />

        {Boolean(providers.data.length) && (
          <div className="col-span-full mx-auto mt-6 h-fit md:col-span-1 md:col-start-1">
            <Pagination
              meta={translatePageInfo(providers.pageInfo)}
              onClickPage={(page) => {
                providers.setPage(page);
                window?.document?.documentElement?.scrollTo({
                  top: 300,
                  left: 300,
                });
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}

function PageHeader() {
  return (
    <div className="flex flex-col items-start py-10 sm:flex-row">
      <div className="mb-6 sm:max-w-[50%] sm:pt-1.5">
        <H1 size="4xl" className="mb-4">
          Centres near me
        </H1>
        <p className="text-base font-normal text-cypress-900">
          Search and compare our imaging centres.
        </p>
      </div>
      <ButtonLink to={Routes.newReferral} className="ml-auto w-full sm:w-fit">
        Create a referral
      </ButtonLink>
    </div>
  );
}

export default PageImagingProviders;
