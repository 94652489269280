import usePrompt from "./useRouterBlocker";
import { useEffect } from "react";

export const usePreventPageLeave = (message: string, condition = true) => {
  const unloadHandler = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };

  useEffect(() => {
    if (!condition) return;

    window.addEventListener("beforeunload", unloadHandler, true);

    return () =>
      window.removeEventListener("beforeunload", unloadHandler, true);
  }, [condition]);

  return usePrompt(message, condition);
};
