import { KnownDateInput } from "@scandotcom/react";
import { getDate, getMonth, getYear, parseISO, isValid } from "date-fns/fp";
import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/Option";
import * as E from "fp-ts/Either";
import * as React from "react";

interface Props {
  label: string;
  initialValue: string;
  onChange: (d: string) => void;
  errors: React.ComponentProps<typeof KnownDateInput>["errorMessages"];
}

/**
 * Parse a date string in YYYY-MM-DD format
 */
export const parseDate = (date: string) => {
  return pipe(
    E.tryCatch(
      () => parseISO(date),
      () => "Failed to parse date"
    ),
    O.fromEither,
    O.filter(isValid)
  );
};

/**
 * Allows sync of a date field with the KnownDateInput, which takes the date components
 * separately.
 *
 * Useful for using as a single form element with a form.
 */
export const ControlledDateInput = React.forwardRef<HTMLInputElement, Props>(
  ({ label, initialValue, onChange, errors }, ref) => {
    const date = React.useMemo(() => parseDate(initialValue), [initialValue]);
    const getDatePart = (get: (d: Date) => number) =>
      pipe(
        date,
        O.map(get),
        O.map(String),
        O.getOrElse(() => "")
      );

    const [day, setDay] = React.useState(getDatePart(getDate));
    const [month, setMonth] = React.useState(
      getDatePart((d) => getMonth(d) + 1)
    );
    const [year, setYear] = React.useState(getDatePart(getYear));

    React.useEffect(() => {
      const newDate = [
        year.padStart(4, "0"),
        month.padStart(2, "0"),
        day.padStart(2, "0"),
      ].join("-");
      // trigger the onChange callback with the new date string
      onChange(newDate);
    }, [day, month, year]);

    return (
      <KnownDateInput
        label={label}
        style="normal"
        errorMessages={errors}
        dayInputProps={{
          defaultValue: day,
          onChange: (e) => setDay(e.target.value),
          formNoValidate: true,
        }}
        monthInputProps={{
          defaultValue: month,
          onChange: (e) => setMonth(e.target.value),
          ref: ref,
          formNoValidate: true,
        }}
        yearInputProps={{
          defaultValue: year,
          onChange: (e) => setYear(e.target.value),
          formNoValidate: true,
        }}
      />
    );
  }
);
