import {
  Address,
  Referral,
  Specialist,
  SpecialistConsultation,
} from "@services/scan/types/common";
import { BreadcrumbItem } from "components/common/Breadcrumb";
import Routes from "./Routes";

// If a referral is confirmed, it's done and there's nothing to enter anymore
// Otherwise, if it's submitted,
export function isReferralDone(referral?: Referral | null) {
  if (!referral) {
    return false;
  }

  return (
    referral?.cancelledAt ||
    referral?.patientConfirmedAt ||
    (referral?.submittedAt && !referral?.confirmationByUser)
  );
}

export const isReferralDraft = (referral: Referral) => !!referral.draftedAt;

// A referral can only be cancelled if it hasn't already been cancelled
// And if it hasn't been confirmed.
export const isReferralCancellable = (referral: Referral) =>
  !referral.cancelledAt && !referral.patientConfirmedAt;

export const isReferralEditable = (referral: Referral) =>
  referral.submittedAt &&
  !referral.cancelledAt &&
  !referral.patientConfirmedAt &&
  !window.AppData.ViewReferral;

export function getFullName(patient: Referral["patient"] | null): string {
  if (!patient) {
    return "";
  }

  return [patient.title, patient.firstName, patient.lastName]
    .filter((item) => item)
    .join(" ");
}

/**
 * Function to format a full patent address.
 *
 * @param patient The patient details from the referral object.
 * @param separator The separator to separate the address items.
 *
 * @returns The address.
 */
export function getFullAddress(
  address?: Address | null,
  separator = ", "
): string {
  if (!address) {
    return "";
  }

  return [address.line1, address.line2, address.city, address.postcode]
    .filter((item): item is string => Boolean(item))
    .join(separator);
}

/**
 * Function to get the initials of a persons name.
 *
 * @param names The names of the person.
 *
 * @returns The initials of a persons name.
 *
 * e.g:
 *
 * const initials = getInitials("foo", "bar")
 */
export function getInitials(...names: string[]) {
  const initials = names.reduce((output, current) => {
    return output + current.charAt(0) || "";
  }, "");

  return initials;
}

/**
 * Function to see if a referral flow
 * shows a machine type select input.
 *
 * Machine Type means "Open Scanner" or "3T", these are only relevant to MRI scans.
 *
 * @param referral The current referral.
 * @returns a boolean value.
 */
export function shouldShowMachineType(referral?: Referral | null) {
  return referral?.modality?.toLowerCase() === "mri";
}

/**
 * Function to generate the breadcrumbs for the referral flow.
 *
 * @param referral The referral object.
 *
 * @returns An array of BreadcrumbItem
 */
export function getReferralBreadcrumbs(referral: Referral): BreadcrumbItem[] {
  const completed = referral.completedStep ?? 1;

  return [
    { name: "Referrals", href: Routes.referrals },
    { name: "Patient", href: Routes.referralPatientInfo(referral.id) },
    completed >= 1 && { name: "Scan", href: Routes.scanDetails(referral.id) },
    completed >= 2 && {
      name: "Scanning location",
      href: Routes.imagingProvider(referral.id),
    },
    completed >= 3 && {
      name: "Safety questions",
      href: Routes.safetyQuestions(referral.id),
    },
  ].filter((item): item is BreadcrumbItem => Boolean(item));
}

export function getSpecialistConsultationBreadcrumbs(
  specialistConsultation: SpecialistConsultation | undefined
): BreadcrumbItem[] {
  const breadcrumbs: BreadcrumbItem[] = [
    { name: "Referrals", href: Routes.referrals },
  ];

  if (specialistConsultation?.id) {
    breadcrumbs.push({
      name: "Patient",
      href: Routes.specialistConsultationPatient(specialistConsultation.id),
    });

    breadcrumbs.push({
      name: "Details",
      href: Routes.specialistConsultationDetails(specialistConsultation.id),
    });
  }

  if (
    specialistConsultation?.id &&
    specialistConsultation.specialistType &&
    specialistConsultation.referralInformation
  ) {
    breadcrumbs.push({
      name: "Specialist",
      href: Routes.specialistConsultationSpecialist(specialistConsultation.id),
    });
  }

  return breadcrumbs;
}
