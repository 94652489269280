import { H1 } from "@scandotcom/react";
import { IconCheck } from "@tabler/icons";
import { Modal } from "components/common/Modal";
import ShowReferral from "components/common/ShowReferral";
import { useReferral } from "hooks/useReferral";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ButtonLink } from "ui/ButtonLink";
import Routes from "utils/Routes";

function PageReferralConfirmed() {
  const { referralId } = useParams();

  const { data: referral } = useReferral({ referralId });
  const navigate = useNavigate();

  const referralHref = Routes.referral(referral?.id || "");
  const onClose = () => navigate(referralHref);
  return (
    <div>
      <ShowReferral />

      <Modal onClose={onClose} isOpen className="max-w-lg p-10">
        <Modal.Close onClick={onClose} className="-mt-6 -mr-6" />

        <div className="mb-6 grid h-14 w-14 place-items-center rounded-full bg-pink-50 text-pink-500">
          <IconCheck className="h-7 w-7" />
        </div>

        <H1 size="2xl" className="mb-4">
          Referral Created
        </H1>

        <p className="mb-6 text-base text-cypress-900">
          Your referral has been successfully created.
          {!referral?.confirmationByUser && (
            <>
              <br />
              <br />
              The patient will receive an email with a link to a booking form.
            </>
          )}
        </p>

        <ButtonLink to={referralHref} kind="secondary" className="block w-fit">
          View referral
        </ButtonLink>
      </Modal>
    </div>
  );
}

export default PageReferralConfirmed;
