import classNames from "classnames";
import React, { ComponentProps } from "react";

interface TagProps extends ComponentProps<"div"> {}

export function Tag({ children, className, ...props }: TagProps) {
  const tagStyles = classNames(
    "rounded-full bg-cream-200 px-3 py-1",
    className
  );

  return (
    <div {...props} className={tagStyles}>
      {children}
    </div>
  );
}
