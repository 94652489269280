import React from "react";
import Routes from "utils/Routes";
import classNames from "classnames";
import { Heading } from "@scandotcom/react";
import { Link } from "react-router-dom";
import { ButtonLink } from "ui/ButtonLink";
import { ListPageHeader } from "../ListPageHeader";
import { ReferralTableIcon } from "./TableIcon";
import { WelcomeCard } from "./WelcomeCard";

const WelcomeCards = () => (
  <div className="mb-10 flex flex-col justify-start gap-4 font-maison sm:flex-row">
    <WelcomeCard
      route={Routes.walkthrough}
      image={window.AppData.WalthroughScreenshot}
      heading="Referral walkthrough"
      description="Get a quick tour of the Scan.com portal to help you find your way around."
      linkLabel="Start walkthrough"
    />
    <WelcomeCard
      route={Routes.guide}
      image={window.AppData.GuideScreenshot}
      heading="Getting started guide"
      description="Learn how to use the portal and how the process works for you and your patients."
      linkLabel="Read now"
    />
  </div>
);

interface ModalProps {
  className?: string;
  resource?: EmptyListProps["resource"];
}
const GetStartedModal = ({ className, resource }: ModalProps) => {
  const modalStyles = classNames(
    "w-full max-w-lg rounded-xl border border-cream-300 bg-white px-6 py-10 text-center md:px-12 md:py-14 h-fit",
    className
  );

  const title =
    resource === "referrals"
      ? "You haven't created a referral yet"
      : "You have no patients yet";

  return (
    <div className={modalStyles}>
      <ReferralTableIcon className="mx-auto mb-8" />
      <Heading variant="h2" size="xl" className="mb-2.5">
        {title}
      </Heading>
      <p className="mb-6 text-sm leading-relaxed">
        {resource === "referrals" ? (
          <>
            Not sure where to get started? You can view our demo referral
            walkthrough or read our{" "}
            <Link to={Routes.guide} className="underline">
              guide
            </Link>{" "}
            to diagnostic scans.
          </>
        ) : (
          "Your patients will show up here after you create your first referral."
        )}
      </p>

      <div className="flex flex-col gap-4 md:flex-row">
        <ButtonLink to={Routes.guide} kind="secondary" className="w-full">
          Getting started guide
        </ButtonLink>

        {resource === "referrals" ? (
          <ButtonLink to={Routes.newReferral} kind="primary" className="w-full">
            Create a referral
          </ButtonLink>
        ) : (
          <ButtonLink to={Routes.newPatient} kind="primary" className="w-full">
            Create a patient
          </ButtonLink>
        )}
      </div>
    </div>
  );
};

interface EmptyListProps {
  resource?: "referrals" | "patients";
}

export const EmptyList = ({ resource }: EmptyListProps) => {
  return (
    <>
      <ListPageHeader resource={resource} />
      {resource === "referrals" && <WelcomeCards />}
      <div className="relative">
        <img
          src={window.AppData.ReferralTableBlurred}
          className="hidden w-full object-cover sm:block"
        />
        <GetStartedModal
          className="inset-0 m-auto md:absolute"
          resource={resource}
        />
      </div>
    </>
  );
};

<>
  <WelcomeCards />
  <div className="relative">
    <img
      src={window.AppData.ReferralTableBlurred}
      className="hidden w-full rounded-xl object-cover sm:block"
    />
    <GetStartedModal className="inset-0 m-auto md:absolute" />
  </div>
</>;
