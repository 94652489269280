import type { PageInfo as PaginationPageInfo } from "@scandotcom/react/dist/esm/Pagination";
import { PageInfo } from "@services/scan/types/common";

/**
 * Translates the pagination metadata from the scan API to a format that can
 * be used for the `Pagination` component from `@scandotcom/react`
 *
 * @param pageInfo
 * @returns PageInfo
 */
export const translatePageInfo = (pageInfo?: {
  offset: number;
  hasMore: boolean;
  limit: number;
  total: number;
}): PaginationPageInfo => {
  const offset = pageInfo?.offset || 0;
  const hasMore = Boolean(pageInfo?.hasMore);
  const limit = pageInfo?.limit || 5;
  const total = pageInfo?.total || 0;

  return {
    isLastPage: !hasMore,
    isFirstPage: offset === 0,
    currentPage: Math.floor(offset / limit) + 1,
    totalPages: Math.ceil(total / limit),
  };
};
