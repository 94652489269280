import { prepReferral } from "@services/scan/utils/prepReferrals";
import { client } from "../../client";
import type { TimeSlot } from "../../types/common";
import type { RawReferral, RawValidationError } from "../../types/requests";
import { prepValidationErrors } from "../../utils/prepValidationErrors";

interface SetTimeSlotProps {
  referralId: string;
  slotIds: TimeSlot["ids"];
}

interface RawResponse {
  success: boolean;
  validation_errors: RawValidationError[];
  object: RawReferral;
}

export async function setTimeSlots({ referralId, slotIds }: SetTimeSlotProps) {
  const request = await client<RawResponse>({
    method: "patch",
    url: `/api/v1/referrals/${referralId}/pick_time_slots`,
    data: { time_slot_ids: slotIds },
  });

  const success = Boolean(request?.success);
  const referral = prepReferral(request?.object);
  const errors = prepValidationErrors(request?.validation_errors);

  return { success, errors, referral };
}
