import { client } from "../../client";
import type { RawImageRequest } from "../../types/requests";
import { prepImageRequests } from "../../utils/prepImageRequests";

interface RawResponse {
  data: RawImageRequest[];
}

interface RespondToImageRequestsProps {
  referralId: string;
  requestId: string;
  action: "approve" | "deny";
}

export async function respondToImageRequests({
  referralId,
  requestId,
  action,
}: RespondToImageRequestsProps) {
  const response = await client<RawResponse>({
    method: "patch",
    url: `/api/v1/referrals/${referralId}/scan_image_requests/${requestId}/${action}`,
  });

  const requests = prepImageRequests(response?.data);

  return requests;
}
