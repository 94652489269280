import { client } from "../../client";
import type {
  RawImagingProvider,
  RawValidationError,
} from "../../types/requests";
import { prepImagingProviders } from "../../utils/prepImagingProviders";
import { prepValidationErrors } from "../../utils/prepValidationErrors";

export interface GetImagingProvidersProps {
  referralId?: string;
  area?: string;
  radius?: string;
  threeT?: boolean;
  openScanner?: boolean;
  includePrices?: boolean;
  minResults?: number;
  maxResults?: number;
  modality?: string;
  bodyParts?: (string | number)[];
  order?: "price" | "distance";
  direction?: "ASC" | "DESC";
  includeOffNetwork?: boolean;
}

interface RawResponse {
  success: boolean;
  data: RawImagingProvider[];
  validation_errors: RawValidationError[];
  page_info: {
    body_part_ids: number[];
    modality: string;
    search_area: string;
    search_area_latitude: number;
    search_area_longitude: number;
    search_radius: string;
  };
}

export async function getImagingProviders({
  referralId,
  area,
  radius,
  threeT,
  openScanner,
  includePrices,
  minResults,
  maxResults,
  modality,
  bodyParts,
  order,
  direction,
  includeOffNetwork,
}: GetImagingProvidersProps) {
  const url = referralId
    ? `/api/v1/referrals/${referralId}/imaging_providers`
    : "/api/v1/imaging_providers";

  const response = await client<RawResponse>({
    method: "get",
    url,
    params: {
      order,
      modality,
      search_area: area,
      search_radius: radius,
      max_results: maxResults,
      min_results: minResults,
      body_part_ids: bodyParts,
      three_t: threeT || undefined,
      include_prices: includePrices,
      open_scanner: openScanner || undefined,
      include_off_network: includeOffNetwork && includeOffNetwork.toString(),
    },
  });

  const success = Boolean(response?.success);
  const pageInfo = prepPageInfo(response?.page_info);
  const providers = prepImagingProviders(response?.data);
  const errors = prepValidationErrors(response?.validation_errors);

  return {
    success,
    errors,
    providers: direction === "ASC" ? providers : providers.reverse(),
    pageInfo,
  };
}

function prepPageInfo(rawInfo?: RawResponse["page_info"]) {
  return {
    area: rawInfo?.search_area ?? "",
    radius: rawInfo?.search_radius ?? "25",
    bodyPartIds: rawInfo?.body_part_ids ?? [],
    modality: rawInfo?.modality,
  };
}
