import { Referral } from "@services/scan/types/common";
import React from "react";
import { Panel } from "../Panel";

interface AdminNotesProps {
  referral?: Referral;
}

export const AdminNotes = ({ referral }: AdminNotesProps) => {
  if (!referral || (referral.adminNotes || "").trim() == "") {
    return null;
  }

  return (
    <Panel title="Admin Notes" className="mt-4">
      <div className="-mt-5 whitespace-pre-wrap text-base font-normal">
        {referral.adminNotes}
      </div>
    </Panel>
  );
};
