import type {
  Referral,
  ReferralStatus,
  SpecialistConsultation,
} from "../types/common";
import type { RawReferral, RawSpecialistConsultation } from "../types/requests";
import { prepImagingProvider } from "./prepImagingProviders";
import { prepSpecialistConsultation } from "./prepSpecialistConsultation";

export function prepReferral(referral?: RawReferral): Referral | null {
  if (!referral) {
    return null;
  }

  const patient = prepReferralPatient(
    referral.patient_id,
    referral.patient_information
  );

  const imagingProvider = prepImagingProvider(referral.imaging_provider);

  const scanReport =
    referral.scan_report_filename && referral.scan_report_url
      ? {
          url: referral.scan_report_url,
          filename: referral.scan_report_filename,
        }
      : null;

  const patientReport =
    referral.patient_report_filename && referral.patient_report_url
      ? {
          url: referral.patient_report_url,
          filename: referral.patient_report_filename,
        }
      : null;

  const referralForm =
    referral.referral_form_filename && referral.referral_form_url
      ? {
          url: referral.referral_form_url,
          filename: referral.referral_form_filename,
        }
      : null;

  const referralDocument =
    referral.referral_document_filename && referral.referral_document_url
      ? {
          url: referral.referral_document_url,
          filename: referral.referral_document_filename,
        }
      : null;

  return {
    type: "Referral",
    id: referral.id,
    reference: referral.reference,
    bodyParts: referral.body_parts,
    patient,
    imagingProvider,

    scanReport,
    patientReport,
    referralForm,
    referralDocument,
    approvedScanImagesCount: referral.approved_scan_images_count ?? 0,
    availableScanReportsCount: referral.approved_scan_images_count ?? 0,

    createdAt: referral.created_at ?? "",
    updatedAt: referral.updated_at ?? "",
    submittedAt: referral.submitted_at,
    patientEmailSentAt: referral.patient_email_sent_at,
    cancelledAt: referral.cancelled_at,
    paidAt: referral.paid_at,
    receivedAt: referral.received_at,
    completedAt: referral.completed_at,
    onHoldAt: referral.on_hold_at,
    patientReportAddedAt: referral.patient_report_added_at,
    patientConfirmedAt: referral.patient_confirmed_at,
    scanBookedAt: referral.scan_booked_at,
    scanImagesRequestedAt: referral.scan_images_requested_at,
    patientDeletedAt: referral.patient_deleted_at,
    draftedAt: referral.drafted_at,
    bookingTime: referral.booking_time,
    confirmedBookingTime: referral.confirmed_booking_time,

    cancellationReason: referral.cancellation_reason,
    completedStep: referral.completed_step ?? 0,
    confirmationByUser: Boolean(referral.confirmation_by_user),
    modality: referral.modality,
    referralInformation: referral.referral_information,
    status: referral.status as ReferralStatus,
    reportStatus: referral.report_status,
    safetyQuestions: referral.safety_question_list,
    internalReference: referral.internal_reference,
    adminNotes: referral.admin_notes,
  };
}

export function prepReferrals(
  rawReferral?: (RawReferral | RawSpecialistConsultation)[]
): (Referral | SpecialistConsultation)[] {
  const referrals = rawReferral
    ?.map((scanOrConsultation) => {
      if (scanOrConsultation.reference.match(/^R\d+/)) {
        return prepReferral(scanOrConsultation as RawReferral);
      } else {
        return prepSpecialistConsultation(
          scanOrConsultation as RawSpecialistConsultation
        );
      }
    })
    .filter((ref): ref is Referral | SpecialistConsultation => Boolean(ref));

  return referrals || [];
}

function prepReferralPatient(
  id: string,
  rawPatient: RawReferral["patient_information"]
): Referral["patient"] {
  const title = rawPatient.title ?? "";
  const firstName = rawPatient.first_name ?? "";
  const lastName = rawPatient.last_name ?? "";
  const fullName = `${firstName} ${lastName}`;

  const email = rawPatient.email ?? "";
  const phone = rawPatient.phone ?? "";
  const alternatePhone = rawPatient.alternate_phone ?? "";
  const gender = rawPatient.gender ?? "";
  const dateOfBirth =
    rawPatient.date_of_birth_iso ?? rawPatient.date_of_birth ?? "";

  const line1 = rawPatient.address_line_1 ?? "";
  const line2 = rawPatient.address_line_2 ?? "";
  const city = rawPatient.address_city ?? "";
  const postcode = rawPatient.address_postcode ?? "";

  const gpDetails = rawPatient.gp_details ?? "";

  return {
    id,
    title,
    firstName,
    lastName,
    fullName,
    email,
    dateOfBirth,
    gender,
    gpDetails,
    phone,
    alternatePhone,
    address: {
      line1,
      line2,
      city,
      postcode,
    },
  };
}
