import { LoadingSpinner } from "@scandotcom/react";
import { submitReferral } from "@services/scan";
import { ValidationError } from "@services/scan/types/common";
import { currentUser } from "@utils/PortalContext";
import { getReferralBreadcrumbs } from "@utils/referralUtils";
import Routes from "@utils/Routes";
import { PageInfo } from "components/common/PageInfo";
import ScanDetailsForm, {
  ScanDetailsFields,
} from "components/portal/ScanDetailsForm";
import { useAdditionalDocuments } from "hooks/useAdditionalDocuments";
import { useModalities } from "hooks/useModalities";
import { useMutateReferral } from "hooks/useMutateReferral";
import { useReferral } from "hooks/useReferral";
import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

function PageEnterScanDetails() {
  const user = currentUser();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { referralId } = useParams();
  const modalities = useModalities();
  const { data: referral } = useReferral({ referralId });
  const mutation = useMutateReferral();
  const completeReferral = useMutation(submitReferral);

  const documents = useAdditionalDocuments(referralId);

  const [validationErrors, setValidationErrors] = useState<ValidationError[]>(
    []
  );

  if (documents.isLoading || !referral || modalities.isLoading) {
    return <LoadingSpinner className="my-8" />;
  }

  // The `confirmation_by_user` option is set at the partner level.
  // And copied to the referral during its creation.
  // If true, the confirmation flow needs to be done by the user here in the portal.
  // If false, we can submit the referral on this page and the confirmation flow will be done by the patient.
  // The confirmation flow is the selection of an imaging provider, a time slot, and answering safety questions.
  const isPatientLed = referral.confirmationByUser && !user?.nonPatientLedFlow;

  function onSubmit(id: string) {
    completeReferral.mutate(id, {
      onSuccess: (data) => {
        if (!data.success || !data.referral) {
          setValidationErrors(data.errors);
          return;
        }

        queryClient.setQueryData(["referral", data.referral.id], data.referral);
        navigate(Routes.referralConfirmation(id));
      },
    });
  }

  function onUpdate(data: ScanDetailsFields, draft: boolean) {
    if (!referral?.id) {
      return;
    }

    const { body_part_ids, ...fields } = data;

    const modalityChanged = referral.modality !== fields.modality;

    const oldBodyPartIds = referral.bodyParts?.map((part) => part.id) ?? [];

    const bodyPartsChanged =
      oldBodyPartIds.length !== body_part_ids.length ||
      oldBodyPartIds.every((val) => body_part_ids.includes(val));

    let completed_step: number | undefined;

    if (modalityChanged || bodyPartsChanged) {
      completed_step = draft ? 1 : 2;
    } else if (!draft && !referral.imagingProvider) {
      completed_step = 2;
    }

    mutation.mutate(
      { referralId: referral.id, completed_step, ...fields, body_part_ids },
      {
        onSuccess: (data) => {
          if (!data.success) {
            setValidationErrors(data.errors);
            return;
          }

          if (draft) {
            return navigate(Routes.home, {
              state: { successMessage: "Referral draft has been saved" },
            });
          }

          if (!isPatientLed) {
            return onSubmit(referral.id);
          }

          navigate(Routes.imagingProvider(referral.id));
        },
      }
    );
  }

  return (
    <>
      <PageInfo
        name="Scan details"
        breadcrumbs={getReferralBreadcrumbs(referral)}
        description="Enter scan details here. All referrals are checked by our team of clinicians to ensure the correct modality and body areas have been chosen for the patient's needs."
      />

      <div className="container">
        <ScanDetailsForm
          modalities={modalities.data ?? []}
          validationErrors={validationErrors}
          showConsentCheckboxes={window.AppData.Oneoff}
          additionalDocuments={documents.data}
          referral={referral}
          onSave={(data) => onUpdate(data, false)}
          onDraft={(data) => onUpdate(data, true)}
          submitLabel={isPatientLed ? "Continue" : "Submit referral"}
        />
      </div>
    </>
  );
}

export default PageEnterScanDetails;
