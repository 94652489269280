import type { Patient } from "../types/common";
import type { RawPatient } from "../types/requests";

export function prepPatient(patient?: RawPatient): Patient | null {
  if (!patient) {
    return null;
  }

  const firstName = patient.first_name;
  const lastName = patient.last_name;
  const fullName = `${firstName} ${lastName}`;

  return {
    id: patient.id,
    firstName,
    lastName,
    fullName,
    email: patient.email,
    title: patient.title,
    phone: patient.phone,
    alternatePhone: patient.alternate_phone,
    dateOfBirth: patient.date_of_birth_iso || patient.date_of_birth,
    gender: patient.gender,
    medicalNotes: patient.medical_notes,
    createdAt: patient.created_at,
    updatedAt: patient.updated_at,
    address: {
      line1: patient.address.line_1,
      line2: patient.address.line_2,
      city: patient.address.city,
      postcode: patient.address.postcode,
    },
  };
}

export function prepPatients(RawPatient?: RawPatient[]): Patient[] {
  const patients = RawPatient?.map(prepPatient).filter(
    (patient): patient is Patient => Boolean(patient)
  );

  return patients || [];
}
