import type { User } from "@services/scan/types/common";
import type { RawUser } from "@services/scan/types/requests";
import { prepUser } from "@services/scan/utils/prepUser";
import { client } from "../../client";

interface RawResponse {
  object: RawUser;
}

export async function getUser(): Promise<User | null> {
  const response = await client<RawResponse>({
    method: "get",
    url: "/api/v1/me",
  });

  const user = prepUser(response?.object);

  return user;
}
