import { getUser } from "@services/scan";
import { User } from "@services/scan/types/common";
import React, { createContext, ReactNode, useContext } from "react";
import { useQuery } from "react-query";

interface PortalContextType {
  currentUser?: User | null;
}

export const PortalContext = createContext<PortalContextType>({
  currentUser: null,
});

interface Props {
  children: ReactNode;
}

export const PortalContextProvider = ({ children }: Props) => {
  const { data: user } = useQuery("me", getUser, {
    staleTime: 1000 * 60 * 4.5,
    refetchInterval: 1000 * 60 * 5,
  });

  return (
    <PortalContext.Provider value={{ currentUser: user }}>
      {children}
    </PortalContext.Provider>
  );
};

export const currentUser = () => useContext(PortalContext).currentUser;
