import { MapPinIcon } from "@heroicons/react/24/solid";
import notifyError from "@services/notifyError";
import type { Specialist } from "@services/scan";
import mapBounds from "@utils/mapBounds";
import classNames from "classnames";
import React, { useEffect, useMemo, useRef, useState } from "react";
import MapBox, { MapRef, Marker, NavigationControl } from "react-map-gl";
import { SpecialistMapPopup } from "./SpecialistMapPopup";

interface MapBoxProps {
  className?: string;
  specialists: Specialist[];
  onSelectSpecialist?: (specialist: Specialist) => void;
}

const initialState = { latitude: 51.5072, longitude: 0.1276, zoom: 9 };
const mapStyle = "mapbox://styles/scandotcom/cl8wv327p004x15n2506gaeis";
const token = window.AppData.MapboxAccessToken;

if (!token) {
  notifyError("MapboxAccessToken missing");
}

export default function SpecialistsMap({
  className,
  specialists = [],
  onSelectSpecialist,
}: MapBoxProps) {
  const mapRef = useRef<MapRef>(null);
  const [selectedSpecialist, setSelectedSpecialist] =
    useState<Specialist | null>(null);

  const bounds = useMemo(() => mapBounds(specialists), [specialists]);

  const fitToBounds = () => {
    mapRef.current?.resize();
    mapRef?.current?.fitBounds(bounds, {
      padding: 40,
      maxZoom: specialists.length ? 16 : 10,
    });
  };

  const wrapperStyles = classNames(
    "border-cream-300 overflow-hidden rounded-xl border h-[600px] lg:min-h-[calc(100vh-3em)] w-full lg:sticky inset-y-6",
    className
  );

  function onMarkerClick(specialist: Specialist) {
    setSelectedSpecialist(specialist);
    mapRef?.current?.panTo([specialist.longitude, specialist.latitude]);
  }

  function onClosePopup() {
    setSelectedSpecialist(null);
    fitToBounds();
  }

  useEffect(() => {
    fitToBounds();
  }, [bounds]);

  return (
    <div className={wrapperStyles}>
      <MapBox
        onLoad={fitToBounds}
        mapboxAccessToken={token}
        ref={mapRef}
        attributionControl={false}
        initialViewState={initialState}
        style={{ width: "100%", height: "100%" }}
        mapStyle={mapStyle}
      >
        <NavigationControl position="top-right" showCompass={false} />
        {specialists.map((specialist) => (
          <Marker
            anchor="bottom"
            key={specialist.id}
            latitude={specialist.latitude}
            longitude={specialist.longitude}
            onClick={(e) => {
              e.originalEvent.stopPropagation();
              onMarkerClick(specialist);
            }}
          >
            <MapPinIcon className="h-10 w-10 text-pink-300 transition-transform hover:scale-125" />
          </Marker>
        ))}

        {selectedSpecialist && (
          <SpecialistMapPopup
            specialist={selectedSpecialist}
            onClose={onClosePopup}
            onSelect={
              onSelectSpecialist
                ? () => onSelectSpecialist(selectedSpecialist)
                : undefined
            }
          />
        )}
      </MapBox>
    </div>
  );
}
