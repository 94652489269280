import { getNotifications } from "@services/scan/requests/notifications/getNotifications";
import { useQuery } from "react-query";

function useNotifications() {
  const { data } = useQuery("notifications", getNotifications, {
    staleTime: 1000 * 60,
    refetchInterval: 1000 * 60,
  });

  return {
    all: data?.all || [],
    read: data?.read || [],
    unread: data?.unread || [],
  };
}

export default useNotifications;
