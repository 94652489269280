import React from "react";
import PropTypes from "prop-types";

export default function CounterVertical({
  count,
  title,
  description,
  completed,
}) {
  return (
    <div className="mb-12 flex">
      <div className="relative h-fit w-fit">
        {count !== "3" && (
          <span className="absolute top-8 left-8 z-0 h-24 w-0.5 bg-gray-100" />
        )}
        {completed ? (
          <span className="text-cool-gray-700 bg-cool-gray-200 z-100 absolute inline-flex h-16 w-16 items-center justify-center rounded-full border-8 border-white bg-primary-500 p-2 text-sm font-medium">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-check"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="#FFFFFF"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M5 12l5 5l10 -10" />
            </svg>
          </span>
        ) : (
          <span className="text-cool-gray-700 bg-cool-gray-200 z-100 absolute inline-flex h-16 w-16 items-center justify-center rounded-full border-8 border-white bg-gray-100 p-2 text-sm font-medium">
            {count}
          </span>
        )}
      </div>
      <div className="ml-24 flex flex-col items-start justify-start">
        <h2 className="mb-2 text-left text-lg font-semibold">{title}</h2>
        <div className="md:text-md space-y-4 text-left text-sm text-gray-400">
          {description}
        </div>
      </div>
    </div>
  );
}

CounterVertical.propTypes = {
  count: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  completed: PropTypes.bool,
};

CounterVertical.defaultProps = {
  count: "",
  title: "",
  description: "",
  completed: false,
};
