import { LoadingSpinner } from "@scandotcom/react";
import { ValidationError } from "@services/scan";
import { currentUser } from "@utils/PortalContext";
import { PageInfo } from "components/common/PageInfo";
import PatientForm, { PatientFormFields } from "components/portal/PatientForm";
import { useMutatePatient } from "hooks/useMutatePatient";
import { usePatient } from "hooks/usePatient";
import React, { useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Routes from "utils/Routes";
import { useMutateSpecialistConsultation } from "../../hooks/useMutateSpecialistConsultation";
import { useSpecialistConsultation } from "../../hooks/useSpecialistConsultation";
import { getSpecialistConsultationBreadcrumbs } from "@utils/referralUtils";

const PageEnterSpecialistConsultationPatient = () => {
  const navigate = useNavigate();
  const user = currentUser();
  const { specialistConsultationId } = useParams() as {
    specialistConsultationId: string;
  };

  const patientMutation = useMutatePatient();

  const { data: specialistConsultation, isLoading } = useSpecialistConsultation(
    specialistConsultationId
  );

  const specialistConsultationMutation = useMutateSpecialistConsultation(
    specialistConsultationId
  );

  const [errors, setErrors] = useState<ValidationError[]>([]);

  async function submit(
    patientFields: Partial<PatientFormFields>,
    isDraft: boolean
  ) {
    const patientRes = await patientMutation.mutateAsync({
      patientId: specialistConsultation?.patient.id,
      ...patientFields,
    });

    if (!patientRes.success || !patientRes.patient) {
      setErrors(patientRes.errors);
      return;
    }

    const { address, ...fields } = patientFields;

    const specialistConsultationRes =
      await specialistConsultationMutation.mutateAsync({
        id: specialistConsultationId,
        patient_id: patientRes.patient.id,
        patient_information: {
          ...fields,
          address_line_1: address?.line_1,
          address_line_2: address?.line_2,
          address_city: address?.city,
          address_postcode: address?.postcode,
        },
      });

    if (!specialistConsultationRes.success) {
      setErrors(specialistConsultationRes.errors);
      return;
    }

    if (isDraft) {
      return navigate(Routes.home, {
        state: {
          successMessage:
            "Specialist consultation referral draft has been saved",
        },
      });
    }

    navigate(
      Routes.specialistConsultationDetails(
        specialistConsultationRes.specialistConsultation?.id || ""
      )
    );

    return;
  }

  if (isLoading) {
    return <LoadingSpinner className="my-8" />;
  }

  return (
    <>
      <PageInfo
        name="Patient"
        breadcrumbs={getSpecialistConsultationBreadcrumbs(
          specialistConsultation
        )}
        description="Provide the details of the patient you are referring for a specialist consultation."
      />
      <div className="container">
        <PatientForm
          hasGPDetails={user?.nonPatientLedFlow}
          onSubmittedPatient={(data) => submit(data, false)}
          onDraftPatient={(data) => submit(data, true)}
          submitLabel="Continue"
          validationErrors={errors}
          patient={specialistConsultation?.patient}
        />
      </div>
    </>
  );
};

export default PageEnterSpecialistConsultationPatient;
