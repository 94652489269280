import * as React from "react";
import { Link, LinkProps } from "react-router-dom";

import { getButtonStyles, Button } from "@scandotcom/react";

type ButtonKind = NonNullable<React.ComponentProps<typeof Button>["kind"]>;
type ButtonSize = NonNullable<React.ComponentProps<typeof Button>["size"]>;

interface Props extends LinkProps {
  kind?: ButtonKind;
  size?: ButtonSize;
}

export const ButtonLink: React.FC<Props> = ({
  className,
  kind = "primary",
  size = "md",
  ...props
}) => (
  <Link {...props} className={getButtonStyles({ kind, size }, className)} />
);
