import axios from "axios";
import PubSub from "pubsub-js";

export async function getOneoffToken(): Promise<string> {
  const shouldRedirect =
    !window.AppData.OneoffAccessToken &&
    !window.location.href.includes("/o/referrals/new");

  if (shouldRedirect) {
    window.location.href = "/o/referrals/new";
  }

  const tokenValid =
    window.AppData.OneoffAccessTokenExpiresAt >
    Math.round(Date.now() / 1000) + 60;

  if (tokenValid) {
    return window.AppData.OneoffAccessToken;
  }

  if (window.AppData.OneoffAccessTokenRefreshing) {
    console.log("Already refreshing Oneoff access_token");
    return new Promise<void>((resolve) => {
      const subscriptionToken = PubSub.subscribe(
        `ONEOFF_ACCESS_TOKEN_REFRESHED_${window.AppData.OneoffAccessTokenRefreshing}`,
        () => {
          resolve();
          setTimeout(() => {
            PubSub.unsubscribe(subscriptionToken);
          }, 60 * 1000);
        }
      );
    }).then(() => window.AppData.OneoffAccessToken);
  }

  window.AppData.OneoffAccessTokenRefreshing = `${Math.random()}`;
  console.log("Refreshing oneoff access_token");
  const response = await axios.request({
    url: "/api/v1/oneoff_tokens",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-TOKEN": window.AppData.CsrfToken,
    },
  });
  const { access_token, access_token_expires_in } = response.data;

  const now = Math.round(Date.now() / 1000);
  window.AppData.OneoffAccessToken = access_token;
  window.AppData.OneoffAccessTokenExpiresIn = access_token_expires_in;
  window.AppData.OneoffAccessTokenExpiresAt = now + access_token_expires_in;
  PubSub.publish(
    `ONEOFF_ACCESS_TOKEN_REFRESHED_${window.AppData.OneoffAccessTokenRefreshing}`
  );
  window.AppData.OneoffAccessTokenRefreshing = null;

  return window.AppData.OneoffAccessToken;
}
