import { XCircleIcon } from "@heroicons/react/20/solid";
import { IconFileDescription } from "@tabler/icons";
import * as React from "react";

interface Props {
  name: string;
  removeDocument: () => void;
}

export const UploadedDocument: React.FC<Props> = ({ name, removeDocument }) => (
  <div className="flex h-16 flex-grow items-center gap-4 rounded-lg border border-cream-300 bg-white p-4">
    <IconFileDescription className="flex-shrink-0" />

    <p className="w-full truncate text-sm font-semibold">{name}</p>

    <button
      type="button"
      onClick={removeDocument}
      className="ml-auto h-5 w-5 flex-shrink-0 text-red"
    >
      <span className="sr-only">Remove document</span>
      <XCircleIcon aria-hidden />
    </button>
  </div>
);
