export function shorten(string = "", limit: number) {
  if (string.length < limit) {
    return string;
  }

  return string.substring(0, limit) + " ...";
}

/**
 * Snake case to title case
 * E.g. "pain_management" -> "Pain Management"
 * @param string
 */
export function titleize(string: string) {
  return string.replace(/_/g, " ").replace(/\b\w/g, (s) => s.toUpperCase());
}
