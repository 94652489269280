import type { ImageRequest } from "../types/common";
import type { RawImageRequest } from "../types/requests";

export function prepImageRequest(
  request: RawImageRequest
): ImageRequest | null {
  if (!request) {
    return null;
  }

  return {
    id: request.id,
    approvedAt: request.approved_at,
    createdAt: request.created_at,
    recipientEmail: request.recipient_email,
  };
}

export function prepImageRequests(rawRequests?: RawImageRequest[] | null) {
  const requests = rawRequests
    ?.map(prepImageRequest)
    .filter((request): request is ImageRequest => Boolean(request));

  return requests;
}
