import axios from "axios";
import PubSub from "pubsub-js";

export async function getToken(): Promise<string> {
  let now = Math.round(Date.now() / 1000);

  if (window.AppData.AccessTokenExpiresAt > now + 60) {
    return window.AppData.AccessToken;
  }

  if (window.AppData.AccessTokenRefreshing) {
    console.log("Already refreshing access_token");

    return new Promise<void>((resolve) => {
      const subscriptionToken = PubSub.subscribe(
        `ACCESS_TOKEN_REFRESHED_${window.AppData.AccessTokenRefreshing}`,
        () => {
          resolve();
          setTimeout(() => {
            PubSub.unsubscribe(subscriptionToken);
          }, 60 * 1000);
        }
      );
    }).then(() => window.AppData.AccessToken);
  }

  window.AppData.AccessTokenRefreshing = `${Math.random()}`;
  console.log("Refreshing access_token");

  const response = await axios.request({
    url: "/api/v1/tokens",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-TOKEN": window.AppData.CsrfToken,
    },
  });
  const { access_token, access_token_expires_in } = response.data;

  now = Math.round(Date.now() / 1000);
  window.AppData.AccessToken = access_token;
  window.AppData.AccessTokenExpiresIn = access_token_expires_in;
  window.AppData.AccessTokenExpiresAt =
    now + window.AppData.AccessTokenExpiresIn;
  PubSub.publish(
    `ACCESS_TOKEN_REFRESHED_${window.AppData.AccessTokenRefreshing}`
  );
  window.AppData.AccessTokenRefreshing = null;

  return window.AppData.AccessToken;
}
