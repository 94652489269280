import { useEffect } from "react";
import { useLocation } from "react-router-dom";

/**
 * Component to scroll to the top on page change
 *
 * @returns null
 */
export function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    document.documentElement.scrollTo({ top: 0, left: 0 });
  }, [pathname]);

  return null;
}
