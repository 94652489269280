import { parseISO } from "date-fns";
import type { TimeSlot } from "../types/common";
import type { RawTimeSlot } from "../types/requests";

export function prepTimeSlot(slot?: RawTimeSlot): TimeSlot | null {
  if (!slot) {
    return null;
  }

  return {
    start: parseISO(slot.start_time),
    end: parseISO(slot.end_time),
    ids: slot.ids,
  };
}

export function prepTimeSlots(rawSlots?: RawTimeSlot[]): TimeSlot[] {
  const slots = rawSlots
    ?.map(prepTimeSlot)
    .filter((slot): slot is TimeSlot => Boolean(slot));

  return slots || [];
}
