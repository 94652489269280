import * as React from "react";
import * as PropTypes from "prop-types";
import { Popover, Transition } from "@headlessui/react";
import { usePopper, PopperProps } from "react-popper";

interface Props {
  menuClassName?: string;
  placement?: "bottom-start" | "bottom-end";
  menuButton: React.ReactNode;
  children:
    | React.ReactNode
    | ((bag: { close: () => void }) => React.ReactElement);
}

export default function AppMenu({
  menuClassName = "",
  menuButton,
  children,
  placement = "bottom-start",
}: Props) {
  const [referenceElement, setReferenceElement] =
    React.useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] =
    React.useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
  });

  return (
    <Popover className={`relative ${menuClassName}`}>
      <Popover.Button
        className="flex items-center rounded-lg hover:bg-cypress-900/70 focus:bg-cypress-900/70 focus:outline-none"
        ref={setReferenceElement}
      >
        {menuButton}
      </Popover.Button>

      <div
        className="absolute z-10 max-h-[411px] w-screen max-w-[380px] p-2 text-cypress-900 md:px-0"
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
      >
        <Transition
          enter="transition duration-200 ease-out origin-top-right"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100 h-full"
          leave="transition duration-100 ease-out origin-top-right"
          leaveFrom="transform scale-100 opacity-100 h-full"
          leaveTo="transform scale-95 opacity-0"
          as={React.Fragment}
        >
          <Popover.Panel>{children}</Popover.Panel>
        </Transition>
      </div>
    </Popover>
  );
}

AppMenu.propTypes = {
  menuClassName: PropTypes.string,
  menuButton: PropTypes.node.isRequired,
  children: PropTypes.func.isRequired,
};
