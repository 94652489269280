import { useQuery } from "react-query";
import { type SpecialistType, client } from "@services/scan";

interface RawResponse {
  data: SpecialistType[];
  errors: string[];
}

export function useSpecialistTypes() {
  const query = useQuery(["specialistTypes"], () => getSpecialistTypes(), {
    staleTime: 1000 * 60 * 5,
  });

  return query;
}

async function getSpecialistTypes(): Promise<SpecialistType[] | undefined> {
  const response = await client<RawResponse>({
    method: "get",
    url: "/api/v1/specialist_types",
  });

  if (!response) {
    return;
  }

  return response.data;
}
