import { Button, SelectInput, TextArea, TextInput } from "@scandotcom/react";
import type {
  SpecialistConsultation,
  SpecialistType,
  ValidationError,
} from "@services/scan";
import { PortalContext } from "@utils/PortalContext";
import { FormGroup, GroupSeparator } from "components/common/FormGroup";
import React, { BaseSyntheticEvent, useContext, useEffect } from "react";
import { useForm } from "react-hook-form";

export interface SpecialistConsultationFields {
  id?: string;
  specialist_type: string | null;
  referral_information: string;
  internal_reference: string;
}

interface Props {
  specialistConsultation: SpecialistConsultation;
  specialistTypes: SpecialistType[];
  onSave: (specialistConsultation: SpecialistConsultationFields) => void;
  onDraft?: (specialistConsultation: SpecialistConsultationFields) => void;
  validationErrors?: ValidationError[];
  submitLabel?: string;
}

function isDraftSubmit(e?: BaseSyntheticEvent<HTMLFormElement, SubmitEvent>) {
  return e?.nativeEvent?.submitter?.dataset["type"] === "draft";
}

function SpecialistConsultationDetailsForm({
  specialistConsultation,
  specialistTypes,
  onSave,
  onDraft,
  validationErrors = [],
  submitLabel = "Continue",
}: Props) {
  const { currentUser } = useContext(PortalContext);

  const {
    handleSubmit,
    watch,
    register,
    formState: { errors, isDirty, isSubmitted },
    control,
    setValue,
    setError,
    getValues,
    reset,
  } = useForm<SpecialistConsultationFields>({
    defaultValues: {
      id: specialistConsultation?.id,
      specialist_type: specialistConsultation.specialistType,
      referral_information:
        specialistConsultation.referralInformation ?? undefined,
      internal_reference: specialistConsultation.internalReference ?? undefined,
    },
  });

  useEffect(() => {
    // use validation errors returned from the API and show them in the form
    validationErrors.forEach((error, i) => {
      if (error.attribute)
        setError(
          error.attribute as keyof SpecialistConsultationFields,
          { type: "validate", message: error.fullMessage },
          { shouldFocus: i === 0 }
        );
    });
  }, [validationErrors]);

  const onSubmit = (e) => {
    e.preventDefault();

    if (onDraft && isDraftSubmit(e)) {
      return onDraft(getValues());
    }

    handleSubmit(onSave)(e);
  };

  return (
    <div>
      <form onSubmit={onSubmit} className="mx-auto mt-12 w-full max-w-[796px]">
        <FormGroup name="Specialist">
          <SelectInput
            label="Specialist type"
            placeholder="Select a specialist type"
            {...register("specialist_type", {
              required: "Select a specialist type.",
            })}
            options={specialistTypes.map(({ label, code }) => ({
              label,
              value: code,
            }))}
            errorMessage={errors.specialist_type?.message}
          />
        </FormGroup>
        <GroupSeparator />
        <FormGroup name="Referral information">
          <TextArea
            label="Reason"
            placeholder="Please provide as much clinical information as possible that is relevant to the specialist consultation."
            rows={5}
            {...register("referral_information", {
              required: "Enter some referral information",
            })}
            errorMessage={errors.referral_information?.message}
          />

          <TextInput
            label="Internal reference"
            hintText="If you would like to add your own internal reference code to this referral, please add it here."
            {...register("internal_reference")}
            errorMessage={errors.internal_reference?.message}
            isOptional
          />
        </FormGroup>

        <GroupSeparator />

        <div className="mt-16 flex flex-wrap justify-end gap-4">
          {onDraft && (
            <Button
              data-type="draft"
              data-test="submit-draft"
              type="submit"
              kind="secondary"
              className="w-full sm:w-fit md:order-2"
            >
              Save draft
            </Button>
          )}
          <Button
            data-test="submit"
            type="submit"
            className="w-full sm:w-fit md:order-2"
          >
            {submitLabel}
          </Button>
        </div>

        {!!validationErrors.length && (
          <>
            <h4 className="font-bold text-red">
              Some errors occurred in your submission:
            </h4>
            <ul className="text-sm text-red">
              {validationErrors.map((error) => (
                <li key={error.attribute}>{error.fullMessage}</li>
              ))}
            </ul>
          </>
        )}
      </form>
    </div>
  );
}

export default SpecialistConsultationDetailsForm;
