import { TimeSlot } from "@services/scan/types/common";
import { formatInTimeZone, zonedTimeToUtc } from "date-fns-tz";
import * as A from "fp-ts/Array";
import { Ord as DateOrd } from "fp-ts/Date";
import { pipe } from "fp-ts/lib/function";
import * as NEA from "fp-ts/NonEmptyArray";
import * as Ord from "fp-ts/Ord";
import * as R from "fp-ts/Record";

export const TIME_ZONE = "Europe/London";

/**
 * An Ord instance for sorting a list for slots entries by the slot
 * day
 */
const bySlotDay = Ord.contramap(([day]: [Date, TimeSlot[]]) => day)(DateOrd);

/**
 * An Ord instance for comparing slots by their start time
 */
const bySlotStartTime = Ord.contramap(({ start }: { start: Date }) => start)(
  DateOrd
);

const chunkSlots =
  (chunker: (slot: TimeSlot) => string) => (slots: TimeSlot[]) => {
    return pipe(
      slots,
      A.sort(bySlotStartTime),
      NEA.groupBy(chunker),
      R.toEntries
    );
  };

/**
 * Groups a list of slots by day, sorting it as it does so.
 * The day used for grouping is dervied using the appropriate timezone.
 *
 * @param slots
 * @returns
 */
export const groupSlotsByDay = (slots: NEA.NonEmptyArray<TimeSlot>) => {
  return pipe(
    slots,
    chunkSlots((slot) => formatInTimeZone(slot.start, TIME_ZONE, "yyyy-MM-dd")),
    A.map(([day, slots]): [Date, NEA.NonEmptyArray<TimeSlot>] => [
      zonedTimeToUtc(`${day}T00:00:00`, TIME_ZONE),
      slots,
    ]),
    A.sort(bySlotDay)
  );
};

export const groupSlotsByWeek = (slots: NEA.NonEmptyArray<TimeSlot>) => {
  return pipe(
    slots,
    chunkSlots((slot) => formatInTimeZone(slot.start, TIME_ZONE, "yyyy-ww"))
  );
};
