import { useMutation, useQueryClient } from "react-query";
import { submitSpecialistConsultation } from "@services/scan";

export default function useSubmitSpecialistConsultation() {
  const queryClient = useQueryClient();

  const mutation = useMutation(submitSpecialistConsultation, {
    onSuccess: (data) => {
      if (!data.success || !data.specialistConsultation) {
        return;
      }

      queryClient.setQueryData(
        ["specialistConsultation", data.specialistConsultation.id],
        data.specialistConsultation
      );
    },
  });

  return mutation;
}
