import { LoadingSpinner } from "@scandotcom/react";
import { isReferralEditable } from "@utils/referralUtils";
import Routes from "@utils/Routes";
import ScanDetailsForm, {
  ScanDetailsFields,
} from "components/portal/ScanDetailsForm";
import { useAdditionalDocuments } from "hooks/useAdditionalDocuments";
import { useModalities } from "hooks/useModalities";
import { useMutateReferral } from "hooks/useMutateReferral";
import { useReferral } from "hooks/useReferral";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

function PageEditScanDetails() {
  const navigate = useNavigate();
  const { referralId } = useParams();
  const modalities = useModalities();
  const mutation = useMutateReferral();
  const { data: referral, isLoading } = useReferral({
    referralId,
    onSuccess: (referral) => {
      if (!isReferralEditable(referral)) {
        navigate(Routes.referral(referral.id));
        return;
      }
    },
  });

  const documents = useAdditionalDocuments(referralId);

  if (isLoading || !referral) {
    return <LoadingSpinner className="my-8" />;
  }

  const onSave = ({
    id,
    modality,
    body_part_ids,
    internal_reference,
    referral_information,
  }: ScanDetailsFields) => {
    mutation.mutate(
      {
        referralId: id,
        body_part_ids,
        modality: modality || null,
        referral_information,
        internal_reference,
      },
      {
        onSuccess: (data) => {
          if (!data.success || !data.referral) return;

          navigate(Routes.referral(data.referral.id), {
            state: {
              successMessage: `Referral ${data.referral?.reference} has been updated`,
            },
          });
        },
      }
    );
  };

  return (
    <>
      <div className="bg-white">
        <div className="flex flex-col items-center justify-between py-4 px-4 sm:px-4 md:flex-row md:px-8">
          <div className="w-full md:w-auto">
            <div className="text-lg font-semibold">
              Edit referral {referral.reference}
            </div>
            <span className="text-xs text-gray-600">
              Enter scan details here. All referrals are checked by our team of
              clinicians to ensure the correct modality and body areas have been
              chosen for the patient&apos;s needs.
            </span>
          </div>
        </div>
        <div className="relative inset-x-auto h-1 w-full border-b" />
      </div>
      <div className="px-4 pt-8 sm:px-4 md:px-8">
        <ScanDetailsForm
          modalities={modalities.data ?? []}
          validationErrors={mutation.data?.errors}
          additionalDocuments={documents.data}
          referral={referral}
          onSave={onSave}
          submitLabel="Save"
        />
      </div>
    </>
  );
}

export default PageEditScanDetails;
