import { getPatient } from "@services/scan";
import { useQuery } from "react-query";

export function usePatient(id?: string | null) {
  const query = useQuery(["patient", id], () => getPatient(id), {
    enabled: Boolean(id),
    staleTime: 1000 * 60 * 5,
  });

  return query;
}
