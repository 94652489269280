import React from "react";

function PostStep() {
  return (
    <div id="post">
      <p className="mt-6 text-xl font-medium">
        What happens after you&#39;ve referred your patient?
      </p>
      <div className="py-4 text-gray-500">
        <p className="mb-4">
          Once you&#39;ve placed a referral via our portal, there are three
          further steps that take place:
        </p>
        <div className="flex flex-col md:flex-row">
          <div className="mr-4 w-full md:w-1/3">
            <p className="mt-4 mb-2 text-3xl text-primary-500 md:mt-0 md:mb-4">
              1
            </p>
            <p className="text-sm text-gray-500">
              Your patient will be notified of the referral. They&#39;ll
              complete their booking, by selecting a conveniently located
              scanning centre from our 150+ partner sites, and making payment.
            </p>
          </div>
          <div className="mr-4 w-full md:w-1/3">
            <p className="mt-8 mb-2 text-3xl text-primary-500 md:mt-0 md:mb-4">
              2
            </p>
            <p className="text-sm text-gray-500">
              Our expert clinical team will review each individual referral, to
              make sure your patient receives the most suitable scan for their
              needs.
            </p>
          </div>
          <div className="w-full md:w-1/3">
            <p className="mt-8 mb-2 text-3xl text-primary-500 md:mt-0 md:mb-4">
              3
            </p>
            <p className="text-sm text-gray-500">
              Your patient will have their scan at their selected centre, and
              their imaging report will be uploaded to the portal, where it can
              be viewed and downloaded.
            </p>
          </div>
        </div>
      </div>
      <p className="pt-6 text-xl font-medium">Our Clinical Experts</p>
      <p className="py-4 text-gray-500">
        Scan.com has a robust clinical due diligence procedure. Our team of
        clinicians, led by consultant radiologist
        <span className="font-medium">
          {" "}
          Dr Khalid Latief (BMSc, MBChB, FRCP, FRCR)
        </span>
        , are here to review every referral. They&#39;ll ensure the selected
        scan will provide the required outcomes for the patient, and deliver the
        highest levels of patient care throughout. They can also assist in
        interpreting radiologist&#39;s reports, and can provide recommendations
        for onward care as required.
      </p>
    </div>
  );
}

export default PostStep;
