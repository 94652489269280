import { useCallback, useState } from "react";
import { getPatients, GetPatientsProps } from "@services/scan";
import { useQuery } from "react-query";
import { debounce } from "@utils/debounce";

interface UsePatientListProps {
  limit?: number;
  order?: GetPatientsProps["order"];
}

export function usePatientList({
  limit = 10,
  order = "created_at",
}: UsePatientListProps) {
  const [pageNumber, setPageNumber] = useState(1);
  const [query, setQuery] = useState("");
  const [orderBy, setOrderBy] = useState(order);
  const [direction, setDirection] = useState<"ASC" | "DESC">("DESC");

  const offset = Math.max(pageNumber * limit - limit, 0);

  const { isLoading, data, isFetching } = useQuery(
    ["patients", { offset, orderBy, direction, query }],
    () => getPatients({ limit, offset, direction, query, order: orderBy }),
    { keepPreviousData: true }
  );

  function setSearch(term: string) {
    setQuery(term);
    setPageNumber(1);
  }

  function setOrder(by: UsePatientListProps["order"] = "created_at") {
    const newDirection = order === by && direction === "ASC" ? "DESC" : "ASC";

    setOrderBy(by);
    setDirection(newDirection);
    setPageNumber(1);
  }

  function setPage(page: number) {
    setPageNumber(page);
  }

  const patients = data?.patients || [];
  const pageInfo = data?.pageInfo;
  const hasNoInitialResults =
    !isLoading &&
    !isFetching &&
    pageInfo?.query === "" &&
    pageInfo?.total === 0;

  const debouncedSetSearch = useCallback(debounce(setSearch, 300), []);

  return {
    isLoading,
    isFetching,
    patients,
    pageInfo,
    hasNoInitialResults,
    page: pageNumber,
    setOrder,
    setSearch: debouncedSetSearch,
    setPage,
  };
}
