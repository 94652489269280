import notifyError from "@services/notifyError";
import { client } from "../../client";
import type {
  RawSpecialistConsultation,
  RawValidationError,
  SpecialistConsultationMutationFields,
} from "../../types/requests";
import { prepValidationErrors } from "../../utils/prepValidationErrors";
import { prepSpecialistConsultation } from "../../utils/prepSpecialistConsultation";

type CreateSpecialistConsultationProps = Partial<
  Omit<SpecialistConsultationMutationFields, "id">
>;

interface RawResponse {
  object: RawSpecialistConsultation;
  success: boolean;
  validation_errors: RawValidationError[];
}

export async function createSpecialistConsultation(
  fields: CreateSpecialistConsultationProps
) {
  const response = await client<RawResponse>({
    method: "post",
    url: "/api/v1/specialist_consultations",
    data: {
      draft: true,
      origin: "portal",
      ...fields,
    },
  });

  const success = Boolean(response?.success);
  const errors = prepValidationErrors(response?.validation_errors);
  const specialistConsultation = prepSpecialistConsultation(response?.object);

  if (!success) {
    notifyError("Error creating specialist consultation", { errors });
  }

  return { specialistConsultation, success, errors };
}
