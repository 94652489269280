import { MapPinIcon } from "@heroicons/react/24/solid";
import { ImagingProvider } from "@services/scan/types/common";
import React from "react";
import { Marker } from "react-map-gl";
import { round } from "utils/numbers";

interface MapMarkerProps {
  provider: ImagingProvider;
  onClick: () => void;
}

export const MapMarker = ({ provider, onClick }: MapMarkerProps) => {
  return (
    <Marker
      anchor="bottom"
      key={provider.id}
      latitude={provider.latitude}
      longitude={provider.longitude}
      onClick={(e) => {
        e.originalEvent.stopPropagation();
        onClick();
      }}
    >
      {provider.price ? (
        <div className="rounded-full bg-white py-1.5 px-2 font-maison-extended text-xs font-bold text-cypress-800 shadow-md">
          £{round(provider.price / 100, 0)}
        </div>
      ) : (
        <MapPinIcon className="h-10 w-10 text-pink-300 transition-transform hover:scale-125" />
      )}
    </Marker>
  );
};
