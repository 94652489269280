import React from "react";
import { arrayOf, string } from "prop-types";
import classNames from "classnames";
import { XCircleIcon } from "@heroicons/react/24/solid";

interface ValidationErrorsProps {
  className?: string;
  errors?: string[];
}

const ValidationErrors = ({
  errors = [],
  className,
}: ValidationErrorsProps) => {
  const wrapperStyles = classNames(
    "flex gap-x-3 rounded-md bg-danger-50 p-4",
    className
  );
  return (
    <div className={wrapperStyles}>
      <XCircleIcon className="h-5 w-5 shrink-0 text-danger-400" aria-hidden />

      <div>
        <h3 className="text-sm font-medium text-danger-800">
          There were errors in your submission.
        </h3>
        <div className="mt-2 text-sm text-danger-700">
          <ul className="list-disc space-y-1 pl-5">
            {errors.map((error) => (
              <li key={error}>{error}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

ValidationErrors.propTypes = {
  errors: arrayOf(string),
  className: string,
};

export default ValidationErrors;
