import { IconHourglass, IconCheck } from "@tabler/icons";
import classNames from "classnames";
import React from "react";

export interface TimelineItemProps {
  label: string;
  description: string;
  completedAt?: string | null;
  direction?: keyof typeof styles.direction;
}

export interface TimelineProps {
  items: TimelineItemProps[];
  direction?: TimelineItemProps["direction"];
  className?: string;
}

const styles = {
  direction: {
    horizontal: "border-t pt-9 pr-14 max-w-xs last:border-none last:pr-0",
    vertical: "border-l pl-10 pb-6 last:border-none last:pb-0 ml-4",
  },
  colors: "border-cypress-900",
} as const;

export const TimelineItem = ({
  label,
  description,
  completedAt,
  direction = "vertical",
}: TimelineItemProps) => {
  const Marker = completedAt ? IconCheck : IconHourglass;
  const markerStyles = classNames(
    "px-1.5 py-1 border border-cypress-900 w-fit rounded-full absolute",
    completedAt ? "bg-pink-300" : "bg-cream-50",
    direction === "vertical" ? "-left-[1.1rem] top-0" : "-top-4"
  );

  const wrapperStyles = classNames(
    styles.direction[direction],
    styles.colors,
    "font-maison font-normal text-sm relative"
  );

  const titleStyles = classNames(
    "text-sm font-bold tracking-wider font-maison-extended uppercase",
    "text-cypress-800 pt-1.5"
  );

  return (
    <div className={wrapperStyles}>
      <div className={markerStyles}>
        <Marker strokeWidth={1.5} className="w-5" aria-hidden />
      </div>
      <h3 className={titleStyles}>{label}</h3>
      <p className="mt-2 whitespace-pre-line text-cypress-900">{description}</p>
      <div className="mt-1.5 text-gray-500">{completedAt}</div>
    </div>
  );
};

export const Timeline = ({ items, className, direction }: TimelineProps) => {
  const timelineStyles = classNames(
    direction === "horizontal" && "flex flex-row overflow-scroll",
    className
  );
  return (
    <div className={timelineStyles} data-test="referral_timeline">
      {items.map((item, index) => (
        <TimelineItem
          {...item}
          direction={direction}
          key={item.label + index}
        />
      ))}
    </div>
  );
};
