import React from "react";
import PropTypes from "prop-types";
import { Routes, Route } from "react-router-dom";

import PageConfirmUser from "pages/confirm_user/PageConfirmUser";

export default function ConfirmUserApp({ referralId, referralReference }) {
  return (
    <Routes>
      <Route path="/">
        <Route
          index
          element={
            <PageConfirmUser
              referralId={referralId}
              referralReference={referralReference}
            />
          }
        />
      </Route>
    </Routes>
  );
}

ConfirmUserApp.propTypes = {
  referralId: PropTypes.string.isRequired,
  referralReference: PropTypes.string.isRequired,
};
