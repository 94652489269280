import { trackEvent } from "@services/analytics";
import PubSub from "pubsub-js";
import { client } from "../../client";
import type { RawReferral, RawValidationError } from "../../types/requests";
import { prepReferral } from "../../utils/prepReferrals";
import { prepValidationErrors } from "../../utils/prepValidationErrors";

interface RawResponse {
  success: boolean;
  object: RawReferral;
  validation_errors: RawValidationError[];
}

export async function submitReferral(referralId: string) {
  const response = await client<RawResponse>({
    method: "patch",
    url: `/api/v1/referrals/${referralId}/submit`,
  });

  const success = Boolean(response?.success);
  const referral = prepReferral(response?.object);
  const errors = prepValidationErrors(response?.validation_errors);

  if (success) {
    trackEvent("submit_referral");
    PubSub.publish("REFERRAL_SUBMITTED", referralId);
  }

  return { referral, success, errors };
}
