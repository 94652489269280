import { LoadingSpinner } from "@scandotcom/react";
import { currentUser } from "@utils/PortalContext";
import { getReferralBreadcrumbs } from "@utils/referralUtils";
import Routes from "@utils/Routes";
import { PageInfo } from "components/common/PageInfo";
import PatientForm, { PatientFormFields } from "components/portal/PatientForm";
import { useMutateReferral } from "hooks/useMutateReferral";
import { useReferral } from "hooks/useReferral";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

function PageEnterPatientInformation() {
  const navigate = useNavigate();
  const { referralId } = useParams();
  const user = currentUser();
  const { data: referral } = useReferral({ referralId });
  const mutation = useMutateReferral();

  async function onSubmittedPatient(
    patient: Partial<PatientFormFields>,
    draft: boolean
  ) {
    if (!referral) {
      return;
    }

    const { address, ...fields } = patient;

    const defaultStep = draft ? 0 : 1;

    mutation.mutate(
      {
        referralId: referral.id,
        completed_step: referral.completedStep || defaultStep,
        patient_information: {
          ...fields,
          address_line_1: address?.line_1,
          address_line_2: address?.line_2,
          address_city: address?.city,
          address_postcode: address?.postcode,
        },
      },
      {
        onSuccess: (data) => {
          if (!data.success) return;

          if (draft)
            return navigate(Routes.home, {
              state: { successMessage: "Referral draft has been saved" },
            });

          navigate(Routes.scanDetails(referral.id));
        },
      }
    );
  }

  if (!referral) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <PageInfo
        name="Patient Details"
        breadcrumbs={getReferralBreadcrumbs(referral)}
        description="Provide the details of the patient you are referring for a scan."
      />

      <div className="container">
        <PatientForm
          hasGPDetails={user?.nonPatientLedFlow}
          onSubmittedPatient={(data) => onSubmittedPatient(data, false)}
          onDraftPatient={(data) => onSubmittedPatient(data, true)}
          submitLabel="Continue"
          validationErrors={mutation.data?.errors}
          patient={referral.patient}
        />
      </div>
    </>
  );
}

export default PageEnterPatientInformation;
