import React from "react";
import { PopoverContentProps } from "@reactour/tour";
import { Button, H4 } from "@scandotcom/react";

interface StepProps {
  header: string;
  body: string;
}

export const createStep =
  ({ currentStep, steps, setCurrentStep }: PopoverContentProps) =>
  ({ header, body }: StepProps) => {
    // exclude first and last steps
    const stepsCount = steps.length - 2;
    const lastStep = stepsCount;

    const nextStep = (step?: number) => {
      if (currentStep !== 5 && step !== 6)
        return setCurrentStep(step ?? ((s) => s + 1));

      // Open mobile menu
      document
        .querySelector<HTMLButtonElement>(
          '.humberger-button:not([data-headlessui-state="open"])'
        )
        ?.click();
      // Delay the animation to avoid flickering
      setTimeout(() => setCurrentStep(step ?? ((s) => s + 1)), 100);
    };

    return (
      <>
        <div className="mb-5">
          <H4 className="text-gray-900" size="xl">
            {header}
          </H4>
          <p className="mt-2 text-base text-cypress-900">{body}</p>
        </div>

        <div className="flex items-center justify-between">
          <Button className="w-fit" onClick={() => nextStep()}>
            {currentStep === lastStep ? "Finish" : "Next"}
          </Button>

          <div className="flex gap-2">
            {Array.from({ length: stepsCount }).map((_, i) => (
              <button
                key={i}
                className="h-2 w-2 rounded-full bg-cream-200"
                style={{
                  backgroundColor:
                    currentStep === i + 1 ? "#f472b6" : undefined,
                }}
                onClick={() => nextStep(i + 1)}
              />
            ))}
          </div>
        </div>
      </>
    );
  };
