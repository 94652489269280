import React from "react";
import { Outlet } from "react-router-dom";
import Button from "ui/Button";
import openLiveChat from "utils/openLiveChat";

function ImagingGuideLayout() {
  return (
    <div className="relative mx-auto flex min-h-screen w-full flex-col px-4 py-6 text-gray-900 antialiased md:container md:px-6">
      <header className="mb-6 flex h-16 flex-col items-center justify-between space-x-2.5 md:flex-row">
        <div className="mb-2 flex md:mb-0">
          <div className="flex flex-shrink-0 items-center">
            <img
              className="block h-8 w-auto lg:hidden"
              src={AppData.ScanLogoBlackUrl}
              alt="Scan.com"
            />
            <img
              className="hidden h-8 w-auto lg:block"
              src={AppData.ScanLogoBlackUrl}
              alt="Scan.com"
            />
          </div>
        </div>
      </header>
      <Outlet />
      <footer className="mt-auto flex flex-col items-center justify-between space-y-8 pt-32 pb-16 sm:mr-0 sm:flex-row sm:space-y-0 sm:pr-0">
        <p className="text-xs text-gray-500">
          &copy;
          {AppData.Year} National MRI Scan Ltd, trading as Scan.com
        </p>
        <Button
          color="white"
          className="text-xs font-normal"
          onClick={() => openLiveChat()}
        >
          Need Help?
        </Button>
      </footer>
    </div>
  );
}

export default ImagingGuideLayout;
