import { PopoverContentProps } from "@reactour/tour";
import { Button, H3 } from "@scandotcom/react";
import { IconCheck, IconX } from "@tabler/icons";
import React from "react";
import { useNavigate } from "react-router-dom";

function LastStep({ setIsOpen }: PopoverContentProps) {
  const navigate = useNavigate();

  return (
    <div>
      <div
        className="absolute right-5 top-4 cursor-pointer rounded-lg border border-cream-200 bg-white p-2 hover:bg-gray-50"
        onClick={() => setIsOpen(false)}
      >
        <IconX className="h-4 w-4" />
      </div>
      <div className="space-y-2">
        <div className="mb-4 inline-flex items-center justify-center rounded-full bg-pink-50 p-2">
          <IconCheck className="h-10 w-10 text-pink-500" strokeWidth={1.5} />
        </div>
        <H3 size="xl" className="text-gray-900">
          Walkthrough complete
        </H3>
        <p className="text-base text-gray-900">
          We hope you found this walkthrough helpful. Now it's time to create
          your first referral!
        </p>
      </div>
      <div className="mt-5 flex flex-col gap-4 sm:flex-row">
        <Button onClick={() => navigate("/referrals/new")}>
          Create referral
        </Button>
        <Button kind="secondary" onClick={() => navigate("/guide")}>
          Getting started guide
        </Button>
      </div>
    </div>
  );
}

export default LastStep;
