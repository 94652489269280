import { prepModalities } from "@services/scan/utils/prepModalities";
import { client } from "../../client";
import { Modality } from "../../types/common";
import { RawModality } from "../../types/requests";

interface RawResponse {
  data: RawModality[];
}

export async function getModalities(): Promise<Modality[]> {
  const response = await client<RawResponse>({
    method: "get",
    url: "/api/v2/modalities",
  });

  const modalities = prepModalities(response?.data);

  return modalities;
}
