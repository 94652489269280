import React from "react";
import { PageInfo } from "components/common/PageInfo";
import { getSpecialistConsultationBreadcrumbs } from "@utils/referralUtils";
import { useNavigate, useParams } from "react-router-dom";
import { useSpecialistConsultation } from "hooks/useSpecialistConsultation";
import { LoadingSpinner } from "@scandotcom/react";
import SpecialistConsultationDetailsForm, {
  SpecialistConsultationFields,
} from "components/portal/SpecialistConsultationDetailsForm";
import { useMutateSpecialistConsultation } from "hooks/useMutateSpecialistConsultation";
import Routes from "@utils/Routes";
import { useSpecialistTypes } from "hooks/useSpecialistTypes";

const PageEnterSpecialistConsultationDetails = () => {
  const { specialistConsultationId } = useParams() as {
    specialistConsultationId: string;
  };
  const { data: specialistConsultation, isLoading } = useSpecialistConsultation(
    specialistConsultationId
  );
  const specialistConsultationMutation = useMutateSpecialistConsultation(
    specialistConsultationId
  );
  const { data: specialistTypes } = useSpecialistTypes();
  const navigate = useNavigate();

  const save = async (formFields: SpecialistConsultationFields) => {
    const result = await specialistConsultationMutation.mutateAsync({
      id: specialistConsultationId,
      specialist_type: formFields.specialist_type || undefined,
      referral_information: formFields.referral_information,
      internal_reference: formFields.internal_reference,
    });
  };

  const submitAndContinue = async (
    formFields: SpecialistConsultationFields
  ) => {
    await save(formFields);
    navigate(Routes.specialistConsultationSpecialist(specialistConsultationId));
  };

  const saveDraft = async (formFields: SpecialistConsultationFields) => {
    await save(formFields);
    navigate(Routes.referrals, {
      state: {
        successMessage: `Specialist consultation referral ${specialistConsultation?.reference} saved as draft.`,
      },
    });
  };

  if (isLoading || !specialistConsultation) {
    return <LoadingSpinner className="my-8" />;
  }

  return (
    <>
      <PageInfo
        name="Specialist consultation details"
        breadcrumbs={getSpecialistConsultationBreadcrumbs(
          specialistConsultation
        )}
        description="Enter specialist consultation details here."
      />

      <div className="container">
        <SpecialistConsultationDetailsForm
          specialistConsultation={specialistConsultation}
          specialistTypes={specialistTypes || []}
          onDraft={saveDraft}
          onSave={submitAndContinue}
        />
      </div>
    </>
  );
};

export default PageEnterSpecialistConsultationDetails;
