import notifyError from "@services/notifyError";
import { client } from "../../client";
import type {
  RawSpecialistConsultation,
  RawValidationError,
  SpecialistConsultationMutationFields,
} from "../../types/requests";
import { prepValidationErrors } from "../../utils/prepValidationErrors";
import { prepSpecialistConsultation } from "../../utils/prepSpecialistConsultation";

type UpdateSpecialistConsultationProps =
  Partial<SpecialistConsultationMutationFields>;

interface RawResponse {
  object: RawSpecialistConsultation;
  success: boolean;
  validation_errors: RawValidationError[];
}
export async function updateSpecialistConsultation({
  id,
  ...fields
}: Partial<UpdateSpecialistConsultationProps>) {
  const response = await client<RawResponse>({
    method: "patch",
    url: `/api/v1/specialist_consultations/${id}`,
    data: fields,
  });

  const success = Boolean(response?.success);
  const errors = prepValidationErrors(response?.validation_errors);
  const specialistConsultation = prepSpecialistConsultation(response?.object);

  if (!success) {
    notifyError("Error updating specialist consultation", { id: id, errors });
  }

  return { specialistConsultation, success, errors };
}
