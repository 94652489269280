import {
  Button,
  Checkbox,
  ErrorMessage,
  RadioToggle,
  TextAnchor,
  TextArea,
} from "@scandotcom/react";
import { CancelReferralModal } from "components/portal/Modals/CancelReferralModal";
import * as React from "react";

import { Referral, SafetyQuestion } from "@services/scan/types/common";
import { IconCheck, IconX } from "@tabler/icons";
import { Controller, useForm } from "react-hook-form";
import { isNil, isNotNil } from "utils/isNil";
import { usePreventPageLeave } from "utils/usePreventPageLeave";
import { NoToAll } from "./NoToAll";

interface Props {
  initialValues: Partial<QuestionsFields>;
  onSubmit: (data: QuestionsFields) => void;
  onDraft: (data: QuestionsFields) => void;
  questions?: SafetyQuestion[];
  referral: Referral;
}

export interface QuestionsFields {
  questions: Record<string, string | boolean | null>;
  gpDetails: string;
  terms: boolean;
}

const isDraftSubmit = (
  e?: React.BaseSyntheticEvent<HTMLFormElement, SubmitEvent>
) => {
  return (
    e &&
    e.nativeEvent.submitter &&
    e.nativeEvent.submitter.dataset["type"] === "draft"
  );
};

export const SafetyQuestionsForm: React.FC<Props> = ({
  initialValues,
  questions = [],
  referral,
  ...props
}) => {
  const {
    control,
    watch,
    getValues,
    register,
    formState: { errors, isDirty, isSubmitted },
    handleSubmit,
    setValue,
  } = useForm<QuestionsFields>({
    defaultValues: initialValues,
  });

  const unblockNavigation = usePreventPageLeave(
    "Currently you'd lose anything entered",
    isDirty && !isSubmitted
  );

  const [openedCancelModal, setOpenedCancelModal] = React.useState(false);
  const [allChecked, setAllChecked] = React.useState(false);

  const questionsField = watch("questions") || {};

  const onSubmit = (e) => {
    e.preventDefault();

    if (isDraftSubmit(e)) {
      unblockNavigation();
      return props.onDraft(getValues());
    }

    handleSubmit(props.onSubmit)(e);
  };

  function onCheckAll(checked: boolean) {
    setAllChecked((prev) => !prev);
    const newAnswers = Object.entries(questionsField).map(([key, value]) => {
      if (key.includes("details")) return [key, value];

      if (checked) return [key, false];

      return [key, null];
    });

    setValue("questions", Object.fromEntries(newAnswers));
  }

  return (
    <>
      <CancelReferralModal
        isOpen={openedCancelModal}
        onClose={() => setOpenedCancelModal(false)}
        id={referral.id}
        reference={referral.reference}
      />
      <form onSubmit={onSubmit} className="relative max-w-screen-sm">
        <NoToAll checked={allChecked} onChange={onCheckAll} />
        <div className="mt-6 space-y-8">
          {questions.map((question) => (
            <div key={question.attribute} className="space-y-4">
              <Controller
                name={`questions.${question.attribute}`}
                control={control}
                rules={{
                  validate: {
                    isChecked: (value) =>
                      isNotNil(value) ? undefined : "Select an option",
                  },
                }}
                render={({ field, fieldState }) => {
                  const value = isNil(field.value)
                    ? null
                    : field.value
                    ? "true"
                    : "false";

                  return (
                    <div>
                      <RadioToggle
                        label={question.clinicianLabel}
                        value={value}
                        onChange={(value) => {
                          field.onChange(value === "true");

                          if (value === "true") {
                            setAllChecked(false);
                          }
                        }}
                        options={[
                          {
                            label: question.trueOption,
                            value: "true",
                            icon: IconCheck,
                          },
                          {
                            label: question.falseOption,
                            value: "false",
                            icon: IconX,
                          },
                        ]}
                      />
                      {fieldState.error ? (
                        <ErrorMessage className="mt-1">
                          {fieldState.error.message}
                        </ErrorMessage>
                      ) : null}
                    </div>
                  );
                }}
              />

              {questionsField[question.attribute] &&
              question.requiresDetails &&
              question.labelDetails &&
              question.attributeDetails ? (
                <TextArea
                  label={question.labelDetails}
                  rows={5}
                  errorMessage={
                    errors.questions?.[`${question.attributeDetails}`]?.message
                  }
                  {...register(`questions.${question.attributeDetails}`, {
                    required: "Enter some details",
                  })}
                />
              ) : null}
            </div>
          ))}

          <TextArea
            data-test="gp_details"
            rows={5}
            id="gp_details"
            placeholder="Surgery Name, City"
            label={"GP details"}
            hintText="We'll only use this in case of an emergency."
            errorMessage={errors.gpDetails?.message}
            {...register("gpDetails", {
              required: "Enter the patient's GP details",
            })}
          />

          <Checkbox
            value="1"
            {...register("terms", { required: "Confirm the terms" })}
            errorMessage={errors.terms?.message}
            data-test="remote_imaging_terms"
            label={
              <>
                I have provided the Patient with a copy of the{" "}
                <TextAnchor
                  href={`${window.AppData.ScanApiUrl}/docs/remote-image-and-report-viewing-service-terms.pdf`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Remote Image and Report Viewing Service Terms
                </TextAnchor>
                , and confirm that the Patient has reviewed and agreed to such
                Terms, and authorised all the actions described therein.
              </>
            }
          />
        </div>

        <div className="mt-6 grid grid-cols-1 justify-end gap-6 md:flex">
          <Button
            data-type="draft"
            data-test="submit-draft"
            type="submit"
            className="bg-white md:order-2"
          >
            Save draft
          </Button>
          <Button
            kind="primary"
            data-test="submit"
            type="submit"
            className="md:order-2"
          >
            Continue
          </Button>
        </div>
      </form>
    </>
  );
};
