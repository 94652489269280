import { Alert, LoadingSpinner } from "@scandotcom/react";
import Routes from "@utils/Routes";
import { useAdditionalDocuments } from "hooks/useAdditionalDocuments";
import { useReferral } from "hooks/useReferral";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getFullAddress, isReferralDone } from "utils/referralUtils";
import { currentUser } from "utils/PortalContext";
import { ContactUs } from "./ContactUs";
import Documents from "./Documents";
import { ImageRequests } from "./ImageRequests";
import { PatientInformation } from "./PatientInformation";
import { ReferralHeader } from "./ReferralHeader";
import { ReferralHistory } from "./ReferralHistory";
import { ScanDetails } from "./ScanDetails";
import { AdminNotes } from "./AdminNotes";

interface PageShowReferralProps {
  /**
   * Used by the ViewReferral app to hide links.
   */
  isExternal?: boolean;
}
const PageShowReferral = ({ isExternal }: PageShowReferralProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { referralId } = useParams();
  const [successMessage, setSuccessMessage] = useState("");

  const { data: referral, isLoading } = useReferral({
    referralId,
    onSuccess: (referral) => {
      if (!isReferralDone(referral)) {
        navigate(Routes.scanDetails(referralId || ""));
      }
    },
  });

  const additionalDocuments = useAdditionalDocuments(referralId);

  useEffect(() => {
    const state = location.state as Record<string, any>;
    setSuccessMessage(state?.successMessage ?? "");
  }, [location.state]);

  if (isLoading || additionalDocuments.isLoading || !referral) {
    return <LoadingSpinner className="my-8" />;
  }

  const patient = referral?.patient;

  return (
    <div>
      <ReferralHeader referral={referral} isExternal={isExternal} />

      {successMessage && (
        <div className="container mb-6">
          <Alert
            title={successMessage}
            kind="success"
            onClose={() => setSuccessMessage("")}
          />
        </div>
      )}

      <div className="container grid-cols-12 gap-x-6 lg:grid">
        <div className="col-span-4 col-start-9 row-span-full mb-4 h-fit w-full lg:sticky lg:top-6 lg:mb-0">
          <ReferralHistory referral={referral} />

          <ContactUs className="mt-4" />

          <AdminNotes referral={referral} />
        </div>

        <div className="col-span-8 row-span-full h-fit space-y-4">
          <ScanDetails referral={referral} />

          <PatientInformation
            referralId={referral.id}
            isExternal={isExternal}
            id={patient.id}
            name={patient.fullName}
            email={patient.email}
            phone={patient.phone}
            alternate_phone={patient.alternatePhone}
            address={getFullAddress(patient.address)}
            gender={patient.gender}
            DOB={patient.dateOfBirth}
          />

          <Documents
            referral={referral}
            additionalDocuments={additionalDocuments.data}
          />

          <ImageRequests referralId={referral.id} />
        </div>
      </div>
    </div>
  );
};

export default PageShowReferral;
