import { ContactUs } from "components/common/ShowReferral/ContactUs";
import { PatientInformation } from "components/common/ShowReferral/PatientInformation";
import { ReferralHeader } from "components/common/ShowReferral/ReferralHeader";
import { ReferralHistory } from "components/common/ShowReferral/ReferralHistory";
import { ScanDetails } from "components/common/ShowReferral/ScanDetails";
import SuccessMessage from "components/common/SuccessMessage";
import React, { useEffect } from "react";

import { getFullAddress, getFullName } from "utils/referralUtils";

import { StepType, useTour } from "@reactour/tour";
import Documents from "components/common/ShowReferral/Documents";
import { demoReferral } from "./demoReferral";

interface Props {
  steps: StepType[];
}

function ReferralWalkthrough({ steps }: Props) {
  const patient = demoReferral.patient;
  const patientName = getFullName(patient);
  const patientAddress = getFullAddress(patient.address);

  const tour = useTour();

  useEffect(() => tour.setSteps?.call(null, steps), [steps]);

  return (
    <div>
      <ReferralHeader referral={demoReferral} isExternal={false} />
      <SuccessMessage message={""} className="mx-auto max-w-7xl" />

      <div className="container grid-cols-12 gap-x-6 lg:grid">
        <div className="col-span-4 col-start-9 row-span-full mb-4 h-fit w-full lg:sticky lg:top-6 lg:mb-0">
          <ReferralHistory className="ref__history" referral={demoReferral} />

          <ContactUs className="mt-4" />
        </div>

        <div className="col-span-8 row-span-full h-fit space-y-4">
          <ScanDetails className="ref__details" referral={demoReferral} />

          <PatientInformation
            referralId={demoReferral.id}
            className="ref__patient"
            isExternal={false}
            id={patient.id}
            name={patientName}
            DOB={patient.dateOfBirth}
            gender={patient.gender}
            phone={patient.phone}
            alternate_phone={patient.alternatePhone}
            email={patient.email}
            address={patientAddress}
          />

          <Documents
            className="ref__docs"
            referral={demoReferral}
            additionalDocuments={[]}
          />
        </div>
      </div>
    </div>
  );
}

export default ReferralWalkthrough;
