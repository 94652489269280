import { LoadingSpinner } from "@scandotcom/react";
import { trackEvent } from "@services/analytics";
import { ValidationError } from "@services/scan/types/common";
import { currentUser } from "@utils/PortalContext";
import { PageInfo } from "components/common/PageInfo";
import PatientForm, { PatientFormFields } from "components/portal/PatientForm";
import { useMutatePatient } from "hooks/useMutatePatient";
import { useMutateReferral } from "hooks/useMutateReferral";
import { usePatient } from "hooks/usePatient";
import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Routes from "utils/Routes";

const PageCreateReferral = () => {
  const navigate = useNavigate();
  const user = currentUser();
  const [searchParams] = useSearchParams();
  const patientId = searchParams.get("patient_id");

  const { data: patient, isLoading } = usePatient(patientId);
  const patientMutation = useMutatePatient();
  const referralMutation = useMutateReferral();

  const [errors, setErrors] = useState<ValidationError[]>([]);

  async function submit(
    patientFields: Partial<PatientFormFields>,
    isDraft: boolean
  ) {
    const patientRes = await patientMutation.mutateAsync({
      patientId: patient?.id,
      ...patientFields,
    });

    if (!patientRes.success || !patientRes.patient) {
      setErrors(patientRes.errors);
      return;
    }

    const { address, ...fields } = patientFields;

    const referralRes = await referralMutation.mutateAsync({
      patient_id: patientRes.patient.id,
      patient_information: {
        ...fields,
        address_line_1: address?.line_1,
        address_line_2: address?.line_2,
        address_city: address?.city,
        address_postcode: address?.postcode,
      },
    });

    if (!referralRes.success) {
      setErrors(referralRes.errors);
      return;
    }

    if (isDraft) {
      return navigate(Routes.home, {
        state: { successMessage: "Referral draft has been saved" },
      });
    }

    trackEvent("continue_referral");
    navigate(Routes.scanDetails(referralRes.referral?.id || ""), {
      state: { referral: referralRes.referral },
    });

    return;
  }

  const breadcrumbs = [
    { name: "Referrals", href: Routes.referrals },
    { name: "Patient", href: Routes.newReferral },
  ];

  if (isLoading) {
    return <LoadingSpinner className="my-8" />;
  }

  return (
    <>
      <PageInfo
        name="Patient"
        breadcrumbs={breadcrumbs}
        description="Provide the details of the patient you are referring for a scan."
      />
      <div className="container">
        <PatientForm
          hasGPDetails={user?.nonPatientLedFlow}
          onSubmittedPatient={(data) => submit(data, false)}
          onDraftPatient={(data) => submit(data, true)}
          submitLabel="Continue"
          validationErrors={errors}
          patient={patient}
        />
      </div>
    </>
  );
};

export default PageCreateReferral;
