import { getReferral } from "@services/scan";
import { Referral } from "@services/scan/types/common";
import Routes from "@utils/Routes";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

interface UseReferralProps {
  onSuccess?: (referral: Referral) => void;
  referralId?: string | null;
}

export function useReferral({ referralId, onSuccess }: UseReferralProps) {
  const navigate = useNavigate();

  useEffect(() => {
    if (!referralId) return navigate(Routes.home);
  }, [referralId]);

  const query = useQuery(
    ["referral", referralId],
    () => getReferral(referralId),
    {
      enabled: Boolean(referralId),
      staleTime: 1000 * 60 * 5,
      onSuccess: (data) => {
        if (!data) return navigate(Routes.home);

        if (onSuccess) onSuccess(data);
      },
    }
  );

  return query;
}
