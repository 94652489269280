import { prepReferral } from "../../utils/prepReferrals";
import { prepValidationErrors } from "../../utils/prepValidationErrors";
import { client } from "../../client";
import type { RawReferral, RawValidationError } from "../../types/requests";

interface RawResponse {
  success: boolean;
  validation_errors: RawValidationError[];
  object: RawReferral;
}

export async function requestScanImages(referralId?: string | null) {
  if (!referralId) {
    throw new Error("No referral ID provided to query.");
  }

  const response = await client<RawResponse>({
    method: "patch",
    url: `/api/v1/referrals/${referralId}/request_scan_images`,
  });

  const success = Boolean(response?.success);
  const referral = prepReferral(response?.object);
  const errors = prepValidationErrors(response?.validation_errors);

  return { success, referral, errors };
}
