import * as React from "react";

interface Size {
  width: number;
  height: number;
}

type CalculateableField = (dimensions: Size) => any;
type CalculateableFields = Record<string, CalculateableField>;
type CalculatedFields<T extends CalculateableFields> = {
  [key in keyof T]: ReturnType<T[key]>;
};

const getWindowDimensions = (): Size => {
  const { clientWidth: width, clientHeight: height } = document.documentElement;

  return {
    width,
    height,
  };
};

const recalculateFields = <T extends CalculateableFields>(
  calculated: Partial<CalculatedFields<T>>,
  toCalculate: T
) => {
  const dimensions = getWindowDimensions();

  if (!toCalculate) return {} as CalculatedFields<T>;
  const clone = { ...calculated };

  let changed = false;

  for (const field in toCalculate) {
    const callback = toCalculate[field];
    clone[field] = callback(dimensions);

    if (calculated[field] !== clone[field]) changed = true;
  }

  if (!changed) return calculated as CalculatedFields<T>;

  return clone as CalculatedFields<T>;
};

export const useOptimizedWindowSize = <T extends CalculateableFields>(
  fields: T
) => {
  const [calculatedFields, setCalculatedFields] = React.useState<
    CalculatedFields<T>
  >(recalculateFields({}, fields));

  React.useEffect(() => {
    const handleResize = () => {
      const recalculated = recalculateFields(calculatedFields, fields);
      setCalculatedFields(recalculated);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [calculatedFields, fields]);

  return calculatedFields;
};
