import { SpecialistConsultation } from "@services/scan/types/common";
import { client } from "../../client";
import { type RawSpecialistConsultation } from "../../types/requests";
import { prepSpecialistConsultation } from "../../utils/prepSpecialistConsultation";

interface RawResponse {
  object: RawSpecialistConsultation;
}

export async function getSpecialistConsultation(
  specialistConsultationId?: string | undefined
): Promise<SpecialistConsultation | undefined> {
  if (!specialistConsultationId) {
    return;
  }

  const response = await client<RawResponse>({
    method: "get",
    url: `/api/v1/specialist_consultations/${specialistConsultationId}`,
  });

  const specialistConsultation = prepSpecialistConsultation(response?.object);

  return specialistConsultation;
}
