import { Disclosure } from "@headlessui/react";
import { IconX, IconMenu2 } from "@tabler/icons";
import classNames from "classnames";
import React from "react";

interface HamburgerButtonProps {
  isToggled: boolean;
  className?: string;
}
export const HamburgerButton = ({
  isToggled,
  className,
}: HamburgerButtonProps) => {
  const MenuIcon = isToggled ? IconX : IconMenu2;

  const buttonStyles = classNames(
    "focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white",
    "text-cream-300 hover:bg-cypress-900 hover:text-cream-300",
    "inline-flex items-center justify-center rounded-md p-2",
    className
  );

  return (
    <Disclosure.Button className={buttonStyles}>
      <span className="sr-only">Toggle main menu</span>
      <MenuIcon className="block h-6 w-6" aria-hidden strokeWidth={1.5} />
    </Disclosure.Button>
  );
};
