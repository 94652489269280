import { Referral, User } from "@services/scan/types/common";
import React from "react";
import { formatDate } from "utils/dates";
import { Panel } from "../Panel";
import { Timeline } from "./Timeline";
import { currentUser } from "@utils/PortalContext";

interface ReferralHistoryProps {
  referral?: Referral;
  className?: string;
}

const descriptionSubmitted = (
  confirmationByUser: boolean,
  nonPatientLedFlow: boolean
) => {
  if (confirmationByUser || nonPatientLedFlow) {
    return "Thank you for submitting this referral.";
  }
  return "Thanks - we've got your referral! We'll contact your patient with a link to complete their scan booking.";
};

const descriptionConfirmed = (
  confirmationByUser: boolean,
  nonPatientLedFlow: boolean
) => {
  if (confirmationByUser) {
    return "We've got your referral! We will keep you updated on its status.";
  }

  if (nonPatientLedFlow) return "An imaging centre has been selected.";

  return "Your patient has picked an imaging provider, confirmed their booking appointment, and provided safety information for our clinicians to review.";
};

export const ReferralHistory = ({
  referral,
  className,
}: ReferralHistoryProps) => {
  const user = currentUser();

  const confirmationByUser = referral?.confirmationByUser || false;
  const nonPatientLedFlow = user?.nonPatientLedFlow || false;

  const events = [
    {
      label: "On hold",
      dateIso: referral?.onHoldAt,
      optional: true,
      description:
        "We are clarifying further details about the scan. Please contact us for any questions.",
    },
    {
      label: "Cancelled",
      dateIso: referral?.cancelledAt,
      optional: true,
      description:
        referral?.cancellationReason || "The scan has been cancelled.",
    },
    {
      label: "Submitted",
      dateIso: referral?.submittedAt,
      optional: false,
      description: descriptionSubmitted(confirmationByUser, nonPatientLedFlow),
    },
    {
      label: "Confirmed",
      dateIso: referral?.patientConfirmedAt,
      optional: false,
      description: descriptionConfirmed(confirmationByUser, nonPatientLedFlow),
    },
    {
      label: "Received",
      dateIso: referral?.receivedAt,
      optional: false,
      description:
        "We've approved and forwarded the referral, and it's been received by the imaging provider. They'll confirm any further details with your patient, and complete the scan.",
    },
    {
      label: "Scan Booked",
      dateIso: referral?.scanBookedAt,
      optional: true,
      description:
        "The patient has agreed to a date and time to attend the scan.",
    },
    {
      label: "Images Requested",
      dateIso: referral?.scanImagesRequestedAt,
      optional: true,
      description:
        "We have requested the images from the imaging provider. You will be able to see a link to them in the scan images section of this page as soon as they are received.",
    },
    {
      label: "Completed",
      dateIso: referral?.completedAt,
      optional: false,
      description:
        "Your patient's scan is complete, and the results report is now available to download.",
    },
  ]
    .filter((event) => event.dateIso || !event.optional)
    .map((event) => ({
      ...event,
      date: event.dateIso ? new Date(event.dateIso) : null,
    }))
    .sort((a, b) => {
      if (!a.date || !b.date) return 0;
      if (a.date > b.date) return 1;
      if (a.date < b.date) return -1;
      return 0;
    });

  const timelineItems = events.map(({ date, description, label }) => ({
    label,
    description,
    completedAt: formatDate(date, "dd MMM yyyy p aaa"),
  }));

  return (
    <Panel title="Referral History" className={className}>
      <Timeline items={timelineItems} />
    </Panel>
  );
};
