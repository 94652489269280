import React from "react";

function Modalities() {
  return (
    <div id="modalities">
      <p className="mt-6 text-xl font-medium">
        What are the different types of diagnostic scans for musculoskeletal
        (MSK) problems?
      </p>

      <div className="flex flex-col pt-4 md:flex-row">
        <div className="w-full py-4 font-medium text-gray-900 md:w-1/3">
          MRI Scans
        </div>
        <div className="w-full pt-0 text-sm text-gray-500 md:w-2/3 md:pt-4">
          <ul className="Safe and non-invasive list-disc pl-4">
            <li className="mb-2">Safe and non-invasive</li>
            <li className="mb-2">
              Best suited to imaging soft tissues, and body parts with higher
              proportions of water, e.g. tendons, muscles, ligaments, fat,
              swelling
            </li>
            <li className="mb-2">
              Use high-strength magnetic fields and radio-frequency waves to
              create images.
            </li>
            <li className="mb-2">Useful for scanning large areas</li>
            <li className="mb-2">Take longer to complete</li>
          </ul>
        </div>
      </div>

      <div className="mt-4 flex flex-col md:mt-8 md:flex-row">
        <div className="w-full font-medium text-gray-900 md:w-1/3">
          Ultrasound Scans
        </div>
        <div className="w-full pt-4 text-sm text-gray-500 md:w-2/3 md:pt-0">
          <ul className="Safe and non-invasive list-disc pl-4">
            <li className="mb-2">Safe and non-invasive</li>
            <li className="mb-2">
              Suitable for targeted imaging of certain body parts
            </li>
            <li className="mb-2">
              Can be used to capture images of body parts while they are in
              motion
            </li>
            <li className="mb-2">
              Use high-frequency sound waves, which bounce off bodily tissues.
              These echoes are used to generate images
            </li>
            <li className="mb-2">
              Quick to use, and often cheaper for patients
            </li>
          </ul>
        </div>
      </div>

      <div className="mt-4 flex flex-col pb-4 md:mt-8 md:flex-row">
        <div className="w-full font-medium text-gray-900 md:w-1/3">
          CT Scans
        </div>
        <div className="w-full pb-4 pt-4 text-sm text-gray-500 md:w-2/3 md:pt-0">
          <ul className="Safe and non-invasive list-disc pl-4">
            <li className="mb-2">
              Uses radiation in the form of X-rays all around the body. The CT
              scanner&#39;s detectors measure how much of the ray passes through
              bodily tissues, to compile images
            </li>
            <li className="mb-2">
              Faster than an MRI scan, and better at viewing bone than an
              ultrasound scan
            </li>
            <li className="mb-0 md:mb-2">
              Must be medically justified due to the radiation exposure involved
              in the scan
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Modalities;
