import type { ScanInvoice } from "../types/common";
import type { RawScanInvoice } from "../types/requests";

export function prepInvoice(rawInvoice?: RawScanInvoice): ScanInvoice | null {
  if (!rawInvoice || !rawInvoice.url) {
    return null;
  }

  return {
    url: rawInvoice.url,
    filename: rawInvoice.filename,
    contentType: rawInvoice.content_type,
    expiresAt: rawInvoice.expires_at,
  };
}
