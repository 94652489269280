import { LoadingSpinner } from "@scandotcom/react";
import { confirmReferral, getSafetyQuestions } from "@services/scan";
import { getReferralBreadcrumbs } from "@utils/referralUtils";
import Routes from "@utils/Routes";
import { PageInfo } from "components/common/PageInfo";
import {
  QuestionsFields,
  SafetyQuestionsForm,
} from "components/portal/SafetyQuestionsForm";
import { useMutateReferral } from "hooks/useMutateReferral";
import { useReferral } from "hooks/useReferral";
import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

function PageSafetyQuestions() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { referralId } = useParams();

  const { data: referral, isLoading } = useReferral({ referralId });
  const mutation = useMutateReferral();

  const questions = useQuery(
    ["security questions", { referralId: referral!.id }],
    () => getSafetyQuestions(referral!.id),
    { enabled: Boolean(referral) }
  );

  const confirm = useMutation(confirmReferral, {
    onSuccess: (data) => {
      if (!data?.success || !data?.referral) {
        return;
      }
      queryClient.setQueryData(["referral", data.referral.id], data.referral);
      navigate(Routes.referralConfirmation(data.referral.id));
    },
  });

  if (questions.isLoading || isLoading) {
    return <LoadingSpinner className="my-8" />;
  }

  const onSubmittedQuestions = (data: QuestionsFields, draft: boolean) => {
    if (!referral) {
      return;
    }

    mutation.mutate(
      {
        referralId: referral.id,
        completed_step: draft ? undefined : 5,
        safety_question_list: data.questions,
        patient_information: { gp_details: data.gpDetails },
      },
      {
        onSuccess: (data) => {
          if (!data.success || !data.referral) return;
          if (draft) {
            navigate(Routes.home, {
              state: { successMessage: "Referral draft has been saved" },
            });
            return;
          }
          queryClient.invalidateQueries("notifications");
          confirm.mutate(data.referral.id);
        },
      }
    );
  };

  if (!referral) {
    return <div className="container py-6">No referral found</div>;
  }

  return (
    <>
      <PageInfo
        name="Safety Questions"
        breadcrumbs={getReferralBreadcrumbs(referral)}
        description="For your patients’ safety it is important that we understand their medical history."
      />

      <div className="mx-auto max-w-[615px] px-6 sm:px-0">
        <SafetyQuestionsForm
          referral={referral}
          initialValues={{
            gpDetails: referral.patient.gpDetails || "",
            questions: referral.safetyQuestions,
          }}
          onSubmit={(data) => onSubmittedQuestions(data, false)}
          onDraft={(data) => onSubmittedQuestions(data, true)}
          questions={questions.data?.questions}
        />
      </div>
    </>
  );
}

export default PageSafetyQuestions;
