import { isValid, parseISO, isPast, isFuture, endOfDay } from "date-fns";

export const validDate = (value: string) => {
  if (!value) {
    return undefined;
  }
  return isValid(parseISO(value)) ? undefined : "Enter a valid date";
};

export const validateDayNotInPast = (value: string) => {
  if (!value) {
    return undefined;
  }
  return isPast(endOfDay(parseISO(value)))
    ? "Enter a date in the future"
    : undefined;
};

export const validateDayNotInFuture = (value: string) => {
  if (!value) {
    return undefined;
  }
  return isFuture(endOfDay(parseISO(value)))
    ? "Enter a date in the past"
    : undefined;
};
