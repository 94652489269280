interface WithLatLng {
  latitude: number;
  longitude: number;
}

export default function mapBounds(
  records: WithLatLng[]
): [[number, number], [number, number]] {
  // if there are no bounds set bounds to London.
  if (!records?.length) {
    return [
      [-0.1695, 51.4901],
      [-0.0905, 51.5305],
    ];
  }
  // Calculate corner values of bounds
  const longs = records.map(({ longitude }) => longitude);
  const lats = records.map(({ latitude }) => latitude);

  const southWest: [number, number] = [Math.min(...longs), Math.min(...lats)];
  const northEast: [number, number] = [Math.max(...longs), Math.max(...lats)];

  return [southWest, northEast];
}
