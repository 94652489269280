import * as React from "react";
import classNames from "classnames";
import { Dialog, Transition } from "@headlessui/react";
import { H3 } from "@scandotcom/react";
import { CrossIcon } from "icons/CrossIcon";
import { NotificationsButton } from "./NotificationsButton";
import { NotificationsContent } from "components/portal/Notifications/NotificationsContent";
import type { NotificationItem } from "@services/scan/types/common";

interface Props {
  all: NotificationItem[];
  read: NotificationItem[];
  unread: NotificationItem[];
}

export const NotificationsMobileMenu: React.FC<Props> = ({
  all,
  read,
  unread,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <>
      <NotificationsButton
        amount={unread.length}
        onClick={() => setIsOpen(true)}
      />
      <Transition
        show={isOpen}
        enter="transition duration-200 ease-out"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition duration-100 ease-out"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        as={React.Fragment}
      >
        <Dialog
          open={isOpen}
          onClose={() => setIsOpen(false)}
          className="relative z-50"
        >
          <div className="fixed inset-0 flex items-center justify-center">
            <Dialog.Panel className="flex h-full w-full flex-col bg-white">
              <div
                className={classNames("flex pl-6 pr-4", {
                  "items-center py-6": !all?.length,
                  "pt-6": Boolean(all?.length),
                })}
              >
                <Dialog.Title as={H3} size="base" className="grow">
                  Notifications
                </Dialog.Title>
                <button
                  className="shadow-xs rounded-lg border border-cream-200 p-2 hover:bg-cream-100"
                  onClick={() => setIsOpen(false)}
                >
                  <CrossIcon className="h-4 w-4 text-cypress-900" />
                </button>
              </div>
              <NotificationsContent
                close={() => setIsOpen(false)}
                all={all}
                read={read}
                unread={unread}
                isMobile
              />
            </Dialog.Panel>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
