import { client } from "../../client";
import type { ScanDocument } from "../../types/common";
import type { RawScanDocument } from "../../types/requests";
import { prepScanDocuments } from "../../utils/prepScanDocuments";

interface RawResponse {
  data: RawScanDocument[];
}

export async function getReferralDocuments(
  referralId?: string | null
): Promise<ScanDocument[]> {
  if (!referralId) {
    throw new Error("No referral ID was provided.");
  }
  const response = await client<RawResponse>({
    method: "get",
    url: `/api/v1/referrals/${referralId}/additional_documents`,
  });

  const documents = prepScanDocuments(response?.data);

  return documents;
}
