import { Button, Heading } from "@scandotcom/react";
import type { Specialist } from "@services/scan";
import classNames from "classnames";
import React from "react";
import { Popup } from "react-map-gl";
import { IconMapPin } from "@tabler/icons";
import { round } from "@utils/numbers";

interface Props {
  specialist: Specialist;
  onClose: () => void;
  onSelect?: () => void;
}

export const SpecialistMapPopup = ({
  specialist,
  onClose,
  onSelect,
}: Props) => {
  const popupStyles = classNames(
    "!max-w-sm [&>.mapboxgl-popup-content]:rounded-xl [&>.mapboxgl-popup-content]:border [&>.mapboxgl-popup-content]:border-cream-300 [&>.mapboxgl-popup-content]:p-6 [&>.mapboxgl-popup-content]:shadow-xl",
    "[&>.mapboxgl-popup-tip]:-mb-2.5 [&>.mapboxgl-popup-tip]:!border-0 [&>.mapboxgl-popup-tip]:!border-b [&>.mapboxgl-popup-tip]:!border-l [&>.mapboxgl-popup-tip]:!border-cream-300 [&>.mapboxgl-popup-tip]:!bg-white [&>.mapboxgl-popup-tip]:!bg-white [&>.mapboxgl-popup-tip]:!w-5 [&>.mapboxgl-popup-tip]:!h-5 [&>.mapboxgl-popup-tip]:rotate-[135deg]"
  );

  return (
    <Popup
      offset={[0, 5]}
      closeButton={false}
      className={popupStyles}
      anchor="top"
      longitude={Number(specialist.longitude)}
      latitude={Number(specialist.latitude)}
      onClose={onClose}
    >
      <Heading size="lg" variant="h3" className="mb-2">
        {specialist.practiceName}
      </Heading>

      <div className="mb-4 flex items-start gap-x-2 text-sm font-medium">
        <IconMapPin
          className="h-5 w-5 shrink-0"
          strokeWidth={1.5}
          aria-hidden
        />

        {specialist.distance !== null && (
          <>
            <div>{round(specialist.distance, 2)}mi</div>
            <div className="text-xl leading-none text-cream-400">&middot;</div>
          </>
        )}

        <address className="not-italic">{specialist.address}</address>
      </div>

      <div className="flex items-center justify-between">
        {onSelect && (
          <Button
            size="sm"
            data-test="list_select_center"
            className="mt-auto min-w-max"
            type="button"
            onClick={onSelect}
          >
            Select centre
          </Button>
        )}
      </div>
    </Popup>
  );
};
