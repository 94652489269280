import { Button, Heading } from "@scandotcom/react";
import { Tag } from "components/common/Tag";
import React from "react";
import { Address } from "./Address";
import { ProviderPrice } from "./ProviderPrice";

interface ImagingProviderCardProps {
  name: string;
  distance?: number;
  minAge?: number;
  address: string;
  price?: number;
  openScanner?: boolean;
  threeT?: boolean;
  onSelect?: () => void;
  tier?: number | null;
  offNetwork?: boolean;
}
export const ImagingProviderCard = ({
  name,
  distance,
  minAge,
  address,
  price,
  onSelect,
  openScanner,
  threeT,
  tier,
  offNetwork,
}: ImagingProviderCardProps) => {
  return (
    <li className="gap-x-5 rounded-xl border border-cream-300 bg-white p-5 text-cypress-900 md:flex">
      <div className="w-full">
        <Heading className="mb-2" variant="h2" size="lg">
          {name}
        </Heading>

        <Address address={address} className="mb-3" distance={distance} />

        <div className="mb-6 flex flex-wrap gap-x-1 text-xs font-medium">
          {openScanner && <Tag>Open Scanner</Tag>}
          {threeT && <Tag>3T</Tag>}
          {minAge && <Tag>Min. age: {minAge}+</Tag>}
          {tier && <Tag>Tier {tier}</Tag>}
          {offNetwork && <Tag>Off-network</Tag>}
        </div>
      </div>

      <div className="mt-5 flex items-center justify-between md:mt-0 md:flex-col md:items-end">
        {price && <ProviderPrice price={price} />}
        {onSelect && (
          <Button
            data-test="select_center"
            className="mt-auto min-w-max"
            type="button"
            onClick={onSelect}
          >
            Select centre
          </Button>
        )}
      </div>
    </li>
  );
};
