import { getReferralDocuments } from "@services/scan";
import { useQuery } from "react-query";

export function useAdditionalDocuments(referralId?: string | null) {
  const query = useQuery(
    ["additionalDocuments", { referralId }],
    () => getReferralDocuments(referralId),
    {
      staleTime: 1000 * 60 * 5,
      enabled: Boolean(referralId),
    }
  );

  return query;
}
