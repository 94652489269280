//
// Transforms this:
// [
//   { attribute: "modality": full_message: "Modality can't be blank" },
//   { attribute: "date_of_birth": fullMessage: "Date of birth must be in the correct format" },
//   { attribute: "date_of_birth": fullMessage: "Date of birth must be before today" },
// ]
// Into that:
// {
//   modality: ["Modality can't be blank"],
//   date_of_birth: [
//     "Date of birth must be in the correct format",
//     "Date of birth must be before today"
//   ]
// }

import { ValidationError } from "@services/scan/types/common";

//
export default function buildErrorsObject(
  array: ValidationError[]
): Record<string, string[]> {
  return array.reduce(
    (acc, error) => ({
      ...acc,
      [error.attribute]: [...(acc[error.attribute] || []), error.fullMessage],
    }),
    {}
  );
}
