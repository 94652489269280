import classNames from "classnames";
import React, { useContext } from "react";
import openLiveChat, { emailSupportHref } from "utils/openLiveChat";
import { PortalContext } from "utils/PortalContext";

interface FooterProps {
  className?: string;
}

export const Footer = ({ className }: FooterProps) => {
  const { currentUser } = useContext(PortalContext);
  const currentYear = window.AppData.Year;

  const footerStyles = classNames(
    "flex flex-row flex-wrap justify-center gap-4 md:gap-6 container mt-16 py-6",
    "font-maison text-cypress-900 text-xs font-normal",
    className
  );

  const linkStyles = "hover:underline";

  const onClickTerms = async () => {
    window.open(currentUser?.contractUrl);
  };

  return (
    <footer className={footerStyles} aria-label="footer">
      <p className="w-full text-center md:mr-auto md:w-fit">
        © {currentYear} Scan.com. All rights reserved.
      </p>

      <a href={emailSupportHref} className={linkStyles}>
        Support
      </a>

      {currentUser?.contractUrl && (
        <button onClick={onClickTerms} className={linkStyles}>
          Terms of Service
        </button>
      )}

      <a
        href="https://uk.scan.com/privacy-policy"
        target="_blank"
        className={linkStyles}
      >
        Privacy Policy
      </a>
    </footer>
  );
};
