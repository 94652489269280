import React from "react";
import PropTypes from "prop-types";
import NavCounter from "./NavCounter";

function Nav({ step, title, subTitle, partnerName }) {
  return (
    <div className="mb-8 flex w-full flex-col items-center justify-between sm:flex-row">
      <div className="mb-12 sm:mb-0">
        <h1 className="text-lg font-semibold text-gray-900">{title}</h1>
        <p className="inline text-gray-500">
          {subTitle}
          <span className="text-primary-700">
            &nbsp;
            {partnerName}
          </span>
        </p>
      </div>
      <NavCounter step={step} />
    </div>
  );
}

Nav.propTypes = {
  step: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  partnerName: PropTypes.string.isRequired,
};

export default Nav;
