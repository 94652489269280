import type { ScanReport } from "../types/common";
import type { RawScanReport } from "../types/requests";

export function prepScanReport(report?: RawScanReport): ScanReport | null {
  if (!report) {
    return null;
  }

  return {
    id: report.id,
    filename: report.filename,
    number: report.number,
    url: report.url,
    contentType: report.content_type,
    expiresAt: report.expires_at,
  };
}

export function prepScanReports(rawReports?: RawScanReport[]): ScanReport[] {
  const reports = rawReports
    ?.map(prepScanReport)
    .filter((report): report is ScanReport => Boolean(report));

  return reports || [];
}
