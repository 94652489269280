import React from "react";

function Overview() {
  return (
    <div id="overview">
      <p className="text-xl font-medium">
        How can scans assist clinicians, therapists and Allied Health
        Professionals (AHPs)?
      </p>
      <div className="py-4 text-gray-500">
        <p className="mb-4">
          Our clinician referral portal is most commonly used for
          musculoskeletal (MSK) purposes. While MSK issues can normally be
          diagnosed through physical examination and assessment by a clinician,
          sometimes scans are required.
        </p>
        <p className="mb-4">
          Common uses for scans within MSK contexts include:
        </p>
        <ul className="list-disc pl-4">
          <li className="mb-4">Putting a patient&#39;s mind at ease</li>
          <li className="mb-4">
            Ruling out certain pathologies, such as tumours
          </li>
          <li className="mb-4">
            Confirming clinical findings that may require review or intervention
            by a specialist doctor (e.g. surgery)
          </li>
          <li className="mb-4">
            Assessing the severity of certain conditions and injuries
          </li>
          <li className="mb-0 pr-2 md:mb-4 md:pr-0">
            Understanding the causes of your patient&#39;s symptoms
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Overview;
