import { client } from "../../client";
import type { ScanImage } from "../../types/common";
import type { RawScanImage } from "../../types/requests";
import { prepScanImages } from "../../utils/prepScanImages";

interface RawResponse {
  data: RawScanImage[];
}

export async function getScanImages(
  referralId?: string | null
): Promise<ScanImage[]> {
  if (!referralId) {
    return [];
  }
  const response = await client<RawResponse>({
    method: "get",
    url: `/api/v1/referrals/${referralId}/scan_images`,
  });

  const images = prepScanImages(response?.data);

  return images;
}
