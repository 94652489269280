import { Heading } from "@scandotcom/react";
import classNames from "classnames";
import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import { IconEdit } from "@tabler/icons";

interface PanelProps {
  title?: ReactNode;
  className?: string;
  children?: ReactNode;
  editHref?: string | null;
}

export const Panel = ({ title, editHref, children, className }: PanelProps) => {
  const panelStyles = classNames(
    "rounded-xl border border-cream-200 bg-white px-6 sm:px-8 py-7",
    className
  );

  const editLinkStyles =
    "flex items-center space-x-1 font-maison text-sm font-medium text-cream-800 hover:text-cypress-900 transition duration-150";

  return (
    <div className={panelStyles}>
      <div className="mb-7 flex items-center justify-between">
        {title && (
          <Heading variant="h2" size="lg" className="capitalize">
            {title}
          </Heading>
        )}

        {editHref && (
          <Link to={editHref} className={editLinkStyles}>
            <IconEdit className="h-5 w-5" strokeWidth={1.5} />
            <span>Edit</span>
          </Link>
        )}
      </div>

      {children}
    </div>
  );
};
