import React from "react";
import { useLocation } from "react-router-dom";
import SuccessMessage from "components/common/SuccessMessage";
import { ReferralList } from "components/portal/List/ReferralList";

function PageListReferrals() {
  const location = useLocation();
  const successMessage = (location.state as Record<string, any>)
    ?.successMessage;

  // Show rails' notice message only once
  let railsNotice = "";
  if (window.AppData.RailsNotice) {
    railsNotice = window.AppData.RailsNotice;
    setTimeout(() => (window.AppData.RailsNotice = null), 1000);
  }

  return (
    <div className="container">
      {!!successMessage && (
        <SuccessMessage message={successMessage} className="mb-6" />
      )}

      {!!railsNotice && (
        <SuccessMessage message={railsNotice} className="mb-6" />
      )}

      <ReferralList />
    </div>
  );
}

export default PageListReferrals;
