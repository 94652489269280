import { Referral } from "@services/scan/types/common";
import { prepReferral } from "@services/scan/utils/prepReferrals";
import { client } from "../../client";
import type { RawReferral } from "../../types/requests";

interface RawResponse {
  object: RawReferral;
}

export async function getReferral(
  referralId?: string | null
): Promise<Referral | null> {
  if (!referralId) {
    return null;
  }

  const response = await client<RawResponse>({
    method: "get",
    url: `/api/v1/referrals/${referralId}`,
  });

  const referral = prepReferral(response?.object);

  return referral;
}
