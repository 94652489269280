import React from "react";
import { SpecialistConsultation } from "@services/scan";
import { Link } from "react-router-dom";
import Routes from "utils/Routes";
import { PageInfo } from "../PageInfo";
import { formatDate } from "@utils/dates";
import { SpecialistConsultationStatusBadge } from "./SpecialistConsultationStatusBadge";

export interface Props {
  specialistConsultation: SpecialistConsultation;
}

export const SpecialistConsultationHeader = ({
  specialistConsultation,
}: Props) => {
  const breadcrumbs = [
    { name: "Referrals", href: Routes.referrals },
    {
      name: "Specialist consultation",
      href: Routes.specialistConsultation(specialistConsultation.id),
    },
  ];

  return (
    <PageInfo name={specialistConsultation.reference} breadcrumbs={breadcrumbs}>
      <PageInfo.Item label="Status">
        <SpecialistConsultationStatusBadge
          specialistConsultation={specialistConsultation}
        />
      </PageInfo.Item>

      <PageInfo.Item label="Patient">
        <Link
          to={Routes.patient(specialistConsultation.patient.id)}
          className="font-semibold text-cypress-500 hover:underline"
        >
          {specialistConsultation.patient.fullName}
        </Link>
      </PageInfo.Item>

      <PageInfo.Item label="Created">
        {formatDate(specialistConsultation.createdAt)}
      </PageInfo.Item>
    </PageInfo>
  );
};
