/**
 * Function to round a number to N decimal places.
 *
 * @param value The value to be rounded.
 * @param decimals The number of decimal places.
 *
 * @returns The rounded value.
 */
export function round(value: number, decimals = 2) {
  const formatter = Intl.NumberFormat("en-GB", {
    maximumFractionDigits: decimals,
    minimumFractionDigits: decimals,
  });

  return formatter.format(value);
}
