import React from "react";
import { bool } from "prop-types";
import ShowReferral from "components/common/ShowReferral";

interface PageViewReferralProps {
  expired?: boolean;
}

function PageViewReferral({ expired }: PageViewReferralProps) {
  if (expired) {
    return (
      <div className="flex min-h-full flex-col items-center bg-white">
        <div className="mt-8 mb-8 w-36 md:mt-16 md:w-44">
          <img src={window.AppData.ScanLogoBlackUrl} alt="Scan.com" />
        </div>
        <div className="mb-8 overflow-hidden rounded-lg border p-8 shadow">
          <p className="text-center text-lg text-gray-900">
            Report link has been expired,
            <br />
            Please contact ukahpbookings@scan.com to request for a copy of the
            report.
          </p>
        </div>
      </div>
    );
  }

  return <ShowReferral isExternal />;
}

PageViewReferral.propTypes = {
  expired: bool.isRequired,
};

export default PageViewReferral;
