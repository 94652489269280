import React from "react";
import { Link } from "react-router-dom";
import Routes from "utils/Routes";
import { Panel } from "../Panel";
import { DetailsEntry, DetailsTable } from "./DetailsTable";

export interface Props {
  id: string;
  name?: string | null;
  DOB?: string | null;
  gender?: string | null;
  phone?: string | null;
  alternate_phone?: string | null;
  email?: string | null;
  address?: string | null;
  className?: string;
  isExternal?: boolean;
  referralId?: string;
}

export const PatientInformation = ({
  id,
  name,
  DOB,
  gender,
  phone,
  alternate_phone,
  email,
  address,
  className,
  isExternal,
  referralId,
}: Props) => {
  // may not have a referral
  const editHref = isExternal
    ? null
    : referralId
    ? Routes.editReferralPatient(referralId)
    : Routes.editPatient(id);
  const patientLink = Routes.patient(id);

  const linkStyles = "font-semibold text-cypress-500 hover:underline";

  return (
    <Panel title="Patient" editHref={editHref} className={className}>
      <DetailsTable>
        <DetailsEntry label="Name">
          {isExternal ? (
            name || "-"
          ) : (
            <Link to={patientLink} className={linkStyles}>
              {name || "-"}
            </Link>
          )}
        </DetailsEntry>

        <DetailsEntry label="Date of birth">{DOB || "-"}</DetailsEntry>

        <DetailsEntry label="Gender">{gender || "-"}</DetailsEntry>

        <DetailsEntry label="Phone number">{phone || "-"}</DetailsEntry>

        <DetailsEntry label="Alternate number">
          {alternate_phone || "-"}
        </DetailsEntry>

        <DetailsEntry label="Email address">{email || "-"}</DetailsEntry>

        <DetailsEntry label="Address">{address || "-"}</DetailsEntry>
      </DetailsTable>
    </Panel>
  );
};
