import {
  Button,
  getLabelStyles,
  RadioInput,
  SelectInput,
  TextArea,
  TextInput,
} from "@scandotcom/react";
import {
  Patient,
  Referral,
  ValidationError,
} from "@services/scan/types/common";
import classNames from "classnames";
import * as E from "fp-ts/Either";
import * as t from "io-ts";
import React, { BaseSyntheticEvent, useEffect, useMemo } from "react";
import { Controller, FieldPath, useForm } from "react-hook-form";
import { FormGroup, GroupSeparator } from "../../components/common/FormGroup";
import { isoToUkDate, ukToIsoDate } from "../../utils/dates";
import { usePreventPageLeave } from "../../utils/usePreventPageLeave";
import { validateDayNotInFuture, validDate } from "../../utils/validation";
import { ControlledDateInput } from "../common/ControlledDateInput";

export interface PatientFormFields {
  title: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  alternate_phone: string;
  date_of_birth: string;
  gender: string;
  medical_notes: string;
  gp_details?: string;
  address: {
    line_1: string;
    line_2: string;
    city: string;
    postcode: string;
  };
}

// An io-ts codec representing a valid path
// to a field in the form
const FieldPath = t.union([
  t.literal("title"),
  t.literal("first_name"),
  t.literal("last_name"),
  t.literal("email"),
  t.literal("phone"),
  t.literal("alternate_phone"),
  t.literal("gp_details"),
  t.literal("date_of_birth"),
  t.literal("gender"),
  t.literal("medical_notes"),
  t.literal("address"),
  t.literal("address.line_1"),
  t.literal("address.line_2"),
  t.literal("address.city"),
  t.literal("address.postcode"),
]);

const isFieldPath = (
  attribute: string
): attribute is FieldPath<PatientFormFields> =>
  E.isRight(FieldPath.decode(attribute));

interface Props {
  hasGPDetails?: boolean;
  className?: string;
  patient?: Partial<Patient> | null;
  onSubmittedPatient: (data: Partial<PatientFormFields>) => void;
  onDraftPatient?: (data: Partial<PatientFormFields>) => void;
  submitLabel: string;
  validationErrors?: ValidationError[];
  isCancel?: boolean;
}

// Because the API handles the patient date of birth in UK format we must
// convert it to ISO format for use in the form (decode) and convert it back
// to UK format for submission to the API (encode).

function PatientForm({
  hasGPDetails,
  patient,
  onSubmittedPatient,
  onDraftPatient,
  submitLabel,
  validationErrors = [],
  isCancel,
  className,
}: Props) {
  const {
    register,
    formState: { errors, isDirty, isSubmitted },
    getValues,
    handleSubmit,
    control,
    setError,
  } = useForm<PatientFormFields>({ defaultValues: prepDefaultValues(patient) });

  const unblockNavigation = usePreventPageLeave(
    "Currently you'd lose anything entered",
    isDirty && !isSubmitted
  );

  const { fieldErrors, otherErrors } = useMemo(
    () => splitErrors(validationErrors),
    [validationErrors]
  );

  useEffect(() => {
    fieldErrors.forEach((err, i) => {
      if (err.attribute && isFieldPath(err.attribute)) {
        setError(
          err.attribute,
          { type: "validate", message: err.fullMessage },
          { shouldFocus: i === 0 }
        );
      }
    });
  }, [fieldErrors]);

  const genders = [
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
  ];

  const titleOptions = useMemo(
    () => [
      { label: "", value: "" },
      ...window.AppData.Titles.split(",").map((title) => ({
        label: title,
        value: title,
      })),
    ],
    []
  );

  const onSubmit = (event) => {
    event.preventDefault();

    unblockNavigation();

    const fields = getValues();

    const date_of_birth = isoToUkDate(fields.date_of_birth);

    const newFields = { ...fields, date_of_birth };

    if (isDraftSubmit(event)) {
      onDraftPatient?.(newFields);
      return;
    }

    handleSubmit(() => onSubmittedPatient(newFields))(event);
  };

  const formStyles = classNames("mx-auto w-full max-w-[796px] mt-4", className);

  return (
    <form onSubmit={onSubmit} noValidate className={formStyles}>
      <FormGroup
        name="Personal information"
        description="These personal details will be used for the clinical referral that is sent to the scanning location."
      >
        <div className="col-span-2 grid gap-6">
          <SelectInput
            {...register("title", { required: "Select the patient's title" })}
            id="title"
            label="Title"
            options={titleOptions}
            errorMessage={errors.title?.message}
          />
          <TextInput
            {...register("first_name", {
              required: "Enter a first name",
            })}
            id="first_name"
            placeholder="John"
            label="First name"
            errorMessage={errors.first_name?.message}
            data-test="first_name"
          />
          <TextInput
            {...register("last_name", { required: "Enter a last name" })}
            id="last_name"
            placeholder="Doe"
            label="Last name"
            errorMessage={errors.last_name?.message}
            data-test="last_name"
          />
        </div>

        <fieldset data-test="select_gender_container">
          <legend className={getLabelStyles({ variant: "dark" })}>
            Gender
          </legend>

          <div className="flex gap-x-8 text-sm font-normal">
            {genders.map(({ label, value }) => (
              <RadioInput
                key={label}
                {...register("gender", { required: "Select a gender" })}
                label={label}
                value={value}
                size="sm"
              />
            ))}
          </div>
        </fieldset>

        <Controller
          name="date_of_birth"
          control={control}
          rules={{
            required: "Enter a date of birth",
            validate: { validDate, validateDayNotInFuture },
          }}
          render={({ field: { onChange, value, ref } }) => (
            <ControlledDateInput
              ref={ref}
              initialValue={value}
              onChange={onChange}
              label="Date of birth"
              errors={{ year: errors.date_of_birth?.message }}
            />
          )}
        />
      </FormGroup>

      <GroupSeparator />

      <FormGroup
        name="Contact information"
        description={
          <>
            We need the patient’s email address for sending booking updates and
            their phone number for pre and post scan consultations.
            <br />
            <br />
            If these details are incorrect their referrals may be delayed.
          </>
        }
      >
        <TextInput
          id="email"
          label="Email address"
          type="email"
          placeholder="john.doe@example.com"
          {...register("email", {
            required: "Enter the patient's email address",
          })}
          errorMessage={errors.email?.message}
          data-test="email"
        />
        <TextInput
          type="tel"
          id="phone"
          placeholder="01277 674 889"
          label="Phone number"
          {...register("phone", {
            required: "Enter the patient's phone number",
          })}
          errorMessage={errors.phone?.message}
          data-test="phone"
        />
        <TextInput
          type="tel"
          id="alternate_phone"
          placeholder="01632 960 000"
          label="Alternate number"
          isOptional
          {...register("alternate_phone")}
          errorMessage={errors.alternate_phone?.message}
          data-test="alternate_phone"
        />
      </FormGroup>

      <GroupSeparator />

      <FormGroup name="Address">
        <TextInput
          id="line_1"
          label="Line 1"
          {...register("address.line_1")}
          errorMessage={errors.address?.line_1?.message}
          data-test="line_1"
        />

        <TextInput
          id="line_2"
          label="Line 2"
          {...register("address.line_2")}
          errorMessage={errors.address?.line_2?.message}
          data-test="line_2"
        />

        <div className="flex gap-4">
          <TextInput
            id="city"
            label="City"
            className="w-full"
            {...register("address.city")}
            errorMessage={errors.address?.city?.message}
            data-test="city"
          />

          <TextInput
            id="postcode"
            label="Postcode"
            className="max-w-[9rem]"
            placeholder="SW1A 1AA"
            {...register("address.postcode")}
            errorMessage={errors.address?.postcode?.message}
            data-test="postcode"
          />
        </div>
      </FormGroup>

      {hasGPDetails && (
        <>
          <GroupSeparator />
          <FormGroup
            name="GP details"
            description="Please provide the patient’s GP surgery name and address if known. We will only contact the patient’s GP in an emergency."
          >
            <TextArea
              id="gp_details"
              label="GP details"
              {...register("gp_details")}
              errorMessage={errors.gp_details?.message}
              data-test="gp_details"
            />
          </FormGroup>
        </>
      )}

      {/* render any extra validation errors that can't be associated with a form field */}
      {otherErrors.length > 0 && (
        <div className="md:grid md:grid-cols-3">
          <div className="col-span-2 col-start-2 py-4 text-red">
            <h4 className="font-bold">
              Some errors occurred in your submission:
            </h4>
            <ul className="text-sm">
              {otherErrors.map((error) => (
                <li key={error.attribute}>{error.fullMessage}</li>
              ))}
            </ul>
          </div>
        </div>
      )}

      <div className="mt-8 flex flex-wrap justify-end gap-6">
        {onDraftPatient && (
          <Button
            data-type="draft"
            data-test="submit-draft"
            type="submit"
            kind="secondary"
            className="w-full sm:w-fit md:order-2"
          >
            Save draft
          </Button>
        )}
        <Button
          data-test="submit"
          type="submit"
          className="w-full sm:w-fit md:order-2"
        >
          {submitLabel || "Save"}
        </Button>
      </div>
    </form>
  );
}

function isDraftSubmit(e?: BaseSyntheticEvent<HTMLFormElement, SubmitEvent>) {
  return e?.nativeEvent?.submitter?.dataset["type"] === "draft";
}

export function translateValidationError(error: ValidationError) {
  // re-assign patient validation errors nested under "patient_information"
  if (error.attribute?.startsWith("patient_information.address_")) {
    return {
      ...error,
      attribute: error.attribute.replace(
        "patient_information.address_",
        "address."
      ),
    };
  }
  if (error.attribute?.startsWith("patient_information.")) {
    return {
      ...error,
      attribute: error.attribute.replace("patient_information.", ""),
    };
  }
  return error;
}

function prepDefaultValues(patient?: Partial<Referral["patient"]> | null) {
  return {
    title: patient?.title,
    first_name: patient?.firstName,
    last_name: patient?.lastName,
    gender: patient?.gender,
    date_of_birth: ukToIsoDate(patient?.dateOfBirth),
    email: patient?.email,
    gp_details: patient?.gpDetails,
    phone: patient?.phone,
    alternate_phone: patient?.alternatePhone,
    address: {
      line_1: patient?.address?.line1,
      line_2: patient?.address?.line2,
      city: patient?.address?.city,
      postcode: patient?.address?.postcode,
    },
  };
}

function splitErrors(validationErrors: ValidationError[]) {
  const { fieldErrors, otherErrors } = validationErrors.reduce(
    (acc, curr) => {
      const error = translateValidationError(curr);

      if (error.attribute && isFieldPath(error.attribute)) {
        return { ...acc, fieldErrors: [...acc.fieldErrors, error] };
      }

      return { ...acc, otherErrors: [...acc.otherErrors, error] };
    },
    {
      fieldErrors: [] as ValidationError[],
      otherErrors: [] as ValidationError[],
    }
  );

  return { fieldErrors, otherErrors };
}

export default PatientForm;
