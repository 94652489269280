import { getBodyParts } from "@services/scan";
import { useQuery } from "react-query";

interface UseBodypartsOptions {
  modalityId?: string | null;
  onSuccess?: (bodyParts: Awaited<ReturnType<typeof getBodyParts>>) => void;
  enabled?: boolean;
}

export function useBodyParts({
  modalityId,
  onSuccess,
  enabled = true,
}: UseBodypartsOptions) {
  const { data, ...rest } = useQuery(
    ["bodyParts", modalityId],
    () => getBodyParts(modalityId),
    {
      enabled: enabled,
      onSuccess: !!onSuccess
        ? (bodyparts) => {
            if (!modalityId || !bodyparts?.length) return;

            onSuccess(bodyparts);
          }
        : undefined,
    }
  );

  return { bodyParts: data ?? [], ...rest };
}
