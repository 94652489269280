import React from "react";
import PropTypes from "prop-types";

function Errors({ errors }) {
  if (!errors.length) return null;

  return (
    <div className="flex flex-col items-end py-2">
      {errors.map((error) => (
        <span key={error} className="text-right text-sm text-red">
          *{error}
        </span>
      ))}
    </div>
  );
}

Errors.defaultProps = {
  errors: [],
};

Errors.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
};

export default Errors;
