import { CheckBadgeIcon, ClockIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";
import React from "react";
import type {
  SpecialistConsultation,
  SpecialistConsultationStatus,
} from "@services/scan";

interface Props {
  specialistConsultation: SpecialistConsultation;
  className?: string;
}

const statusClass: Record<SpecialistConsultationStatus, string> = {
  "In Draft": "bg-cream-100 text-neutral-600",
  Referred: "bg-cypress-100 text-cypress-500",
};

const StatusIcon: Record<
  SpecialistConsultationStatus,
  React.FC<React.ComponentProps<"svg">>
> = {
  "In Draft": ClockIcon,
  Referred: CheckBadgeIcon,
};

export const SpecialistConsultationStatusBadge: React.FC<Props> = ({
  specialistConsultation,
  className,
}) => {
  const status = specialistConsultation.status;
  const Icon = StatusIcon[status];

  const badgeStyles = classNames(
    "inline-flex max-w-full items-center gap-2 rounded-full px-3.5 py-2 text-xs font-semibold",
    statusClass[status] || "bg-cream-100 text-neutral-600",
    className
  );

  return (
    <div className={badgeStyles}>
      {Icon && <Icon className="h-4 w-4 flex-shrink-0" />}
      <span className="truncate" data-test="referral_status">
        {status}
      </span>
    </div>
  );
};
