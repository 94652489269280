import { client } from "@services/scan/client";
import type { NotificationItem } from "@services/scan/types/common";
import type { RawNotificationItem } from "@services/scan/types/requests";
import { prepNotifications } from "@services/scan/utils/prepNotifications";

interface RawResponse {
  data: RawNotificationItem[];
}

export async function getNotifications() {
  const response = await client<RawResponse>({
    method: "get",
    url: "/api/v1/notifications",
  });

  const all = prepNotifications(response?.data);

  const read = all.filter(({ readAt }) => Boolean(readAt));
  const unread = all.filter(({ readAt }) => !Boolean(readAt));

  return { all, read, unread };
}
