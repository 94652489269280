import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { fetchPartner } from "services/partnerApi";
import { LoadingSpinner } from "@scandotcom/react";
import CounterVertical from "components/terms_review/CounterVertical";
import { trackEvent } from "services/analytics";

function PageWelcome({ partnerId }) {
  const [partnerData, setPartnerData] = useState({
    loading: true,
    partner: null,
  });

  useEffect(() => {
    fetchPartner(partnerId).then(({ partner }) => {
      setPartnerData({ loading: false, partner });
    });
  }, []);

  if (partnerData.loading) {
    return <LoadingSpinner className="my-8" />;
  }

  const { partner } = partnerData;

  if (!partner) {
    return <div>Partner not found</div>;
  }

  const contactName = partner.partner_termsheet.contact_name;

  const termsheetAccepted = !!partner.partner_termsheet.accepted_at;
  const contractAccepted = !!partner.partner_contract.accepted_at;

  const clickReviewTermsheet = () => {
    trackEvent("review_termsheet");
  };

  return (
    <div className="mx-auto max-w-5xl rounded-xl border-gray-200 p-16 text-center sm:my-12 sm:border">
      <div className="flex flex-col items-center justify-between sm:flex-row">
        <div className="mb-4 flex flex-col items-center sm:mb-0 sm:items-start">
          <h1 className="text-lg font-semibold text-gray-900">{`Hi ${contactName},`}</h1>
          <p className="inline text-gray-500">
            Welcome to the Scan.com Referral Portal.
          </p>
        </div>
        <div className="">
          <div className="ml-3 inline-flex">
            <a
              href="https://uk.scan.com/"
              target="_blank"
              className="inline-flex items-center justify-center whitespace-nowrap rounded-lg bg-primary-50 px-8 py-4 text-sm font-medium text-primary-500 transition-colors hover:bg-primary-700 focus:outline-none focus:ring-2  focus:ring-primary-500 focus:ring-offset-2"
              rel="noreferrer"
            >
              About Scan.com
            </a>
          </div>
        </div>
      </div>
      <div className="mt-12 flex flex-col items-start sm:my-12 sm:px-24">
        <p className="mt-4 text-xl font-semibold">
          Steps to get started with referrals:
        </p>
        <div className="mt-6 w-full">
          <CounterVertical
            title="Review Termsheet"
            description="This is an agreement of terms between us."
            count="1"
            completed={termsheetAccepted}
          />
          <CounterVertical
            title="Review Contract"
            description="You need to accept this on behalf of your company."
            count="2"
            completed={contractAccepted}
          />
          <CounterVertical
            title="Invite any other users for sign up"
            description="For each referral you will receive a fee as outlined in the Termsheet"
            count="3"
            completed={false}
          />
        </div>
      </div>
      <div className="mt-8 flex justify-center space-x-24">
        {!termsheetAccepted && (
          <div className="inline-flex rounded-md shadow">
            <Link
              data-test="review_termsheet"
              to="/termsheet"
              onClick={clickReviewTermsheet}
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-primary-600 px-5 py-3 text-base font-medium text-white hover:bg-primary-700"
            >
              Review Termsheet
            </Link>
          </div>
        )}
        {termsheetAccepted && !contractAccepted && (
          <div className="ml-3 inline-flex">
            <Link
              to="/contract"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-primary-600 px-5 py-3 text-base font-medium text-white hover:bg-primary-700"
            >
              Review Contract
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}

PageWelcome.propTypes = {
  partnerId: PropTypes.string.isRequired,
};

export default PageWelcome;
