import { client } from "../../client";
import type { BodyPart } from "../../types/common";

interface RawResponse {
  data: BodyPart[];
}

export async function getBodyParts(
  modality?: string | null
): Promise<BodyPart[]> {
  const response = await client<RawResponse>({
    method: "get",
    params: { modality },
    url: "/api/v1/body_parts",
  });

  return response?.data ?? [];
}
