import React from "react";
import PropTypes from "prop-types";

function NavCounter({ step }) {
  const termsheet = step === "termsheet";
  const contract = step === "contract";

  return (
    <div className="flex justify-between pb-12 pr-12">
      <div className="mr-28 flex flex-col items-center justify-center">
        <div className="relative h-fit w-fit items-center">
          <span
            className={`${
              contract ? "bg-primary-600" : "bg-gray-200"
            } z-100 absolute top-2 left-5 h-0.5 w-28`}
          />
          <span
            className={`${
              termsheet || contract
                ? "border-primary-600 bg-primary-200"
                : "bg-white"
            } absolute z-50 inline-flex h-4 w-4  items-center  justify-center rounded-full border-2 p-2`}
          >
            <span
              className={`${
                termsheet || contract ? "bg-primary-600" : "bg-white"
              } justify-cente z-70 absolute inline-flex h-4 w-4 items-center rounded-full border-2 border-white`}
            />
          </span>
          <h2
            className={`${
              termsheet || contract ? "text-primary-600" : "text-gray-500 "
            }  absolute top-6 -left-6 text-sm font-medium`}
          >
            Termsheet
          </h2>
        </div>
      </div>
      <div className="mr-28 flex flex-col items-center justify-center">
        <div className="relative h-fit w-fit items-center">
          <span className="z-100 absolute top-2 left-5 h-0.5 w-28 bg-gray-200" />
          <span
            className={`${
              contract ? "border-primary-600 bg-primary-200" : "bg-white"
            } absolute z-50 inline-flex h-4 w-4 items-center justify-center  rounded-full border-2 bg-white p-2`}
          >
            <span
              className={`${
                contract ? "bg-primary-600" : "bg-white"
              } justify-cente z-70 absolute inline-flex h-4 w-4 items-center rounded-full border-2 border-white`}
            />
          </span>
          <h2
            className={`${
              contract ? "-600 text-primary-600" : "text-gray-500 "
            } absolute top-6 -left-5 text-sm font-medium text-gray-500`}
          >
            Contract
          </h2>
        </div>
      </div>
      <div className="">
        <div className="relative h-fit w-fit items-center">
          <span className="absolute z-50 inline-flex h-4 w-4 items-center justify-center rounded-full border-2 border-gray-200 bg-gray-200 p-2">
            <span className="z-70 absolute inline-flex h-4 w-4 items-center justify-center rounded-full border-2 border-white bg-white" />
          </span>
          <h2 className="absolute top-6 -left-6 text-sm font-medium text-gray-500">
            Complete
          </h2>
        </div>
      </div>
    </div>
  );
}

NavCounter.propTypes = {
  step: PropTypes.string.isRequired,
};

export default NavCounter;
