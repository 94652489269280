import React from "react";
import { Routes, Route } from "react-router-dom";

import ImagingGuideLayout from "layouts/ImagingGuideLayout";
import PageImagingGuide from "pages/imaging_guide/PageImagingGuide";

export default function ImagingGuideApp() {
  return (
    <Routes>
      <Route path="/" element={<ImagingGuideLayout />}>
        <Route index element={<PageImagingGuide />} />
      </Route>
    </Routes>
  );
}
