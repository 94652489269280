import React from "react";
import { string } from "prop-types";
import { Routes, Route } from "react-router-dom";

import PageViewReferral from "pages/view_referral/PageViewReferral";

interface ViewReferralAppProps {
  expiredStr: string;
}

export default function ViewReferralApp({ expiredStr }: ViewReferralAppProps) {
  const expired = expiredStr === "true";

  return (
    <Routes>
      <Route path="/">
        <Route
          path="/referrals/:referralId"
          element={<PageViewReferral expired={expired} />}
        />
      </Route>
    </Routes>
  );
}

ViewReferralApp.propTypes = {
  expiredStr: string.isRequired,
};
