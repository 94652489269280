import {
  LoadingSpinner,
  Pagination,
  SelectInput,
  TextInput,
} from "@scandotcom/react";
import { IconMapPin } from "@tabler/icons";
import { currentUser } from "@utils/PortalContext";
import { translatePageInfo } from "@utils/translatePageInfo";
import { PageInfo } from "components/common/PageInfo";
import {
  MachineTypeFilters,
  SearchRadiusValues,
  SortByValues,
} from "components/portal/ImagingProviders/constants";
import { ImagingProviderCard } from "components/portal/ImagingProviders/ImagingProviderCard";
import { Map } from "components/portal/ImagingProviders/Map";
import { SortBy } from "components/portal/ImagingProviders/SortBy";
import { ProviderTimeSlotsModal } from "components/portal/Modals/ProviderTimeSlotModal";
import { useBookingProvider } from "hooks/useBookingProvider";
import { useImagingProviders } from "hooks/useImagingProviders";
import { useReferral } from "hooks/useReferral";
import React, { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import {
  getFullAddress,
  getReferralBreadcrumbs,
  shouldShowMachineType,
} from "utils/referralUtils";

function PageSelectImagingProvider() {
  const { referralId } = useParams();
  const { data: referral, isLoading: loadingReferral } = useReferral({
    referralId,
  });

  const defaultArea = getFullAddress(referral?.patient.address) || "London";

  const user = currentUser();

  const providers = useImagingProviders({
    referralId: referral?.id,
    modality: referral?.modality || undefined,
    area: defaultArea,
    includeOffNetwork: true,
  });

  const booking = useBookingProvider(referral?.id);

  if (loadingReferral || !referral) {
    return <LoadingSpinner />;
  }

  const isLoading = booking.isLoading || providers.isLoading;
  const showMachineType = shouldShowMachineType(referral);

  return (
    <>
      <PageInfo
        name="Scanning location"
        breadcrumbs={getReferralBreadcrumbs(referral)}
        description="Choose a convenient scanning location for your patient to have their scan at."
      />
      <div className="container grid auto-rows-min gap-6 lg:grid-cols-2">
        <ProviderTimeSlotsModal
          onSlotPicked={({ ids }) => booking.setSlot(ids)}
          timeSlots={booking.slots}
          onClose={booking.closeSlots}
          isOpen={booking.showSlots}
        />
        <div className="col-span-full md:col-span-1">
          <TextInput
            defaultValue={defaultArea}
            label="Location Search"
            hideLabel
            rounded="full"
            className="mb-4"
            leftIcon={<IconMapPin strokeWidth={1.5} className="h-6 w-6" />}
            onChange={(e) => providers.setSearch(e.currentTarget.value)}
          />
          <div className="mb-4 flex flex-wrap items-center gap-4">
            <SelectInput
              size="sm"
              hideLabel
              label="Search Radius"
              value={providers.options.radius}
              options={SearchRadiusValues}
              className="w-[calc(50%-0.5rem)] sm:w-fit"
              onChange={(e) =>
                providers.setFilters({ radius: e.currentTarget.value })
              }
            />

            {showMachineType && (
              <SelectInput
                hideLabel
                size="sm"
                label="Type of MRI machine"
                placeholder="Type of machine"
                options={MachineTypeFilters}
                className="w-[calc(50%-0.5rem)] sm:w-fit"
                onChange={(e) =>
                  providers.setFilters({
                    threeT: e.currentTarget.value === "threeT",
                    openScanner: e.currentTarget.value === "openScanner",
                  })
                }
              />
            )}

            {providers.hasPrices && (
              <SortBy
                onChange={(value) => providers.setOrder(value)}
                label="Sort by:"
                order={providers.options.order}
                direction={providers.options.direction}
                options={SortByValues}
                className="min-w-fit sm:ml-auto"
              />
            )}
          </div>

          {isLoading && <LoadingSpinner />}

          {!isLoading && (
            <ul className="space-y-4">
              {providers.data.map((provider) => (
                <ImagingProviderCard
                  key={provider.id}
                  name={provider.name}
                  address={provider.address}
                  tier={provider.tier}
                  price={provider.price}
                  distance={provider.distance}
                  minAge={provider.minAge}
                  openScanner={provider.openScanner}
                  offNetwork={provider.offNetwork}
                  threeT={provider.threeT}
                  onSelect={() => booking.selectProvider(provider.id)}
                />
              ))}
            </ul>
          )}

          {!isLoading && providers.data.length === 0 && (
            <p>
              No imaging providers were found for your search query. Try a
              different search or increasing the search radius.
            </p>
          )}
        </div>

        <div className="top-6 col-span-full row-span-1 md:sticky md:col-span-1">
          {providers.data.length > 0 && (
            <Map
              providers={providers.data}
              onSelectProvider={({ id }) => booking.selectProvider(id)}
            />
          )}
        </div>

        <div className="col-span-full mx-auto mt-6 h-fit sm:col-span-1">
          <Pagination
            meta={translatePageInfo(providers.pageInfo)}
            onClickPage={(page) => {
              providers.setPage(page);
              window?.document?.documentElement?.scrollTo({ top: 0, left: 0 });
            }}
          />
        </div>
      </div>
    </>
  );
}

export default PageSelectImagingProvider;
