import { Dialog } from "@headlessui/react";
import { Pagination, TextInput } from "@scandotcom/react";
import { Patient } from "@services/scan/types/common";
import { IconSearch } from "@tabler/icons";
import { formatDate, formatRelativeDate } from "@utils/dates";
import { getInitials } from "@utils/referralUtils";
import { translatePageInfo } from "@utils/translatePageInfo";
import classNames from "classnames";
import { usePatientList } from "hooks/usePatientList";
import React, { useRef } from "react";
import { Modal, ModalProps } from "../../common/Modal";

interface PatientsModalProps extends ModalProps {
  onSelect: (patient: Patient) => void;
}

const patientsPerPage = 5;

export const PatientsModal = ({
  onClose,
  isOpen,
  onSelect,
}: PatientsModalProps) => {
  const searchInput = useRef(null);

  const { patients, pageInfo, setPage, setSearch } = usePatientList({
    order: "created_at",
    limit: patientsPerPage,
  });

  function closeModal() {
    setSearch("");
    onClose();
  }

  return (
    <Modal
      onClose={closeModal}
      initialFocus={searchInput}
      className="max-w-4xl p-12 text-cypress-900"
      isOpen={isOpen}
    >
      <Modal.Close onClick={closeModal} className="-mt-8 -mr-8" />
      <Dialog.Title className="mb-2 font-maison-extended text-2xl font-bold tracking-tight ">
        Choose an existing patient
      </Dialog.Title>
      <p className="text mb-8 text-base">
        Choose an existing patient if you have previously created a referral for
        them.
      </p>
      <TextInput
        className="mb-2"
        label="Search patients"
        hideLabel
        leftIcon={<IconSearch />}
        placeholder="Search by name or email"
        rounded="full"
        onChange={(e) => setSearch(e.target.value)}
      />

      <div className="text-sm font-normal">
        <div className="mb-4 grid grid-cols-[2fr_1fr] place-items-start border-b border-cream-200 px-2 py-4 font-medium text-cream-800">
          <div>Name</div>
          <div>Created</div>
        </div>

        {patients.map((patient) => (
          <PatientRow
            className="mb-4 grid-cols-[2fr_1fr] place-items-start"
            key={patient.id}
            firstName={patient.firstName}
            lastName={patient.lastName}
            onClick={() => onSelect(patient)}
            email={patient.email}
            created={patient.createdAt}
          />
        ))}
      </div>

      {(pageInfo?.total || 0) > patientsPerPage ? (
        <div className="mt-8 flex justify-center">
          <Pagination
            meta={translatePageInfo(pageInfo)}
            onClickPage={setPage}
          />
        </div>
      ) : null}
    </Modal>
  );
};

interface PatientRowProps {
  onClick: () => void;
  email: string;
  firstName?: string;
  lastName?: string;
  className?: string;
  created: string;
}

function PatientRow({
  email,
  onClick,
  className,
  firstName = "",
  lastName = "",
  created,
}: PatientRowProps) {
  return (
    <button
      data-test="patient_row"
      onClick={onClick}
      className={classNames(
        "group grid w-full items-center rounded-xl p-2 text-cypress-900 transition-colors hover:bg-cream-100 focus:bg-cream-100 focus:outline-none",
        className
      )}
    >
      <div className="flex items-center gap-x-4">
        <div className="flex h-11 w-11 items-center justify-center rounded-full bg-cream-100 text-base font-medium uppercase transition-colors group-hover:bg-cream-200">
          {getInitials(firstName, lastName)}
        </div>
        <div>
          <div className="text-start text-base font-medium capitalize">
            {firstName} {lastName}
          </div>
          <div className="text-cream-800">{email}</div>
        </div>
      </div>

      <span title={formatDate(created)}>{formatRelativeDate(created)}</span>
    </button>
  );
}
