import * as React from "react";

import { PopoverContentProps } from "@reactour/tour";
import classNames from "classnames";

function TourStepContent(props: PopoverContentProps) {
  const { steps, currentStep } = props;

  const content = steps[currentStep].content as any;

  const isAnchor = currentStep === 0 || currentStep === steps.length - 1;

  return (
    <div
      className={classNames("relative shadow-xl", {
        "p-5": !isAnchor,
        "p-10": isAnchor,
      })}
    >
      {typeof content === "function" ? content({ ...props }) : content}
    </div>
  );
}

export default TourStepContent;
