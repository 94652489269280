import { PageInfo } from "components/common/PageInfo";
import PatientForm, { PatientFormFields } from "components/portal/PatientForm";
import { useMutatePatient } from "hooks/useMutatePatient";
import { bool, func } from "prop-types";
import React from "react";
import { useNavigate } from "react-router-dom";
import Routes from "utils/Routes";

interface PageCreatePatientProps {
  isNewBooking?: boolean;
  onSuccess?: (value: string) => void;
}

const PageCreatePatient = ({
  isNewBooking,
  onSuccess,
}: PageCreatePatientProps) => {
  const navigate = useNavigate();

  const mutation = useMutatePatient();

  function onSubmittedPatient(patient: Partial<PatientFormFields>) {
    mutation.mutate(patient, {
      onSuccess: (data) => {
        if (!data.success || !data.patient) {
          return;
        }

        if (onSuccess) {
          onSuccess(`${patient.first_name} ${patient.last_name}`);
          return;
        }

        navigate(Routes.patient(data.patient.id));
        return;
      },
    });
  }

  const breadcrumbs = [
    { name: "Patients", href: Routes.patients },
    { name: "New patient", href: Routes.newPatient },
  ];

  return (
    <>
      <PageInfo breadcrumbs={breadcrumbs} name="New patient" />

      <div className="container">
        <PatientForm
          className="sm:mt-14"
          patient={{}}
          onSubmittedPatient={onSubmittedPatient}
          submitLabel={isNewBooking ? "Continue" : "Create"}
          validationErrors={mutation.data?.errors}
        />
      </div>
    </>
  );
};

export default PageCreatePatient;

PageCreatePatient.propTypes = {
  isNewBooking: bool,
  onSuccess: func,
};
