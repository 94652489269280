import { CheckboxInput } from "@scandotcom/react";
import { IconCheck } from "@tabler/icons";
import classNames from "classnames";
import React, { useEffect, useState } from "react";

interface NoToAllProps {
  onChange: (value: boolean) => void;
  checked?: boolean;
}

export function NoToAll({ onChange, checked }: NoToAllProps) {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const onScroll = () => setIsSticky(window.scrollY > 150);

    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const wrapperStyles = classNames(
    isSticky && "sticky top-4 shadow-lg",
    "group flex w-full items-start gap-x-4 rounded-2xl border p-4 outline-none transition-colors",
    "border-neutral-200 bg-white active:border-pink-300 active:bg-pink-50 hover:border-neutral-300 hover:bg-neutral-50"
  );

  const onClick = () => onChange(!checked);

  return (
    <button type="button" onClick={onClick} className={wrapperStyles}>
      <CheckboxInput checked={checked} className="mt-1" readOnly />

      <div className="space-y-1 text-left">
        <span className="text-base font-medium text-neutral-900">
          No to all
        </span>
        <p className="text-sm font-normal text-neutral-500">
          Mark all safety questions as 'No'
        </p>
      </div>
    </button>
  );
}
