import * as React from "react";
import { ButtonLink } from "ui/ButtonLink";
import { H3, H4 } from "@scandotcom/react";
import { BellIcon } from "icons/BellIcon";

interface Props {
  close: () => void;
}

export const NotificationsEmptyState: React.FC<Props> = ({ close }) => {
  return (
    <>
      <div className="hidden px-6 py-5 sm:block">
        <H3 size="base">Notifications</H3>
      </div>
      <div className="px-14 text-center sm:py-12">
        <div className="mb-4 inline-grid h-20 w-20 place-items-center rounded-full bg-cream-100 p-4">
          <BellIcon className="h-12 w-12 stroke-cypress-900 stroke-1 text-transparent" />
        </div>
        <H4 size="base" className="mb-2">
          No notifications
        </H4>
        <p className="mb-6 text-sm leading-normal text-cream-800">
          We'll notify you here when there are updates on your referrals.
        </p>
        <ButtonLink
          to="/referrals/new"
          onClick={close}
          className="mx-auto block w-fit"
        >
          Create a referral
        </ButtonLink>
      </div>
    </>
  );
};
