import {
  CheckBadgeIcon,
  ClockIcon,
  DocumentCheckIcon,
  XCircleIcon,
} from "@heroicons/react/20/solid";
import { Referral, ReferralStatus } from "@services/scan/types/common";
import classNames from "classnames";
import React from "react";

interface Props {
  referral: Referral;
  className?: string;
}

const statusClass: Record<ReferralStatus, string> = {
  "In Draft": "bg-cream-100 text-neutral-600",
  Created: "bg-cream-100 text-neutral-600",
  Submitted: "bg-yellow-100 text-yellow-900",
  Completed: "bg-pink-200",
  Received: "bg-yellow-100 text-yellow-900",
  "Scan Booked": "bg-cream-200 text-neutral-700",
  "On hold": "bg-cream-200 text-neutral-700",
  Cancelled: "bg-cream-200 text-neutral-700",
  Confirmed: "bg-cypress-100 text-cypress-500",
};

const StatusIcon: Record<
  ReferralStatus,
  React.FC<React.ComponentProps<"svg">>
> = {
  "In Draft": ClockIcon,
  Created: ClockIcon,
  Submitted: ClockIcon,
  Completed: DocumentCheckIcon,
  Received: CheckBadgeIcon,
  "Scan Booked": CheckBadgeIcon,
  "On hold": ClockIcon,
  Cancelled: XCircleIcon,
  Confirmed: CheckBadgeIcon,
};

export const ReferralStatusBadge: React.FC<Props> = ({
  referral,
  className,
}) => {
  const status = portalStatus(referral);
  const Icon = StatusIcon[status];

  const badgeStyles = classNames(
    "inline-flex max-w-full items-center gap-2 rounded-full px-3.5 py-2 text-xs font-semibold",
    statusClass[status] || "bg-cream-100 text-neutral-600",
    className
  );

  return (
    <div className={badgeStyles}>
      {Icon && <Icon className="h-4 w-4 flex-shrink-0" />}
      <span className="truncate" data-test="referral_status">
        {status}
      </span>
    </div>
  );
};

// We want to show the status as "In Draft" until the user has nothing more to do.
function portalStatus(referral: Referral): ReferralStatus {
  const status = referral.status;
  const confirmationByUser = referral.confirmationByUser;

  const isInDraft =
    status === "In Draft" || (status === "Submitted" && confirmationByUser);

  return isInDraft ? "In Draft" : referral.status;
}
