import React from "react";
import { Heading } from "@scandotcom/react";
import { Link } from "react-router-dom";
import { IconArrowRight } from "@tabler/icons";

interface Props {
  route: string;
  image: string;
  heading: string;
  description: string;
  linkLabel: string;
}

export const WelcomeCard = ({
  route,
  image,
  heading,
  description,
  linkLabel,
}: Props) => {
  return (
    <Link
      to={route}
      className="flex w-full flex-col overflow-hidden rounded-lg border border-cream-300 bg-white shadow-sm transition hover:shadow-lg hover:shadow-cypress-900/5 lg:h-44 lg:w-1/2 lg:flex-row"
    >
      <img
        src={image}
        className="aspect-video w-full object-cover object-top lg:aspect-square lg:h-full lg:w-fit"
      />
      <div className="w-full border-t border-cream-300 px-6 py-7 lg:border-l lg:border-t-0">
        <Heading variant="h3" size="lg" className="mb-2">
          {heading}
        </Heading>
        <p className="mb-4 text-sm leading-relaxed">{description}</p>
        <span className="inline-flex items-center gap-1.5 text-sm font-medium text-cypress-900 hover:underline">
          {linkLabel} <IconArrowRight className="h-4 w-4" strokeWidth={2} />
        </span>
      </div>
    </Link>
  );
};
