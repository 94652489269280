/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { InsurancePromoBanner } from "components/portal/InsurancePromoBanner";
import React from "react";
import { Outlet } from "react-router-dom";
import { Footer } from "./Footer";
import { HeaderMenu } from "./HeaderMenu";

function PortalLayout() {
  return (
    <div className="font-maison">
      <InsurancePromoBanner />
      <HeaderMenu />

      <main className="min-h-[calc(100vh-80px-104px)]">
        <Outlet />
      </main>
      <Footer />
    </div>
  );
}

export default PortalLayout;
