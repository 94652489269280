import { Referral } from "@services/scan/types/common";
import Routes from "@utils/Routes";
import React from "react";
import { formatDate } from "utils/dates";
import { isReferralEditable } from "utils/referralUtils";
import { Panel } from "../Panel";
import { DetailsEntry, DetailsTable } from "./DetailsTable";
import classNames from "classnames";

export interface ScanDetailsProps {
  referral: Referral;
  className?: string;
}

export const ScanDetails = ({ referral, className }: ScanDetailsProps) => {
  const editHref = isReferralEditable(referral)
    ? Routes.editScanDetails(referral.id)
    : null;

  const provider = referral.imagingProvider;

  return (
    <Panel title="Scan Details" editHref={editHref} className={className}>
      <DetailsTable>
        <DetailsEntry label="Reference">
          {referral.reference}
          {referral.internalReference && (
            <>
              {" "}
              <span className="font-normal text-cream-800">
                ({referral.internalReference})
              </span>
            </>
          )}
        </DetailsEntry>
        {provider && (
          <DetailsEntry label="Imaging centre" isTopAligned>
            <a
              href={provider.url}
              target="_blank"
              className="font-semibold text-cypress-500 hover:underline"
            >
              {provider.name}
            </a>
            {Boolean(provider.address) && (
              <div className="text-cream-800">{provider.address}</div>
            )}
            {Boolean(provider.town) && (
              <div className="text-cream-800">{provider.town}</div>
            )}
            {Boolean(provider.postcode) && (
              <div className="text-cream-800">{provider.postcode}</div>
            )}
          </DetailsEntry>
        )}

        {referral.bookingTime && (
          <DetailsEntry label="Appointment date">
            {formatDate(referral.confirmedBookingTime || referral.bookingTime)}{" "}
            <AppointmentStatus confirmed={!!referral.confirmedBookingTime} />
          </DetailsEntry>
        )}

        <DetailsEntry label="Modality">{referral.modality}</DetailsEntry>
        <DetailsEntry label="Body Parts">
          {referral.bodyParts?.map((bp) => bp.name).join("; ")}
        </DetailsEntry>

        {/* TODO: get link for safety questions. */}
        {/* <DetailsEntry label="Safety questions">
          <Link
            to={""}
            className="hover:underline text-cypress-500 font-semiBold"
          >
            View responses
          </Link>
        </DetailsEntry> */}
        <DetailsEntry label="Referral information" isTopAligned>
          <span className="whitespace-pre-line">
            {referral.referralInformation}
          </span>
        </DetailsEntry>
      </DetailsTable>
    </Panel>
  );
};

function AppointmentStatus({ confirmed = false }) {
  const styles = classNames(
    "ml-2 rounded-lg px-2 py-0.5 text-sm font-bold",
    confirmed
      ? "bg-success-700 text-success-50"
      : "bg-yellow-100 text-yellow-700"
  );
  return <span className={styles}>{confirmed ? "Confirmed" : "Pending"}</span>;
}
