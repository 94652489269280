import { client } from "@services/scan/client";

interface RawResponse {
  success: boolean;
}

interface ReadNotification {
  id?: string;
  readAll?: boolean;
}

export async function readNotification({
  id,
  readAll = false,
}: ReadNotification) {
  if (!id && !readAll) {
    return { success: false };
  }

  const url = id
    ? `/api/v1/notifications/${id}/read`
    : "/api/v1/notifications/read_all";

  const response = await client<RawResponse>({ method: "patch", url });

  const success = Boolean(response?.success);

  return { success };
}
