import { PageInfo } from "../types/common";
import { RawPageInfo } from "../types/requests";

export function prepPageInfo(info?: RawPageInfo): PageInfo {
  return {
    hasMore: info?.has_more ?? false,
    limit: info?.limit ?? 5,
    offset: info?.offset ?? 0,
    order: info?.order ?? "created_at",
    direction: info?.order_direction ?? "DESC",
    query: info?.search_query ?? "",
    total: info?.total_count ?? 0,
  };
}
