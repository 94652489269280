import { client } from "../../client";
import type { ScanInvoice } from "../../types/common";
import type { RawScanInvoice } from "../../types/requests";
import { prepInvoice } from "../../utils/prepInvoice";

interface RawResponse {
  object: RawScanInvoice;
}

export async function getScanInvoice(
  referralId?: string | null
): Promise<ScanInvoice | null> {
  if (!referralId) {
    throw new Error("no referral ID provided.");
  }

  const response = await client<RawResponse>({
    method: "get",
    url: `/api/v1/referrals/${referralId}/invoice`,
  });

  const report = prepInvoice(response?.object);

  return report;
}
