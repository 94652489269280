import * as React from "react";
import { H2, LoadingSpinner } from "@scandotcom/react";
import { IconChevronLeft, IconChevronRight } from "@tabler/icons";
import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/Option";
import * as A from "fp-ts/Array";
import * as NEA from "fp-ts/NonEmptyArray";
import { formatInTimeZone } from "date-fns-tz";
import { SlotSelector } from "./SlotSelector";
import { groupSlotsByWeek, groupSlotsByDay, TIME_ZONE } from "./processSlots";
import { startOfWeek } from "date-fns/esm";
import { wait } from "utils/wait";
import { Transition } from "@headlessui/react";
import { TimeSlot } from "@services/scan/types/common";

interface Props {
  slots: TimeSlot[];
  onPickSlot: (slot: TimeSlot) => void;
}
const transitionProps = {
  enter: "transform transition duration-100",
  enterFrom: "opacity-0 scale-95",
  enterTo: "opacity-100 scale-100",
};

export const SlotCalendar: React.FC<Props> = ({ onPickSlot, slots }) => {
  const [page, setPage] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const containerRef = React.useRef<HTMLDivElement | null>(null);
  const [containerStyle, setContainerStyle] = React.useState({});

  const chunkedSlots = React.useMemo(
    () => (A.isNonEmpty(slots) ? groupSlotsByWeek(slots) : []),
    [slots]
  );
  const hasPrevPage = page > 0;
  const hasNextPage = page < chunkedSlots.length - 1;

  const weekSlots = pipe(chunkedSlots, A.lookup(page));

  const changePage = async (inc: number) => {
    // simulate some fake loading to make the new
    // slots more obvious
    setLoading(true);
    await wait(250);
    setPage((p) => p + inc);
    setLoading(false);
  };

  const onNext = () => {
    if (hasNextPage) {
      changePage(1);
    }
  };
  const onPrev = () => {
    if (hasPrevPage) {
      changePage(-1);
    }
  };

  return (
    <div
      ref={containerRef}
      style={containerStyle}
      className="relative flex flex-col"
    >
      <div className="mb-6 flex justify-between gap-4">
        {pipe(
          weekSlots,
          O.map(([, slots]) => NEA.head(slots)),
          O.map((slot) => startOfWeek(slot.start, { weekStartsOn: 1 })),
          O.map((d) => formatInTimeZone(d, TIME_ZONE, "PPP")),
          O.map((weekCommencing) => <H2>Week commencing {weekCommencing}</H2>),
          O.toNullable
        )}

        <div className="flex justify-between gap-2">
          <div className="flex">
            <button
              type="button"
              onClick={onPrev}
              className="pr-1 disabled:text-neutral-400"
              disabled={!hasPrevPage}
            >
              <IconChevronLeft aria-hidden />
              <span className="sr-only">
                Load previous week of appointment slots
              </span>
            </button>
            <button
              type="button"
              onClick={onNext}
              className="pl-1 disabled:text-neutral-400"
              disabled={!hasNextPage}
            >
              <IconChevronRight aria-hidden />
              <span className="sr-only">
                Load next week of appointment slots
              </span>
            </button>
          </div>
        </div>
      </div>

      <Transition
        show={loading}
        enter="duration-[10ms]"
        enterFrom="opacity-90"
        enterTo="opacity-100"
        as="div"
        className=" py-6"
      >
        <LoadingSpinner />
      </Transition>

      <Transition
        show={!loading}
        as="div"
        {...transitionProps}
        beforeLeave={() => {
          const currentHeight = containerRef.current?.clientHeight;
          if (currentHeight) {
            setContainerStyle({ minHeight: currentHeight });
          }
        }}
        afterEnter={() => {
          setContainerStyle({});
        }}
      >
        {pipe(
          weekSlots,
          O.map(([, slots]) => groupSlotsByDay(slots)),
          O.fold(
            () => <p>There are no slots available</p>,
            (slots) => (
              <>
                <SlotSelector
                  slots={slots}
                  SlotLink={({ slot, children, className }) => {
                    const slotStart = slot.start;

                    return (
                      <button
                        data-test="time_slot"
                        type="button"
                        onClick={() => onPickSlot(slot)}
                        key={slotStart.toISOString()}
                        className={className}
                      >
                        {children}
                      </button>
                    );
                  }}
                />
              </>
            )
          )
        )}
      </Transition>
      <div className="mt-auto flex justify-between pt-6 text-lg">
        <button
          type="button"
          onClick={onPrev}
          className="flex items-center gap-2 font-maison-extended font-bold disabled:text-neutral-400"
          disabled={!hasPrevPage}
        >
          <IconChevronLeft aria-hidden /> Previous week
        </button>
        <button
          type="button"
          onClick={onNext}
          className="flex items-center gap-2 font-maison-extended font-bold disabled:text-neutral-400"
          disabled={!hasNextPage}
        >
          Next week <IconChevronRight aria-hidden />
        </button>
      </div>
    </div>
  );
};
