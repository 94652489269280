import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import axios from "axios";
import buildErrorsObject from "utils/buildErrorsObject";
import { fetchPartner } from "services/partnerApi";
import Nav from "components/terms_review/Nav";
import Errors from "components/terms_review/Errors";
import { LoadingSpinner } from "@scandotcom/react";
import { trackEvent } from "services/analytics";

function PageTermsheet({ partnerId, termsheetOptions }) {
  const [partnerData, setPartnerData] = useState({
    loading: true,
    partner: null,
  });
  const [termsheet, setTermsheet] = useState(null);
  const [attributesErrors, setAttributesErrors] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    fetchPartner(partnerId).then(({ partner }) => {
      if (partner?.partner_termsheet?.accepted_at) {
        navigate("/contract");
        return;
      }
      setPartnerData({ loading: false, partner });
      setTermsheet(partner.partner_termsheet);
    });
  }, []);

  if (partnerData.loading) {
    return <LoadingSpinner className="my-8" />;
  }

  const { partner } = partnerData;

  if (!partner) {
    return <div>Partner not found</div>;
  }

  if (!termsheet) {
    return <LoadingSpinner className="my-8" />;
  }

  const onChangeClinic = (event) => {
    const partnerclinicType = event.target.value;
    const otherSelected = partnerclinicType === "other";
    const partnerclinicTypeOther = otherSelected
      ? termsheet.partner_clinic_type_other
      : "";

    setTermsheet({
      ...termsheet,
      partner_clinic_type: partnerclinicType,
      partner_clinic_type_other: partnerclinicTypeOther,
    });
  };

  const onChangeService = (event) => {
    const partnerServiceProvided = event.target.value;
    const otherSelected = partnerServiceProvided === "other";
    const partnerServiceProvidedOther = otherSelected
      ? termsheet.partner_service_provided_other
      : "";

    setTermsheet({
      ...termsheet,
      partner_service_provided: partnerServiceProvided,
      partner_service_provided_other: partnerServiceProvidedOther,
    });
  };

  const setAttribute = (key, value) => {
    setTermsheet({
      ...termsheet,
      [key]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    axios
      .patch(`/api/v1/partners/${partnerId}/accept_termsheet`, {
        termsheet: {
          partner_name: termsheet.partner_name,
          contact_name: termsheet.contact_name,
          company_registration_number: termsheet.company_registration_number,
          company_address: termsheet.company_address,
          vat_registration_number: termsheet.vat_registration_number,
          contract_start_date: termsheet.contract_start_date,
          partner_service_provided: termsheet.partner_service_provided,
          partner_service_provided_other:
            termsheet.partner_service_provided_other,
          partner_clinic_type: termsheet.partner_clinic_type,
          partner_clinic_type_other: termsheet.partner_clinic_type_other,
          partner_bank_sort_code: termsheet.partner_bank_sort_code,
          partner_bank_account_number: termsheet.partner_bank_account_number,
        },
      })
      .then(() => {
        trackEvent("accept_termsheet");
        navigate("/contract");
      })
      .catch((error) => {
        if (error?.response?.data?.success === false) {
          const { validation_errors } = error.response.data;
          setAttributesErrors(buildErrorsObject(validation_errors));
        } else {
          // unexpected error
        }
      });
  };

  const servicesOptions = [
    { value: "", name: "" },
    ...termsheetOptions.partner_service_provided_options,
  ];

  const clinicTypeOptions = [
    { value: "", name: "" },
    ...termsheetOptions.partner_clinic_type_options,
  ];

  return (
    <form onSubmit={handleSubmit}>
      <div className="bg-gray-50 py-12">
        <div className="mx-auto max-w-5xl">
          <Nav
            step="termsheet"
            title="Review our Termsheet"
            subTitle="Step 1 - Check our terms for"
            partnerName={partner.name}
          />
          <div className="rounded-xl border border-gray-200 bg-white shadow-sm">
            <div className="w-full">
              <div className="flex w-full justify-between border-b border-gray-200 py-4 px-6">
                <h2 className="text-lg font-semibold text-gray-900">
                  Termsheet
                </h2>
              </div>
              <div className="px-6">
                <div className="flex w-full flex-col justify-between border-b border-gray-200 py-4 sm:flex-row">
                  <h2 className="text-gray-900">Company name</h2>
                  <div className="mt-4 flex w-full flex-col sm:mt-0 sm:w-1/3">
                    <input
                      data-test="partner_name"
                      className={` ${
                        attributesErrors.partner_name?.length
                          ? "border-red focus:border-red focus:ring-red "
                          : " border-gray-200 focus:border-primary-500 focus:ring-primary-500"
                      } font-sm w-full rounded border py-2 px-3 text-left leading-tight text-gray-900 shadow sm:text-right`}
                      id="partner_name"
                      type="text"
                      placeholder="Name of your company"
                      required
                      value={termsheet.partner_name || ""}
                      onChange={(e) =>
                        setAttribute("partner_name", e.target.value)
                      }
                    />
                    <Errors errors={attributesErrors.partner_name} />
                  </div>
                </div>
                <div className="flex w-full flex-col justify-between border-b border-gray-200 py-4 sm:flex-row">
                  <h2 className="text-gray-900">
                    Name of contact at your company
                  </h2>
                  <div className="mt-4 flex w-full flex-col sm:mt-0 sm:w-1/3">
                    <input
                      data-test="contact_name"
                      className={` ${
                        attributesErrors.contact_name?.length
                          ? "border-red focus:border-red focus:ring-red "
                          : " border-gray-200 focus:border-primary-500 focus:ring-primary-500"
                      } font-sm w-full rounded border py-2 px-3 text-left leading-tight text-gray-900 shadow sm:text-right`}
                      id="contact_name"
                      type="text"
                      placeholder="Your Lead Contact Name"
                      value={termsheet.contact_name || ""}
                      required
                      onChange={(e) =>
                        setAttribute("contact_name", e.target.value)
                      }
                    />
                    <Errors errors={attributesErrors.contact_name} />
                  </div>
                </div>
                <div className="flex w-full flex-col justify-between border-b border-gray-200 py-4 sm:flex-row">
                  <h2 className="text-gray-900">Start date</h2>
                  <div className="mt-4 flex w-full flex-col sm:mt-0 sm:w-1/3">
                    <input
                      data-test="contract_start_date"
                      className={` ${
                        attributesErrors.contract_start_date?.length
                          ? "border-red focus:border-red focus:ring-red "
                          : " border-gray-200 focus:border-primary-500 focus:ring-primary-500"
                      } font-sm w-full rounded border py-2 px-3 text-left leading-tight text-gray-900 shadow sm:text-right`}
                      id="contract_start_date"
                      type="date"
                      placeholder=""
                      value={termsheet.contract_start_date || ""}
                      onChange={(e) =>
                        setAttribute("contract_start_date", e.target.value)
                      }
                    />
                    <Errors errors={attributesErrors.contract_start_date} />
                  </div>
                </div>
                <div className="flex w-full flex-col justify-between border-b border-gray-200 py-4 sm:flex-row">
                  <h2 className="mb-2 text-gray-900">Initial term</h2>
                  <span className="text-gray-500">
                    {termsheet.initial_term}
                  </span>
                </div>{" "}
                <div className="flex w-full flex-col justify-between border-b border-gray-200 py-4 sm:flex-row">
                  <h2 className="mb-2 text-gray-900">Term</h2>
                  <span className="text-gray-500">{termsheet.term}</span>
                </div>{" "}
                <div className="flex w-full flex-col justify-between border-b border-gray-200 py-4 sm:flex-row">
                  <h2 className="mb-2 text-gray-900">Renewing</h2>
                  <span className="text-gray-500">{termsheet.renewing}</span>
                </div>
                <div className="flex w-full flex-col justify-between border-b border-gray-200 py-4 sm:flex-row">
                  <h2 className="mb-2 text-gray-900">
                    Renewal cancellation notice period
                  </h2>
                  <span className="text-gray-500">
                    {termsheet.cancellation_notice_period}
                  </span>
                </div>
                <div className="flex w-full flex-col justify-between border-b border-gray-200 py-4 sm:flex-row">
                  <h2 className="mb-2 text-gray-900">Territories</h2>
                  <span className="text-gray-500">{termsheet.territories}</span>
                </div>
                <div className="flex w-full flex-col justify-between border-b border-gray-200 py-4 sm:flex-row">
                  <h2 className="mb-2 text-gray-900">Exclusivity</h2>
                  <span className="text-gray-500">
                    {termsheet.exclusivity ? "Yes" : "None"}
                  </span>
                </div>
                <div className="flex w-full flex-col justify-between border-b border-gray-200 py-4 sm:flex-row">
                  <h2 className="text-gray-900">
                    Service provided by your company
                  </h2>
                  <div className="mt-4 flex w-full flex-col sm:mt-0 sm:w-1/3">
                    <select
                      data-test="partner_service_provided"
                      className={` ${
                        attributesErrors.partner_service_provided?.length
                          ? "border-red focus:border-red focus:ring-red "
                          : " border-gray-200 focus:border-primary-500 focus:ring-primary-500"
                      } font-sm w-full rounded border py-2 px-3 pr-8 text-left leading-tight text-gray-900 shadow sm:text-right`}
                      id="partner_service_provided"
                      value={termsheet.partner_service_provided || ""}
                      onChange={onChangeService}
                    >
                      {servicesOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.name}
                        </option>
                      ))}
                    </select>
                    <Errors
                      errors={attributesErrors.partner_service_provided}
                    />
                    {termsheet.partner_service_provided === "other" && (
                      <>
                        <input
                          data-test="partner_service_provided_other"
                          className={` ${
                            attributesErrors.partner_service_provided?.length
                              ? "border-red focus:border-red focus:ring-red "
                              : " border-gray-200 focus:border-primary-500 focus:ring-primary-500"
                          } font-sm mt-4 w-full rounded border py-2 px-3 text-left leading-tight text-gray-900 shadow sm:text-right`}
                          id="partner_service_provided_other"
                          type="text"
                          placeholder="Service provided by your company"
                          value={termsheet.partner_service_provided_other || ""}
                          onChange={(e) =>
                            setAttribute(
                              "partner_service_provided_other",
                              e.target.value
                            )
                          }
                        />
                        <Errors
                          errors={
                            attributesErrors.partner_service_provided_other
                          }
                        />
                      </>
                    )}
                  </div>
                </div>
                <div className="flex w-full flex-col justify-between border-b border-gray-200 py-4 sm:flex-row">
                  <h2 className="text-gray-900">Type of clinic</h2>
                  <div className="mt-4 flex w-full flex-col sm:mt-0 sm:w-1/3">
                    <select
                      data-test="partner_clinic_type"
                      className={` ${
                        attributesErrors.partner_clinic_type?.length
                          ? "border-red focus:border-red focus:ring-red "
                          : " border-gray-200 focus:border-primary-500 focus:ring-primary-500"
                      } font-sm w-full rounded border py-2 px-3 pr-8 text-left leading-tight text-gray-900 shadow sm:text-right`}
                      id="partner_clinic_type"
                      placeholder="Type of Clinic"
                      value={termsheet.partner_clinic_type || ""}
                      onChange={onChangeClinic}
                    >
                      {clinicTypeOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.name}
                        </option>
                      ))}
                    </select>
                    <Errors errors={attributesErrors.partner_clinic_type} />
                    {termsheet.partner_clinic_type === "other" && (
                      <>
                        <input
                          data-test="partner_clinic_type_other"
                          className={` ${
                            attributesErrors.partner_clinic_type?.length
                              ? "border-red focus:border-red focus:ring-red "
                              : " border-gray-200 focus:border-primary-500 focus:ring-primary-500"
                          } font-sm mt-4 w-full rounded border py-2 px-3 text-left leading-tight text-gray-900 shadow sm:text-right`}
                          id="partner_clinic_type_other"
                          type="text"
                          placeholder="Enter your clinic type"
                          value={termsheet.partner_clinic_type_other || ""}
                          onChange={(e) =>
                            setAttribute(
                              "partner_clinic_type_other",
                              e.target.value
                            )
                          }
                        />
                        <Errors
                          errors={attributesErrors.partner_clinic_type_other}
                        />
                      </>
                    )}
                  </div>
                </div>
                <div className="flex w-full flex-col justify-between border-b border-gray-200 py-4 sm:flex-row">
                  <h2 className="mb-2 text-gray-900">Referral fee</h2>
                  <span className="text-gray-500">
                    <div className="whitespace-pre-wrap">
                      {termsheet.referral_fee}
                    </div>
                  </span>
                </div>
                <div className="flex w-full flex-col justify-between border-b border-gray-200 py-4 sm:flex-row">
                  <h2 className="mb-2 text-gray-900">Payment schedule</h2>
                  <span className="text-gray-500">
                    {termsheet.payment_schedule}
                  </span>
                </div>
                <div className="flex w-full justify-between  border-gray-200 py-4">
                  <h2 className="mb-2 text-gray-900">Payment terms</h2>
                  <span className="text-gray-500">
                    {termsheet.payment_terms}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 flex w-full items-center justify-center">
            <div className="flex w-fit flex-col space-y-4">
              <div className="inline-flex rounded-md">
                <button
                  data-test="submit"
                  type="submit"
                  className="inline-flex w-full items-center justify-center rounded-md border-2 border-primary-600 bg-primary-600 px-5 py-3 text-base font-medium text-white hover:border-primary-700 hover:bg-primary-700"
                >
                  I am happy to continue with the terms
                </button>
              </div>
              <a
                href="https://calendly.com/toby-100/partnership-terms?month=2022-06"
                className="w-full"
                target="_blank"
                rel="noreferrer"
              >
                <button
                  type="button"
                  className="inline-flex w-full items-center justify-center rounded-md border-2 border-primary-700 px-5 py-3 text-base font-medium text-primary-700 hover:bg-primary-700 hover:text-white"
                >
                  I want to discuss the T&Cs
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

PageTermsheet.propTypes = {
  partnerId: PropTypes.string.isRequired,
  termsheetOptions: PropTypes.shape({
    partner_clinic_type_options: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.string,
      })
    ),
    partner_service_provided_options: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.string,
      })
    ),
  }).isRequired,
};

export default PageTermsheet;
