import { IconBulb, IconX } from "@tabler/icons";
import { currentUser } from "@utils/PortalContext";
import Routes from "@utils/Routes";
import React, { useEffect, useState } from "react";
import { ButtonLink } from "ui/ButtonLink";

export function InsurancePromoBanner() {
  const user = currentUser();
  const storageKey = `hide-updated-insurance-promo-for-${user?.id}`;
  const [isBannerHidden, setIsBannerHidden] = useState(true);

  function hideBanner() {
    localStorage.setItem(storageKey, "true");
    setIsBannerHidden(true);
  }

  useEffect(() => {
    if (!user?.id) return;

    const data = window.localStorage.getItem(storageKey);
    if (!data) setIsBannerHidden(false);
  }, [user?.id]);

  if (isBannerHidden) {
    return null;
  }

  return (
    <div className="relative w-full border-b border-neutral-300 bg-neutral-50 py-3">
      <div className="container items-center sm:flex">
        <IconBulb
          className="mr-4 hidden h-10 w-10 shrink-0 text-black sm:block"
          strokeWidth={1}
          aria-hidden
        />

        <div className="mb-3 mr-3 sm:mb-0">
          <p className="text-lg font-medium tracking-tight text-neutral-900">
            Referring insured patients?
          </p>
          <p className="text-base font-normal text-neutral-700">
            Post-scan receipts are provided to support claims. Please ask
            patients to confirm eligibility with their insurer before booking.
          </p>
        </div>

        <ButtonLink
          size="sm"
          to={Routes.newReferral}
          className="block w-full whitespace-nowrap !rounded-lg sm:ml-auto sm:w-auto sm:text-base sm:font-semibold"
          kind="cream"
        >
          Create a referral
        </ButtonLink>

        <button
          type="button"
          onClick={hideBanner}
          className="absolute right-1.5 top-1.5 p-2.5 text-neutral-500 hover:text-neutral-700 focus-visible:text-neutral-700 sm:static sm:ml-6"
        >
          <IconX className="h-5 w-5" />
          <span className="sr-only">close promo banner</span>
        </button>
      </div>
    </div>
  );
}
