import { client } from "../../client";
import { RawPaymentInfo } from "../../types/requests";
import { prepPaymentInfo } from "../../utils/prepPaymentInfo";

interface RawResponse {
  object: RawPaymentInfo;
}

export async function getPaymentInfo() {
  const response = await client<RawResponse>({
    method: "get",
    url: "/api/v1/payment_information",
  });

  const info = prepPaymentInfo(response?.object);

  return info;
}
