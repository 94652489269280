import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import axios from "axios";
import PubSub from "pubsub-js";
import { Button, H3, TextInput } from "@scandotcom/react";
import notifyError from "services/notifyError";
import buildErrorsObject from "@utils/buildErrorsObject";

interface FormInputs {
  first_name: string;
  last_name: string;
  phone: string;
  alternate_phone: string;
  email: string;
}

function PageProfile() {
  const navigate = useNavigate();
  const { first_name, last_name, phone, alternate_phone, email } =
    window.AppData.User;
  const [validationErrors, setValidationErrors] = React.useState<
    Record<string, string[]>
  >({});
  const { handleSubmit, register, setValue } = useForm<FormInputs>({
    defaultValues: { first_name, last_name, phone, alternate_phone, email },
  });

  const onSubmit: SubmitHandler<FormInputs> = (formInputs) => {
    setValidationErrors({});

    axios
      .patch(`/api/v1/profile`, formInputs)
      .then(({ data: { message } }) => {
        window.AppData.User = {
          first_name: formInputs.first_name,
          last_name: formInputs.last_name,
          phone: formInputs.phone,
          alternate_phone: formInputs.alternate_phone,
          email: formInputs.email,
          ...window.AppData.User,
        };
        PubSub.publish("USER_UPDATED");

        navigate("/", {
          state: {
            successMessage: ["Your profile has been updated.", message]
              .filter((item) => item)
              .join("\n"),
          },
        });
      })
      .catch((error) => {
        if (error?.response?.data?.success === false) {
          const { validation_errors } = error.response.data;
          setValidationErrors(buildErrorsObject(validation_errors));
        } else {
          notifyError(error, {
            description: "Error when updating user's profile",
          });
        }
      });
  };

  return (
    <>
      <div className="px-4 sm:px-4 md:px-8">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="m-auto lg:max-w-[794px]"
        >
          <div className="grid gap-6 border-b border-b-cream-300 pt-16 pb-10 md:grid-cols-5">
            <H3 className="col-span-2">Personal information</H3>
            <div className="col-span-3 grid gap-6 md:grid-cols-2 md:gap-4 [&_label]:!normal-case">
              <div>
                <TextInput
                  id="first_name"
                  label="First name"
                  type="text"
                  required={true}
                  {...register("first_name")}
                  errorMessage={validationErrors.first_name?.join(",")}
                />
              </div>
              <div>
                <TextInput
                  id="last_name"
                  label="Last name"
                  type="text"
                  required={true}
                  {...register("last_name")}
                  errorMessage={validationErrors.last_name?.join(",")}
                />
              </div>
            </div>
          </div>
          <div className="grid gap-6 pt-10 pb-16 md:grid-cols-5">
            <H3 className="col-span-2">Contact details</H3>
            <div className="col-span-3 grid gap-6 [&_label]:!normal-case">
              <div>
                <TextInput
                  id="email"
                  label="Email address"
                  type="email"
                  required={true}
                  {...register("email")}
                  errorMessage={validationErrors.email?.join(",")}
                />
              </div>
              <div>
                <TextInput
                  id="phone"
                  label="Phone number"
                  type="text"
                  required={true}
                  {...register("phone")}
                  errorMessage={validationErrors.phone?.join(",")}
                />
              </div>
              <div>
                <TextInput
                  id="alternate_phone"
                  label="Alternate number"
                  type="text"
                  required={true}
                  {...register("alternate_phone")}
                  errorMessage={validationErrors.alternate_phone?.join(",")}
                />
              </div>
            </div>
          </div>

          {!!validationErrors.base?.length && (
            <div className="md:grid md:grid-cols-3">
              <div className="col-span-2 col-start-2 py-4 text-red">
                <h4 className="font-bold">
                  Some errors occurred in your submission:
                </h4>
                <ul className="text-sm">
                  {validationErrors.base.map((error) => (
                    <li key={error}>{error}</li>
                  ))}
                </ul>
              </div>
            </div>
          )}

          <div className="grid grid-cols-1 justify-end gap-6 md:flex">
            <Button data-test="submit" type="submit" className="md:order-2">
              Save
            </Button>
          </div>
        </form>
      </div>
    </>
  );
}

export default PageProfile;
