import React from "react";
import { round } from "utils/numbers";

interface ProviderPriceProps {
  price: number;
  className?: string;
}

export const ProviderPrice = ({ price, className }: ProviderPriceProps) => {
  return (
    <div className={className}>
      <div className="mb-1 font-maison-extended text-xl font-bold text-cypress-900">
        £{round(price / 100, 0)}
      </div>
      <div className="min-w-max text-xs font-normal text-gray-600">
        Total cost
      </div>
    </div>
  );
};
