import type { Patient } from "@services/scan/types/common";
import type { RawPatient } from "@services/scan/types/requests";
import { client } from "../../client";
import { prepPatient } from "../../utils/prepPatients";

interface RawResponse {
  object: RawPatient;
}

export async function getPatient(
  patientId?: string | null
): Promise<Patient | null> {
  if (!patientId) {
    return null;
  }
  const response = await client<RawResponse>({
    method: "get",
    url: `/api/v1/patients/${patientId}`,
  });

  const patient = prepPatient(response?.object);

  return patient;
}
