import { IconChevronDown } from "@tabler/icons";
import classNames from "classnames";
import React, { CSSProperties, useRef } from "react";

interface SortByProps {
  order?: string;
  direction?: "ASC" | "DESC";
  onChange: (value: string) => void;
  label: string;
  options: { value: string; label: string }[];
  className?: string;
}
export const SortBy = ({
  onChange,
  label,
  order,
  direction,
  options,
  className,
}: SortByProps) => {
  const sortByRef = useRef<HTMLSelectElement>(null);
  const helperElmRef = useRef<HTMLSpanElement>(null);

  const dynamicWidth = (helperElmRef.current?.offsetWidth || 110) + 10;

  const styles = {
    "--dynamic-width": `${dynamicWidth}px`,
  } as CSSProperties;

  const wrapperStyles = classNames(
    "relative flex items-center text-xs font-medium text-cypress-800",
    className
  );

  return (
    <label className={wrapperStyles}>
      {label}
      <select
        value={`${order}-${direction}`.toLowerCase()}
        name="sort by"
        id="sort_by"
        className="ml-0.5 w-[var(--dynamic-width)] appearance-none rounded-md border border-transparent bg-transparent text-center font-semibold focus:border-pink-500 focus:outline-none focus:ring focus:ring-pink-100"
        ref={sortByRef}
        style={styles}
        onChange={(e) => {
          const helperElm = helperElmRef.current;

          if (helperElm) {
            helperElm.innerText =
              e.target?.querySelector("option:checked")?.innerHTML || "all";
          }

          onChange(e.currentTarget.value);
        }}
      >
        {options.map(({ value, label }) => (
          <option value={value} key={label}>
            {label}
          </option>
        ))}
      </select>
      <span
        ref={helperElmRef}
        className="absolute top-0 -left-[999999px]"
        aria-hidden
      />
      <IconChevronDown className="h-4 w-4" aria-hidden />
    </label>
  );
};
