import axios from "axios";

// Reload the page when a new version of the code is released.
// Or if the user is logged out.
export default function pageReloader(
  { mustBeSignedIn } = { mustBeSignedIn: false }
) {
  axios.get("/version").then(({ data }) => {
    const { version, user_signed_in } = data;
    if (version && AppData.AppVersion && version !== AppData.AppVersion) {
      console.log("Reloading page to get new version");
      window.location.reload();
    }

    if (mustBeSignedIn && !user_signed_in) {
      console.log("Reloading page because user is logged out");
      window.location.reload();
    }
  });
}
