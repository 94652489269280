function decodeJwtToken(token: string) {
  if (!token) return { error: "No JWT Token" };

  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (err) {
    return { error: `${err}` };
  }
}

export default decodeJwtToken;
