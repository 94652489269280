import { useQuery } from "react-query";
import { type Specialist, type RawSpecialist, client } from "@services/scan";

interface RawResponse {
  data: RawSpecialist[];
  errors: string[];
}

export function useSpecialists(specialistType?: string, area?: string) {
  const query = useQuery(
    ["specialists", specialistType, area],
    () => getSpecialists(specialistType, area),
    {
      enabled: Boolean(specialistType) && Boolean(area),
      staleTime: 1000 * 60 * 5,
    }
  );

  return query;
}

async function getSpecialists(
  specialistType?: string,
  postcode?: string
): Promise<Specialist[] | undefined> {
  if (!specialistType || !postcode) {
    return;
  }

  const response = await client<RawResponse>({
    method: "get",
    url: "/api/v1/specialists",
    params: {
      postcode,
      specialist_type: specialistType,
    },
  });

  if (!response) {
    return;
  }

  return response.data.map(
    (specialist): Specialist => ({
      id: specialist.id,
      title: specialist.title,
      firstName: specialist.first_name,
      lastName: specialist.last_name,
      practiceName: specialist.practice_name,
      email: specialist.email,
      specialistType: specialist.specialist_type,
      address: specialist.address,
      latitude: specialist.latitude,
      longitude: specialist.longitude,
      distance: specialist.distance,
    })
  );
}
