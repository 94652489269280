import { Referral } from "@services/scan/types/common";

export const demoReferral: Referral = {
  type: "Referral",
  id: "7bb2ab60-2020-4fa4-a41e-dd45ad8b5428",
  createdAt: "2023-01-27T10:16:30.210+00:00",
  updatedAt: "2023-01-27T10:22:08.832+00:00",
  modality: "CT",
  referralInformation:
    "Suspected rotator cuff injury, potential tear to supraspinatus.",
  reference: "R2",
  submittedAt: "2023-01-27T10:18:14.437+00:00",
  draftedAt: null,
  completedStep: 1,
  patientEmailSentAt: null,
  cancelledAt: null,
  paidAt: null,
  receivedAt: null,
  completedAt: null,
  onHoldAt: null,
  patientReportAddedAt: null,
  patientConfirmedAt: null,
  cancellationReason: null,
  bookingTime: null,
  confirmedBookingTime: null,
  scanBookedAt: null,
  confirmationByUser: false,
  scanImagesRequestedAt: null,
  internalReference: "",
  patientDeletedAt: null,
  approvedScanImagesCount: 0,
  status: "Submitted",
  reportStatus: null,
  bodyParts: [{ id: 28, name: "Shoulder - Right" }],
  referralDocument: null,
  referralForm: { url: "", filename: "R2-Referral-Form.pdf" },
  scanReport: null,
  patientReport: null,
  availableScanReportsCount: 0,
  adminNotes: null,
  patient: {
    fullName: "John Doe",
    id: "fcfee502-66a7-4b69-a8e4-02e626f21078",
    firstName: "John",
    lastName: "Doe",
    email: "johnDoe@mail.com",
    title: "",
    phone: "01277674889",
    alternatePhone: "01632960000",
    dateOfBirth: "21/05/1988",
    gender: "male",
    gpDetails: "High Street Medical Centre, 1 High Street, SW1P 3PA",
    address: {
      line1: "Main lane 1",
      line2: "Line 2",
      city: "London",
      postcode: "SW1A 1AA",
    },
  },
  safetyQuestions: {
    previous_surgery: false,
    previous_surgery_details: null,
    previous_imaging: false,
    previous_imaging_details: null,
    allergies: false,
    allergies_details: null,
    claustrophobia: null,
    epilepsy: null,
    brain_operation: null,
    spine_operation: null,
    past_six_weeks_operation: null,
    metal_injuries: null,
    medicine_patch: null,
    fits_or_blackouts: null,
    pacemaker: null,
    brain_aneurysm_clip: null,
    programmable_hydrocephalus_shunt: null,
    cochlear_implant: null,
    overweight: null,
    asthma: false,
    high_blood_pressure: false,
    xray_contrast_injection: false,
    diabetic: false,
    metformin: false,
    kidney_problems: false,
    kidney_function: false,
    hearing_aid: false,
    body_piercings: false,
    barium: false,
    over_6_foot_4_inches: null,
    overweight_details: null,
    over_6_foot_4_inches_details: null,
    pregnant: null,
  },
  imagingProvider: {
    id: 156,
    name: "European Scanning Centre (Marylebone)",
    address: "10-11 Bulstrode Place",
    latitude: 51.5182377,
    longitude: -0.1505898,
    distance: 123,
    postcode: "W1U 2HX",
    town: "London",
    tier: null,
    minAge: 17,
    // email: "nationalmri@alliance.co.uk",
    openScanner: false,
    threeT: false,
    slug: "european-scanning-centre-marylebone",
    url: "https://uk.scan.com/locations/european-scanning-centre-marylebone",
  },
};
