import { endOfDay, format, intlFormatDistance, parse } from "date-fns";
import { enGB } from "date-fns/locale";

const ISO_DATE_FORMAT = "yyyy-MM-dd";
const UK_DATE_FORMAT = "dd/MM/yyyy";

export function isoToUkDate(isoDate?: string) {
  if (!isoDate) {
    return;
  }
  return convertDate(isoDate, ISO_DATE_FORMAT, UK_DATE_FORMAT);
}

export function ukToIsoDate(ukDate?: string) {
  if (!ukDate) {
    return;
  }

  if (ukDate.match(/\d{4}-\d{2}-\d{2}/)) {
    return ukDate;
  }

  return convertDate(ukDate, UK_DATE_FORMAT, ISO_DATE_FORMAT);
}

export function convertDate(
  date: string,
  fromFormat: string,
  toFormat: string
) {
  if (!date) {
    return;
  }

  const fromDate = parse(date, fromFormat, endOfDay(new Date()));

  return formatDate(fromDate, toFormat);
}

export function formatDate(
  rawDate?: string | Date | null,
  formatter = "dd MMM, yyyy 'at' paaa"
) {
  if (!rawDate) {
    return;
  }

  try {
    const date = typeof rawDate === "string" ? new Date(rawDate) : rawDate;

    return format(date, formatter, { locale: enGB });
  } catch {
    return "";
  }
}

/**
 * Function to format the date into a relative format.
 *
 * e.g 2 weeks ago.
 *
 * @param rawDate The date to be formatted.
 * @returns The date in a relative format.
 */
export function formatRelativeDate(rawDate?: string | Date | null) {
  if (!rawDate) {
    return;
  }

  const date = typeof rawDate === "string" ? new Date(rawDate) : rawDate;

  const formattedDate = intlFormatDistance(date, Date.now());

  return formattedDate;
}
