import React, { useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import notifyError from "services/notifyError";

function PageConfirmUser({ referralId, referralReference }) {
  const [email, setEmail] = useState("");
  const [validationError, setValidationError] = useState();

  const handleSubmit = (event) => {
    event.preventDefault();

    axios
      .patch(`/api/v1/referrals/${referralId}/confirm_user`, { email })
      .then((response) => {
        const { redirect } = response.data;
        window.location.href = redirect;
      })
      .catch((error) => {
        if (error?.response?.data?.success === false) {
          const { validation_errors } = error.response.data;
          console.log(validation_errors);
          setValidationError(validation_errors.join(", "));
        } else {
          notifyError(error, {
            description: "Error when confirming user in order to view referral",
            referralId,
            referralReference,
          });
          setValidationError("An unexpected error occured");
        }
      });
  };

  const showValidationError = () => (
    <div className="mt-2 flex items-baseline justify-end text-xs text-danger-500">
      <svg
        width="12"
        height="11"
        viewBox="0 0 12 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.5593 9.49996L6.88584 0.574961C6.80114 0.413331 6.67383 0.277958 6.51769 0.183506C6.36156 0.0890539 6.18256 0.0391235 6.00009 0.0391235C5.81761 0.0391235 5.63861 0.0890539 5.48248 0.183506C5.32635 0.277958 5.19904 0.413331 5.11434 0.574961L0.440337 9.49996C0.360497 9.65238 0.321339 9.82283 0.326648 9.99482C0.331958 10.1668 0.381555 10.3345 0.470645 10.4817C0.559734 10.6289 0.685301 10.7507 0.835203 10.8351C0.985104 10.9196 1.15427 10.964 1.32634 10.964H10.6733C10.8454 10.964 11.0146 10.9196 11.1645 10.8351C11.3144 10.7507 11.4399 10.6289 11.529 10.4817C11.6181 10.3345 11.6677 10.1668 11.673 9.99482C11.6783 9.82283 11.6392 9.65238 11.5593 9.49996ZM5.49984 3.71146C5.49984 3.57885 5.55252 3.45168 5.64628 3.35791C5.74005 3.26414 5.86723 3.21146 5.99984 3.21146C6.13244 3.21146 6.25962 3.26414 6.35339 3.35791C6.44716 3.45168 6.49984 3.57885 6.49984 3.71146V6.71146C6.49984 6.84407 6.44716 6.97125 6.35339 7.06501C6.25962 7.15878 6.13244 7.21146 5.99984 7.21146C5.86723 7.21146 5.74005 7.15878 5.64628 7.06501C5.55252 6.97125 5.49984 6.84407 5.49984 6.71146V3.71146ZM6.02484 9.46646H6.01084C5.81366 9.46583 5.62436 9.38899 5.48253 9.252C5.3407 9.11502 5.25732 8.9285 5.24984 8.73146C5.24623 8.63346 5.26219 8.53573 5.29679 8.44398C5.33139 8.35222 5.38392 8.26828 5.45133 8.19705C5.51873 8.12583 5.59966 8.06876 5.68937 8.02916C5.77909 7.98957 5.87579 7.96826 5.97384 7.96646H5.98784C6.18498 7.9667 6.37439 8.04318 6.51642 8.1799C6.65845 8.31661 6.74209 8.50297 6.74984 8.69996C6.75371 8.79818 6.73792 8.89618 6.70338 8.98821C6.66884 9.08024 6.61625 9.16443 6.5487 9.23584C6.48116 9.30725 6.40002 9.36444 6.31006 9.40405C6.22009 9.44365 6.12312 9.46487 6.02484 9.46646Z"
          fill="#FB3640"
        />
      </svg>
      <span className="ml-1">{validationError}</span>
    </div>
  );

  return (
    <div className="flex min-h-full flex-col items-center bg-white">
      <div className="mt-8 mb-8 w-36 md:mt-16 md:w-44">
        <img src={AppData.ScanLogoBlackUrl} alt="Scan.com" />
      </div>
      <div className="mb-8 overflow-hidden md:rounded-lg md:border md:p-8 md:shadow">
        <p className="mb-4 text-lg font-bold text-gray-900 md:mb-8">
          Confirm your email address before viewing referral {referralReference}
        </p>
        <form onSubmit={handleSubmit}>
          <div className="mt-8">
            <label
              htmlFor="email"
              className="block w-36 text-sm font-medium text-gray-600 md:w-72"
            >
              Email address
            </label>
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              required
              placeholder="Enter your email address"
              name="email"
              id="email"
              autoComplete="email"
              className="mt-2 block h-11 w-full rounded-lg border p-3.5 focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
            />
          </div>
          {!!validationError && showValidationError()}
          <div className="mt-8">
            <button
              className="inline-flex h-11 w-28 items-center justify-center rounded-md border border-primary-600 bg-primary-600 px-5 py-2 text-sm font-medium text-white transition-colors hover:border-primary-700 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
              type="submit"
            >
              Confirm
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

PageConfirmUser.propTypes = {
  referralId: PropTypes.string.isRequired,
  referralReference: PropTypes.string.isRequired,
};

export default PageConfirmUser;
