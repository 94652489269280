import notifyError from "services/notifyError";
import axios from "axios";

async function fetchPartner(partnerId) {
  try {
    const response = await axios.request({
      method: "get",
      url: `/api/v1/partners/${partnerId}`,
    });
    return response.data;
  } catch (error) {
    const status = error?.response?.status;
    if (status === 404) {
      console.log("Partner not found");
    } else {
      notifyError(`Partner API error ${status}`, { response: error?.response });
    }
    return { partner: null };
  }
}

export { fetchPartner }; // eslint-disable-line import/prefer-default-export
