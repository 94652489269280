import React from "react";
import PropTypes from "prop-types";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";
interface SuccessMessageProps {
  message?: string | null;
  className?: string;
}

const SuccessMessage = ({ message, className }: SuccessMessageProps) => {
  if (!message) return <></>;

  const wrapperStyles = classNames(
    "mt-6 flex flex-row gap-x-3 rounded-md bg-success-50 p-4",
    "text-success-700 text-sm font-medium",
    className
  );

  return (
    <div className={wrapperStyles}>
      <CheckCircleIcon
        className="h-5 w-5 flex-shrink-0 text-success-400"
        aria-hidden="true"
      />
      <p className="whitespace-pre-wrap">{message}</p>
    </div>
  );
};

export default SuccessMessage;
