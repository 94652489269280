import { getTimeSlots, setTimeSlots } from "@services/scan";
import { TimeSlot } from "@services/scan/types/common";
import Routes from "@utils/Routes";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router";
import { useMutateReferral } from "./useMutateReferral";

export function useBookingProvider(referralId?: string) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [showSlots, setShowSlots] = useState(false);
  const [provider, setProvider] = useState<number | null>(null);

  const referral = useMutateReferral();
  const timeSlots = useMutation(setTimeSlots, {
    onSuccess: (data) => {
      if (!data.success || !data.referral) {
        return;
      }
      queryClient.setQueryData(["referral", referralId], data.referral);
      completeStep();
    },
  });

  const slots = useQuery(
    ["slots", { provider }],
    () => getTimeSlots(referralId),
    {
      enabled: Boolean(referralId && provider),
      onSuccess: (data) => {
        if (data.timeSlots.length === 0) {
          completeStep();
          return;
        }
        setShowSlots(true);
      },
    }
  );

  async function selectProvider(id: number) {
    if (!referralId) return;

    referral.mutate(
      { referralId, imaging_provider_id: id },
      { onSuccess: () => setProvider(id) }
    );
  }

  function setSlot(slotIds: TimeSlot["ids"]) {
    if (!referralId) return;

    timeSlots.mutate({ referralId, slotIds });
  }

  function completeStep() {
    if (!referralId) return;

    referral.mutate({ referralId, completed_step: 3 });

    navigate(Routes.safetyQuestions(referralId), {
      state: { referral },
    });
  }

  function closeSlots() {
    setShowSlots(false);
    setProvider(null);
  }

  return {
    isLoading: referral.isLoading || slots.isLoading,
    slots: slots.data?.timeSlots ?? [],
    selectProvider,
    showSlots,
    closeSlots,
    setSlot,
  };
}
