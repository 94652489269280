import * as React from "react";
import AppMenu from "./AppMenu";
import { NotificationsContent } from "components/portal/Notifications/NotificationsContent";
import { NotificationsButton } from "./NotificationsButton";
import { NotificationItem } from "@services/scan/types/common";

interface Props {
  all: NotificationItem[];
  read: NotificationItem[];
  unread: NotificationItem[];
}

export const NotificationsMenu: React.FC<Props> = ({ all, read, unread }) => {
  return (
    <AppMenu
      placement="bottom-end"
      menuButton={<NotificationsButton amount={unread.length} />}
    >
      {({ close }) => (
        <NotificationsContent
          close={close}
          all={all}
          read={read}
          unread={unread}
        />
      )}
    </AppMenu>
  );
};
