import { client } from "../../client";
import type { RawScanDocument, RawValidationError } from "../../types/requests";
import { prepScanDocument } from "../../utils/prepScanDocuments";
import { prepValidationErrors } from "../../utils/prepValidationErrors";

interface UploadReferralDocument {
  signal?: AbortSignal;
  referralId: string;
  file: File;
  onUploadProgress: (event: ProgressEvent) => void;
}

interface RawResponse {
  cancelled: boolean;
  success: boolean;
  validation_errors: RawValidationError[];
  object: RawScanDocument;
}

export async function uploadReferralDocument({
  file,
  signal,
  referralId,
  onUploadProgress,
}: UploadReferralDocument) {
  const formData = new FormData();
  formData.append("file", file);

  const response = await client<RawResponse>({
    signal,
    method: "POST",
    data: formData,
    onUploadProgress,
    url: `/api/v1/referrals/${referralId}/additional_documents`,
  });

  const success = Boolean(response?.success);
  const cancelled = Boolean(response?.cancelled);
  const document = prepScanDocument(response?.object);
  const errors = prepValidationErrors(response?.validation_errors);

  return { errors, document, success, cancelled };
}
