import { prepReferrals } from "@services/scan/utils/prepReferrals";
import { client } from "../../client";
import type {
  RawPageInfo,
  RawPatient,
  RawReferral,
  RawSpecialistConsultation,
} from "../../types/requests";
import { prepPageInfo } from "../../utils/prepPageInfo";

export interface GetReferralsProps {
  order?: "updated_at" | "modality";
  direction?: "ASC" | "DESC";
  offset?: number;
  limit?: number;
  query?: string;
  patientId?: string | null;
  statuses?: string[] | null;
}

interface RawResponse {
  data: (RawReferral | RawSpecialistConsultation)[];
  page_info: RawPageInfo;
}

export async function getReferrals({
  order = "updated_at",
  direction = "DESC",
  statuses,
  offset = 0,
  limit = 50,
  query = "",
  patientId,
}: GetReferralsProps) {
  const response = await client<RawResponse>({
    method: "get",
    url: "/api/v1/referral_summary",
    params: {
      order,
      order_direction: direction,
      offset,
      limit,
      search_query: query,
      patient_id: patientId,
      statuses,
    },
  });

  const pageInfo = prepPageInfo(response?.page_info);
  const referrals = prepReferrals(response?.data);

  return { pageInfo, referrals };
}
