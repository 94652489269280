import React from "react";
import { Navigate, Outlet, useNavigate, useParams } from "react-router-dom";
import { useSpecialistConsultation } from "hooks/useSpecialistConsultation";
import Routes from "@utils/Routes";
import { LoadingSpinner } from "@scandotcom/react";

export default function SpecialistConsultationEditable() {
  const { specialistConsultationId } = useParams();

  if (!specialistConsultationId) {
    return <Navigate to={Routes.referrals} />;
  }

  const { data: specialistConsultation, isLoading } = useSpecialistConsultation(
    specialistConsultationId
  );

  if (!specialistConsultation || isLoading)
    return <LoadingSpinner className="my-8" />;

  if (specialistConsultation.status === "Referred") {
    return (
      <Navigate to={Routes.specialistConsultation(specialistConsultationId)} />
    );
  }

  return <Outlet />;
}
