import { getScanImages } from "@services/scan";
import { useState } from "react";
import { useQuery } from "react-query";

export function useScanImages(id?: string, reference?: string) {
  const [showModal, setShowModal] = useState(false);

  const [referral, setReferral] = useState<{
    reference?: string;
    id?: string;
  } | null>({ id, reference });

  const { isLoading, data } = useQuery(
    ["scan images", { referralId: referral?.id }],
    () => getScanImages(referral?.id),
    {
      enabled: Boolean(referral?.id),
      onSuccess: () => {
        if (data?.length === 1) {
          window.open(data[0].url);
          return;
        }

        setShowModal(true);
      },
    }
  );

  function show(id: string, reference?: string) {
    setReferral({ id, reference });
  }

  function close() {
    setShowModal(false);
    setReferral(null);
  }

  return {
    referral,
    data,
    show,
    isLoading,
    showModal,
    close,
  };
}
