import * as React from "react";

export const SvgWrapper = ({
  className = "",
  width = "24",
  height = width || "24",
  viewBox = `0 0 ${width} ${height}`,
  ...rest
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...rest}
    />
  );
};
