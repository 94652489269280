import React from "react";

function Guide() {
  return (
    <div className="border-b border-cream-400 bg-cream-200">
      <div className="mx-auto max-w-5xl rounded-lg px-6 py-12 md:py-16">
        <h1 className="font-maison-extended text-2xl font-bold md:text-3xl">
          Guide to scan modalities & referrals
        </h1>
        <div className="pt-4 text-sm md:pt-6 md:text-base">
          <p className="mb-4">
            Welcome to Scan.com&#39;s market-leading scan referral portal for
            clinicians and therapists. In a few easy steps, you can create a
            referral, and provide your patients with direct access to diagnostic
            MRI, ultrasound and CT scans at over 150 imaging centres across the
            UK.
          </p>
          <p>
            In this guide, we&#39;ll cover the modalities we offer, share
            details of our clinical due diligence processes, and help you decide
            which scans to select for your patients.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Guide;
