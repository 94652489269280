import { ValidationError } from "../types/common";
import { RawValidationError } from "../types/requests";

export function prepValidationError(
  error: RawValidationError
): ValidationError {
  return {
    attribute: error.attribute,
    message: error.message,
    fullMessage: error.full_message,
  };
}

export function prepValidationErrors(
  rawErrors?: RawValidationError[]
): ValidationError[] {
  const errors = rawErrors
    ?.map(prepValidationError)
    .filter((err): err is ValidationError => Boolean(err));

  return errors || [];
}
