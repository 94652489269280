import { History, Transition } from "history";
import { useCallback, useContext, useEffect, useRef } from "react";
import { Navigator } from "react-router";
import { UNSAFE_NavigationContext as NavigationContext } from "react-router-dom";

type ExtendNavigator = Navigator & Pick<History, "block">;
export function useRouterBlocker(
  blocker: (tx: Transition) => void,
  condition = true
) {
  const { navigator } = useContext(NavigationContext);

  useEffect(() => {
    if (!condition) return;

    const unblock = (navigator as ExtendNavigator).block((tx) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock();
          tx.retry();
        },
      };

      blocker(autoUnblockingTx);
    });

    return unblock;
  }, [navigator, blocker, condition]);
}

export default function usePrompt(message: string, condition = true) {
  const unblockRef = useRef(false);

  const blocker = useCallback(
    (tx: Transition) => {
      if (unblockRef.current || window.confirm(message)) {
        unblockRef.current = false;
        tx.retry();
      }
    },
    [message]
  );

  useRouterBlocker(blocker, condition);

  return () => (unblockRef.current = true);
}
