import { getReferrals, GetReferralsProps } from "@services/scan";
import { debounce } from "@utils/debounce";
import { useCallback, useEffect, useState } from "react";
import { useQuery } from "react-query";

const STATUS_PREFS_KEY = "statusFilters";

interface UseReferralListProps {
  limit?: number;
  order?: GetReferralsProps["order"];
  patientId?: string | null;
}

export function useReferralList({
  limit = 10,
  order = "updated_at",
  patientId,
}: UseReferralListProps) {
  const statusFilterPrefs = getStatusFilterPrefs();

  const [pageNumber, setPageNumber] = useState(1);
  const [query, setQuery] = useState("");
  const [orderBy, setOrderBy] = useState(order);
  const [direction, setDirection] = useState<"ASC" | "DESC">("DESC");
  const [statuses, setStatuses] = useState<string[]>(statusFilterPrefs);

  const offset = Math.max(pageNumber * limit - limit, 0);

  const { isLoading, data, isFetching } = useQuery(
    ["referrals", { offset, orderBy, direction, query, patientId, statuses }],
    () =>
      getReferrals({
        order: orderBy,
        direction,
        offset,
        limit,
        query,
        patientId,
        statuses,
      }),
    { keepPreviousData: true }
  );

  function setSearch(term: string) {
    setQuery(term);
    setPageNumber(1);
  }

  function setPage(page: number) {
    setPageNumber(page);
  }

  function toggleStatusFilter(status: string) {
    setStatuses((oldStatuses) => {
      const statusExists = oldStatuses.includes(status);

      const newStatuses = statusExists
        ? oldStatuses.filter((s) => s !== status)
        : [...oldStatuses, status];

      setStatusFilterPrefs(newStatuses);

      return newStatuses;
    });

    setPage(1);
  }

  function getStatusFilterPrefs(): string[] {
    const statusPrefs = localStorage.getItem(STATUS_PREFS_KEY) || "[]";
    return JSON.parse(statusPrefs);
  }

  function setStatusFilterPrefs(prefs: string[]) {
    const statusPrefs = JSON.stringify(prefs) || "[]";
    localStorage.setItem(STATUS_PREFS_KEY, statusPrefs);
  }

  function setOrder(by: UseReferralListProps["order"] = "updated_at") {
    const newDirection =
      orderBy === by && direction === "DESC" ? "ASC" : "DESC";

    setOrderBy(by);
    setDirection(newDirection);
    setPageNumber(1);
  }

  const referrals = data?.referrals ?? [];
  const pageInfo = data?.pageInfo;
  const hasNoInitialResults =
    !isLoading &&
    query === "" &&
    pageInfo?.total === 0 &&
    statuses.length === 0;

  const debounceSetSearch = useCallback(debounce(setSearch, 300), []);

  return {
    isLoading,
    isFetching,
    data: referrals,
    pageInfo,
    hasNoInitialResults,
    page: pageNumber,
    setOrder,
    setSearch: debounceSetSearch,
    statusFilters: statuses,
    toggleStatusFilter,
    setPage,
  };
}
