import type { NotificationItem } from "../types/common";
import type { RawNotificationItem } from "../types/requests";

export function prepNotification(
  notification?: RawNotificationItem
): NotificationItem | null {
  if (!notification) {
    return null;
  }

  return {
    id: notification.id,
    title: notification.title,
    message: notification.message,
    readAt: notification.read_at,
    createdAt: notification.created_at,
    name: notification.patient_full_name,
    referralId: notification.referral_id,
    referralReference: notification.referral_reference,
  };
}

export function prepNotifications(
  rawNotifications?: RawNotificationItem[]
): NotificationItem[] {
  const notifications = rawNotifications
    ?.map(prepNotification)
    .filter((notification): notification is NotificationItem =>
      Boolean(notification)
    );

  return notifications || [];
}
