import { Tab } from "@headlessui/react";
import { H4 } from "@scandotcom/react";
import classNames from "classnames";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { readNotification } from "@services/scan";
import type { NotificationItem } from "@services/scan/types/common";
import Routes from "@utils/Routes";
import { useMutation, useQueryClient } from "react-query";
import { Notification } from "./Notification";

interface Props {
  close: () => void;
  all: NotificationItem[];
  read: NotificationItem[];
  unread: NotificationItem[];
}

const tabClassName = (selected: boolean) =>
  classNames("rounded-lg px-[10px] py-1 font-medium", {
    "bg-cream-100 text-cypress-900": selected,
  });

function Notifications({ close, all, read, unread }: Props) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [selectedTab, setSelectedTab] = useState(0);

  const { mutate } = useMutation(readNotification, {
    onSuccess: () => queryClient.invalidateQueries("notifications"),
  });

  const clickNotification = (item: NotificationItem) => {
    navigate(Routes.referral(item.referralId));
    mutate({ id: item.id });
    close();
  };

  const isReadAllButtonShown = Boolean(unread.length) && selectedTab < 2;

  const createItemProps = (item: NotificationItem) => ({
    key: item.id,
    item,
    onClick: () => clickNotification(item),
  });

  return (
    <div className="flex w-full flex-col">
      <Tab.Group selectedIndex={selectedTab} onChange={setSelectedTab}>
        <Tab.List className="flex px-6 pb-3 pt-1 text-xs text-cream-800 sm:pt-6">
          <H4 size="base" className="hidden grow sm:block">
            Notifications
          </H4>
          <Tab className={tabClassName(selectedTab === 0)}>All</Tab>
          <Tab className={tabClassName(selectedTab === 1)}>Unread</Tab>
          <Tab className={tabClassName(selectedTab === 2)}>Read</Tab>
        </Tab.List>
        <Tab.Panels className="h-screen max-h-[calc(100vh-98px)] grow overflow-y-auto sm:max-h-[334px]">
          <Tab.Panel className="px-4">
            {all.map((item) => (
              <Notification {...createItemProps(item)} />
            ))}
          </Tab.Panel>
          <Tab.Panel
            className={classNames("px-4", {
              "flex h-full items-center justify-center": unread.length === 0,
            })}
          >
            {unread.length === 0 ? (
              <span>No unread notifications.</span>
            ) : (
              unread.map((item) => <Notification {...createItemProps(item)} />)
            )}
          </Tab.Panel>
          <Tab.Panel className="px-4">
            {read.map((item) => (
              <Notification {...createItemProps(item)} />
            ))}
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>

      {isReadAllButtonShown && (
        <button
          className="absolute bottom-4 left-1/2 max-w-fit -translate-x-1/2 rounded-full border border-cream-300 bg-white px-4 py-2 text-xs font-medium leading-5 text-cypress-900 shadow-lg hover:bg-cream-200 "
          onClick={() => mutate({ readAll: true })}
        >
          Mark all as read
        </button>
      )}
    </div>
  );
}

export default Notifications;
