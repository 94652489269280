import classNames from "classnames";
import * as React from "react";
import { Link } from "react-router-dom";

type Props = React.ComponentProps<typeof Link>;

export const TextLink: React.FC<Props> = ({ className, ...props }) => {
  return (
    <Link
      className={classNames(
        "font-semibold text-cypress-500 hover:underline",
        className
      )}
      {...props}
    />
  );
};
