import { IconChevronRight } from "@tabler/icons";
import classNames from "classnames";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";

export interface BreadcrumbItem {
  name: string;
  href: string;
}

export interface BreadcrumbProps {
  history: BreadcrumbItem[];
  className?: string;
}

const BreadcrumbSeparator = () => (
  <IconChevronRight
    strokeWidth={1.5}
    className="w-4 text-cream-600"
    aria-hidden
  />
);

export const Breadcrumb = ({ className, history }: BreadcrumbProps) => {
  const wrapperStyles = classNames(
    "text-sm font-medium w-fit text-cypress-900",
    className
  );

  return (
    <nav aria-label="Breadcrumb" className={wrapperStyles}>
      <ol className="inline-flex flex-wrap items-center gap-1.5">
        {history.map(({ name, href }, index) => {
          const isLast = history.length === index + 1;
          const ariaCurrent = isLast ? "page" : undefined;
          return (
            <Fragment key={href + "crumb"}>
              <li className="last:font-normal last:text-cream-800">
                <Link
                  to={href}
                  className="hover:underline"
                  aria-current={ariaCurrent}
                >
                  {name}
                </Link>
              </li>
              {!isLast && <BreadcrumbSeparator />}
            </Fragment>
          );
        })}
      </ol>
    </nav>
  );
};
