import Routes from "@utils/Routes";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { getSpecialistConsultation } from "@services/scan";

export function useSpecialistConsultation(id: string) {
  const navigate = useNavigate();

  const query = useQuery(
    ["specialistConsultation", id],
    () => getSpecialistConsultation(id),
    {
      enabled: Boolean(id),
      staleTime: 1000 * 60 * 5,
      onSuccess: (data) => {
        if (!data) return navigate(Routes.home);
      },
    }
  );

  return query;
}
