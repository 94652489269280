import { prepReferral } from "@services/scan/utils/prepReferrals";
import { client } from "../../client";
import type { RawReferral, RawValidationError } from "../../types/requests";
import { prepValidationErrors } from "../../utils/prepValidationErrors";

interface CancelReferralProps {
  referralId: string;
  reason: string;
}

interface RawResponse {
  success: boolean;
  object: RawReferral;
  validation_errors: RawValidationError[];
}

export async function cancelReferral({
  referralId,
  reason,
}: CancelReferralProps) {
  const response = await client<RawResponse>({
    method: "patch",
    url: `/api/v1/referrals/${referralId}/cancel`,
    data: { cancellation_reason: reason },
  });

  const success = Boolean(response?.success);
  const referral = prepReferral(response?.object);
  const errors = prepValidationErrors(response?.validation_errors);

  return { success, errors, referral };
}
