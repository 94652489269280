import classNames from "classnames";
import React, { ReactNode } from "react";

interface FormGroupProps {
  name: string;
  description?: ReactNode;
  children?: ReactNode;
  className?: string;
}

export const FormGroup = ({
  name,
  description,
  children,
  className,
}: FormGroupProps) => {
  return (
    <div className="flex flex-col gap-x-14 md:flex-row">
      <div className="mb-6 w-full shrink-0 sm:w-[288px]">
        <h3 className="mb-4 font-maison-extended text-xl font-bold">{name}</h3>
        {description && (
          <p className="text-sm font-normal text-cream-800">{description}</p>
        )}
      </div>
      <div className={classNames("w-full space-y-6", className)}>
        {children}
      </div>
    </div>
  );
};

export const GroupSeparator = () => (
  <hr className="my-6 w-full border-cream-300 sm:my-10" />
);
