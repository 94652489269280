import type { Specialist } from "../types/common";
import type { RawSpecialist } from "../types/requests";

export function prepSpecialist(
  specialist?: RawSpecialist | null
): Specialist | null {
  if (!specialist) {
    return null;
  }

  return {
    id: specialist.id,
    title: specialist.title,
    firstName: specialist.first_name,
    lastName: specialist.last_name,
    specialistType: specialist.specialist_type,
    practiceName: specialist.practice_name,
    address: specialist.address,
    email: specialist.email,
    latitude: specialist.latitude,
    longitude: specialist.longitude,
    distance: specialist.distance,
  };
}
