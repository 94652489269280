import { IconMapPin } from "@tabler/icons";
import classNames from "classnames";
import React from "react";
import { round } from "@utils/numbers";

interface AddressProps {
  /**
   * The distance to the address in miles.
   */
  distance?: number;
  address: string;
  className?: string;
}

export const Address = ({
  address,
  distance,
  className = "sm:items-center items-start",
}: AddressProps) => {
  const addressStyles = classNames(
    "flex gap-x-2 text-sm font-medium",
    className
  );

  return (
    <span className={addressStyles}>
      <IconMapPin className="h-5 w-5 shrink-0" strokeWidth={1.5} aria-hidden />

      {distance && (
        <>
          <div>{round(distance, 2)}mi</div>
          <div className="text-xl leading-none text-cream-400">&middot;</div>
        </>
      )}

      <address className="not-italic">{address}</address>
    </span>
  );
};
