import { H1 } from "@scandotcom/react";
import { IconCheck } from "@tabler/icons";
import { Modal } from "components/common/Modal";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ButtonLink } from "ui/ButtonLink";
import Routes from "utils/Routes";
import ShowSpecialistConsultation from "../../components/common/ShowSpecialistConsultation";

export default function PageSpecialistConsultationReferred() {
  const { specialistConsultationId } = useParams() as {
    specialistConsultationId: string;
  };

  const navigate = useNavigate();
  const referralHref = Routes.specialistConsultation(specialistConsultationId);
  const onClose = () => navigate(referralHref);

  return (
    <div>
      <ShowSpecialistConsultation />

      <Modal onClose={onClose} isOpen className="max-w-lg p-10">
        <Modal.Close onClick={onClose} className="-mt-6 -mr-6" />

        <div className="mb-6 grid h-14 w-14 place-items-center rounded-full bg-pink-50 text-pink-500">
          <IconCheck className="h-7 w-7" />
        </div>

        <H1 size="2xl" className="mb-4">
          Specialist Consultation Referral Created
        </H1>

        <p className="mb-6 text-base text-cypress-900">
          Your specialist consultation referral has been successfully created.
        </p>

        <ButtonLink to={referralHref} kind="secondary" className="block w-fit">
          View referral
        </ButtonLink>
      </Modal>
    </div>
  );
}
