import type { ScanImage } from "../types/common";
import type { RawScanImage } from "../types/requests";

export function prepScanImage(image?: RawScanImage): ScanImage | null {
  if (!image) {
    return null;
  }

  return {
    id: image.id,
    url: image.url,
    expiresAt: image.expires_at,
    description: image.description,
  };
}

export function prepScanImages(rawImages?: RawScanImage[]): ScanImage[] {
  const images = rawImages
    ?.map(prepScanImage)
    .filter((image): image is ScanImage => Boolean(image));

  return images || [];
}
