import { UploadInput } from "@scandotcom/react";
import { deleteReferralDocument } from "@services/scan";
import React from "react";
import { useMutation } from "react-query";
import { UploadedDocument } from "./UploadedDocument";
import { UploadingFile } from "./UploadingFile";

export interface Doc {
  id: string;
  name: string;
}

interface Props {
  referralId: string;
  existingDocuments: Doc[];
}

interface State {
  uploadedDocuments: Doc[];
  uploadingFiles: File[];
}

type Action =
  | { type: "AddFiles"; files: File[] }
  | { type: "RemoveUploadedDocument"; id: string }
  | { type: "DocumentUploaded"; doc: Doc; file: File }
  | { type: "RemoveUploadingFile"; file: File };

const reducer: React.Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case "AddFiles":
      return {
        ...state,
        uploadingFiles: [...state.uploadingFiles, ...action.files],
      };
    case "RemoveUploadedDocument":
      return {
        ...state,
        uploadedDocuments: state.uploadedDocuments.filter(
          (doc) => doc.id !== action.id
        ),
      };
    case "DocumentUploaded":
      return {
        uploadedDocuments: [...[action.doc], ...state.uploadedDocuments],
        uploadingFiles: state.uploadingFiles.filter(
          (item) => item !== action.file
        ),
      };
    case "RemoveUploadingFile":
      return {
        ...state,
        uploadingFiles: state.uploadingFiles.filter(
          (item) => item !== action.file
        ),
      };
    default:
      return state;
  }
};

export const UploadAdditionalDocuments: React.FC<Props> = ({
  referralId,
  existingDocuments,
}) => {
  const mutation = useMutation(deleteReferralDocument);

  const [state, dispatch] = React.useReducer(reducer, {
    uploadedDocuments: [...existingDocuments],
    uploadingFiles: [],
  });

  return (
    <div className="space-y-4">
      <UploadInput
        label="Upload document"
        onChange={(e) => {
          const files = Array.from(e.target.files || []);
          if (files.length > 0) {
            dispatch({ type: "AddFiles", files });
          }
        }}
        multiple
      />

      {state.uploadingFiles.map((file) => (
        <UploadingFile
          key={file.name}
          referralId={referralId}
          file={file}
          documentUploaded={(doc) =>
            dispatch({ type: "DocumentUploaded", doc, file })
          }
          removeFile={() => dispatch({ type: "RemoveUploadingFile", file })}
        />
      ))}

      {state.uploadedDocuments.map(({ id, name }) => (
        <UploadedDocument
          key={id}
          name={name}
          removeDocument={() => {
            dispatch({ type: "RemoveUploadedDocument", id });
            mutation.mutate({ referralId, documentId: id });
          }}
        />
      ))}
    </div>
  );
};
