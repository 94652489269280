import notifyError from "@services/notifyError";
import { client } from "../../client";
import type {
  RawReferral,
  RawValidationError,
  ReferralMutationFields,
} from "../../types/requests";
import { prepReferral } from "../../utils/prepReferrals";
import { prepValidationErrors } from "../../utils/prepValidationErrors";

type CreateReferralProps = Partial<Omit<ReferralMutationFields, "referralId">>;

interface RawResponse {
  object: RawReferral;
  success: boolean;
  validation_errors: RawValidationError[];
}

export async function createReferral(fields: CreateReferralProps) {
  const response = await client<RawResponse>({
    method: "post",
    url: "/api/v1/referrals",
    data: {
      draft: true,
      origin: "portal",
      ...fields,
    },
  });

  const success = Boolean(response?.success);
  const errors = prepValidationErrors(response?.validation_errors);
  const referral = prepReferral(response?.object);

  if (!success) {
    notifyError("Error creating referral", { errors });
  }

  return { referral, success, errors };
}
