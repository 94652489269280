import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import pageReloader from "utils/pageReloader";

import TermsReviewLayout from "layouts/TermsReviewLayout";
import PageWelcome from "pages/terms_review/PageWelcome";
import PageTermsheet from "pages/terms_review/PageTermsheet";
import PageContract from "pages/terms_review/PageContract";

export default function TermsReviewApp({ partnerId, termsheetOptionsJson }) {
  const termsheetOptions = JSON.parse(termsheetOptionsJson);
  const location = useLocation();

  useEffect(() => {
    // Reload the page if a new version of the code is released.
    pageReloader();
  }, [location]);

  return (
    <Routes>
      <Route path="/" element={<TermsReviewLayout />}>
        <Route index element={<PageWelcome partnerId={partnerId} />} />
        <Route
          path="/termsheet"
          element={
            <PageTermsheet
              partnerId={partnerId}
              termsheetOptions={termsheetOptions}
            />
          }
        />
        <Route
          path="/contract"
          element={<PageContract partnerId={partnerId} />}
        />
      </Route>
    </Routes>
  );
}

TermsReviewApp.propTypes = {
  partnerId: PropTypes.string.isRequired,
  termsheetOptionsJson: PropTypes.string.isRequired,
};
