import type { ImagingProvider } from "../types/common";
import type { RawImagingProvider } from "../types/requests";

export function prepImagingProvider(
  provider?: RawImagingProvider | null
): ImagingProvider | null {
  if (!provider) {
    return null;
  }

  return {
    id: provider.id,
    slug: provider.slug ?? "",
    url: provider.url ?? "",
    name: provider.name,
    address: provider.address,
    email: provider.email,
    distance: provider.distance,
    latitude: provider.latitude,
    longitude: provider.longitude,
    minAge: provider.min_age,
    openScanner: Boolean(provider.open_scanner),
    offNetwork: Boolean(provider.off_network),
    threeT: Boolean(provider.three_t),
    postcode: provider.postcode,
    price: provider.price,
    town: provider.town,
    tier: provider.tier,
  };
}

export function prepImagingProviders(
  rawProvider?: RawImagingProvider[]
): ImagingProvider[] {
  const providers = rawProvider
    ?.map(prepImagingProvider)
    .filter((provider): provider is ImagingProvider => Boolean(provider));

  return providers || [];
}
