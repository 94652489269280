import * as React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { currentUser } from "utils/PortalContext";

function WithEditPaymentPermission() {
  const userData = currentUser();

  const hasPermissions = Boolean(userData?.canEditPaymentInfo);

  return hasPermissions ? <Outlet /> : <Navigate to="/account" />;
}

export default WithEditPaymentPermission;
