import { client } from "../../client";
import type { RawImageRequest } from "../../types/requests";
import { prepImageRequests } from "../../utils/prepImageRequests";

interface RawResponse {
  data: RawImageRequest[];
}

export async function getImageRequests(referralId?: string | null) {
  if (!referralId) {
    throw new Error("No referral ID provided.");
  }

  const response = await client<RawResponse>({
    method: "get",
    url: `/api/v1/referrals/${referralId}/scan_image_requests`,
  });

  const requests = prepImageRequests(response?.data);

  return requests;
}
