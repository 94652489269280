import notifyError from "@services/notifyError";
import { ImagingProvider } from "@services/scan/types/common";
import classNames from "classnames";
import React, { useEffect, useMemo, useRef, useState } from "react";
import MapBox, { MapRef, NavigationControl } from "react-map-gl";
import { MapMarker } from "./MapMarker";
import { ProviderPopup } from "./ProviderPopup";
import mapBounds from "@utils/mapBounds";

interface MapBoxProps {
  className?: string;
  providers: ImagingProvider[];
  onSelectProvider?: (provider: ImagingProvider) => void;
}

const initialState = { latitude: 51.5072, longitude: 0.1276, zoom: 9 };
const mapStyle = "mapbox://styles/scandotcom/cl8wv327p004x15n2506gaeis";
const token = window.AppData.MapboxAccessToken;

if (!token) {
  notifyError("MapboxAccessToken missing");
}

export const Map = ({
  className,
  providers = [],
  onSelectProvider,
}: MapBoxProps) => {
  const mapRef = useRef<MapRef>(null);
  const [selectedProvider, setSelectedProvider] =
    useState<ImagingProvider | null>(null);

  const bounds = useMemo(() => mapBounds(providers), [providers]);

  const fitToBounds = () => {
    mapRef.current?.resize();
    mapRef?.current?.fitBounds(bounds, {
      padding: 40,
      maxZoom: providers.length ? 16 : 10,
    });
  };

  const wrapeprStyles = classNames(
    "border-cream-300 overflow-hidden rounded-xl border h-[600px] sm:min-h-[61rem] sm:h-full w-full",
    className
  );

  function onMarkerClick(provider: ImagingProvider) {
    setSelectedProvider(provider);
    mapRef?.current?.panTo([provider.longitude, provider.latitude]);
  }

  function onClosePopup() {
    setSelectedProvider(null);
    fitToBounds();
  }

  useEffect(() => {
    fitToBounds();
  }, [bounds]);

  return (
    <div className={wrapeprStyles}>
      <MapBox
        onLoad={fitToBounds}
        mapboxAccessToken={token}
        ref={mapRef}
        attributionControl={false}
        initialViewState={initialState}
        style={{ width: "100%", height: "100%" }}
        mapStyle={mapStyle}
      >
        <NavigationControl position="top-right" showCompass={false} />
        {providers.map((provider) => (
          <MapMarker
            key={provider.id}
            provider={provider}
            onClick={() => onMarkerClick(provider)}
          />
        ))}

        {selectedProvider && (
          <ProviderPopup
            provider={selectedProvider}
            onClose={onClosePopup}
            onSelect={
              onSelectProvider
                ? () => onSelectProvider(selectedProvider)
                : undefined
            }
          />
        )}
      </MapBox>
    </div>
  );
};
