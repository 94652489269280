import { client } from "../../client";
import type {
  PatientMutationFields,
  RawPatient,
  RawValidationError,
} from "../../types/requests";
import { prepPatient } from "../../utils/prepPatients";
import { prepValidationErrors } from "../../utils/prepValidationErrors";

interface RawResponse {
  object?: RawPatient;
  success: boolean;
  validation_errors: RawValidationError[];
}

type CreatePatientFields = Partial<Omit<PatientMutationFields, "patientId">>;

export async function createPatient(data: CreatePatientFields) {
  const response = await client<RawResponse>({
    data,
    method: "POST",
    url: "/api/v1/patients",
  });

  const success = Boolean(response?.success);
  const patient = prepPatient(response?.object);
  const errors = prepValidationErrors(response?.validation_errors);

  return { success, patient, errors };
}
