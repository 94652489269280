import {
  Button,
  Checkbox,
  ErrorMessage,
  MultiSelect,
  SelectInput,
  TextArea,
  TextInput,
} from "@scandotcom/react";
import {
  ImagingProvider,
  Modality,
  Referral,
  ScanDocument,
  ValidationError,
} from "@services/scan/types/common";
import { PortalContext } from "@utils/PortalContext";
import Routes from "@utils/Routes";
import { getFullAddress } from "@utils/referralUtils";
import { FormGroup, GroupSeparator } from "components/common/FormGroup";
import { UploadAdditionalDocuments } from "components/common/UploadAdditionalDocuments";
import { useBodyParts } from "hooks/useBodyParts";
import { useImagingProviders } from "hooks/useImagingProviders";
import React, {
  BaseSyntheticEvent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Controller, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { usePreventPageLeave } from "utils/usePreventPageLeave";
import EstimatedPrice from "./EstimatedPrice";
import { CancelReferralModal } from "./Modals/CancelReferralModal";

export interface ScanDetailsFields {
  id?: string;
  body_part_ids: number[];
  modality: string;
  referral_information: string;
  internal_reference: string;
  marketing_enabled: boolean;
}

interface Props {
  referral: Referral;
  modalities: Modality[];
  onSave: (referral: ScanDetailsFields) => void;
  onDraft?: (referral: ScanDetailsFields) => void;
  additionalDocuments?: ScanDocument[];
  validationErrors?: ValidationError[];
  showConsentCheckboxes?: boolean;
  submitLabel?: string;
}

function isDraftSubmit(e?: BaseSyntheticEvent<HTMLFormElement, SubmitEvent>) {
  return e?.nativeEvent?.submitter?.dataset["type"] === "draft";
}

function ScanDetailsForm({
  referral,
  modalities,
  onSave,
  onDraft,
  additionalDocuments = [],
  validationErrors = [],
  showConsentCheckboxes = false,
  submitLabel = "Submit Referral",
}: Props) {
  const { currentUser } = useContext(PortalContext);

  const {
    handleSubmit,
    watch,
    register,
    formState: { errors, isDirty, isSubmitted },
    control,
    setValue,
    setError,
    getValues,
    reset,
  } = useForm<ScanDetailsFields>({
    defaultValues: {
      id: referral?.id,
      marketing_enabled: Boolean(currentUser?.marketingEnabled),
      body_part_ids: referral.bodyParts?.map(({ id }) => id) ?? [],
      referral_information: referral.referralInformation ?? undefined,
      internal_reference: referral.internalReference ?? undefined,
      modality: referral.modality ?? undefined,
    },
  });

  const selectedModality = watch("modality");
  const selectedBodyParts = watch("body_part_ids");

  const { bodyParts } = useBodyParts({ modalityId: selectedModality });
  const providers = useImagingProviders({
    enabled: ({ bodyPartIds, modality }) =>
      !!bodyPartIds?.length && !!modality && !referral.confirmationByUser,
    maxResults: 3,
    minResults: 3,
    area: getFullAddress(referral?.patient.address),
    modality: selectedModality,
    bodyPartIds: selectedBodyParts.map((value) => Number(value)),
  });

  useEffect(() => {
    providers.setFilters({
      modality: selectedModality,
      bodyPartIds: selectedBodyParts.map((value) => Number(value)),
    });
  }, [selectedModality, selectedBodyParts]);

  const providerPrices = useMemo(
    () => getProviderPrices(providers.data),
    [providers.data]
  );

  const [openedCancelModal, setOpenedCancelModal] = useState(false);

  const unblockNavigation = usePreventPageLeave(
    "Currently you'd lose anything entered",
    isDirty && !isSubmitted
  );

  const selectedModalitySettings = useMemo(
    () => modalities?.find(({ label }) => label === selectedModality),
    [selectedModality]
  );

  const bodyPartSelectionMessage = `Select up to ${
    selectedModalitySettings?.maxBodyParts ?? 4
  } body parts`;

  useEffect(() => {
    // use validation errors returned from the API and show them in the form
    validationErrors.forEach((error, i) => {
      if (error.attribute)
        setError(
          error.attribute as keyof ScanDetailsFields,
          { type: "validate", message: error.fullMessage },
          { shouldFocus: i === 0 }
        );
    });
  }, [validationErrors]);

  const onSubmit = (e) => {
    e.preventDefault();

    if (onDraft && isDraftSubmit(e)) {
      unblockNavigation();
      return onDraft(getValues());
    }

    handleSubmit(onSave)(e);
  };

  const hasPatientAddress = Boolean(
    referral?.patient?.address?.line1 || referral?.patient?.address?.postcode
  );

  /**
   * TODO: Remove xray pricing fix when sufficient changes are added to scan-api.
   * https://app.shortcut.com/scancom/story/2711/b2c-patient-can-order-more-than-one-x-ray-part
   */
  const maxSelection =
    currentUser?.partnerPricing === "direct_to_customer" &&
    selectedModality === "X-Ray"
      ? 1
      : selectedModalitySettings?.maxBodyParts;

  return (
    <div>
      <CancelReferralModal
        isOpen={openedCancelModal}
        onClose={() => setOpenedCancelModal(false)}
        id={referral.id}
        reference={referral.reference}
      />
      <form onSubmit={onSubmit} className="mx-auto mt-12 w-full max-w-[796px]">
        <FormGroup
          name="Scan"
          description={
            <>
              Not sure which scan to choose? Read{" "}
              <Link to={Routes.guide} className="underline">
                our scan guide
              </Link>{" "}
              to learn more.
            </>
          }
        >
          <SelectInput
            label="Scan type"
            placeholder="Select a scan type"
            {...register("modality", { required: "Select a modality" })}
            options={modalities.map(({ label }) => ({ label, value: label }))}
            errorMessage={errors.modality?.message}
          />

          <div data-test="select_body_parts_container">
            <Controller
              name="body_part_ids"
              control={control}
              rules={{
                required: bodyPartSelectionMessage,
                validate: {
                  atLeastOne: (ids) =>
                    ids?.length === 0 ? bodyPartSelectionMessage : undefined,
                  atMostMaxBodyParts: (ids) =>
                    ids?.length > (selectedModalitySettings?.maxBodyParts ?? 0)
                      ? bodyPartSelectionMessage
                      : undefined,
                },
              }}
              render={({ field: { onChange, value } }) => {
                return (
                  <MultiSelect
                    label="Body parts"
                    placeholder="Select a body part"
                    collection="body parts"
                    selected={value}
                    setSelected={onChange}
                    items={bodyParts}
                    predicate={selectedModality ? undefined : "scan type"}
                    hasError={Boolean(errors.body_part_ids?.message)}
                    maxSelection={maxSelection}
                    maxSelectionMessage={`${selectedModalitySettings?.label} scans only allow up to ${maxSelection} body part(s)`}
                  />
                );
              }}
            />
            {errors.body_part_ids?.message ? (
              <ErrorMessage className="mt-1">
                {errors.body_part_ids?.message}
              </ErrorMessage>
            ) : null}

            <div className="mt-2 text-sm text-neutral-500">
              {selectedModality && (
                <p>
                  You can select up to {maxSelection} body part(s) for{" "}
                  {selectedModality} scans.
                </p>
              )}
            </div>
          </div>

          {currentUser?.paymentEnabled && (
            <EstimatedPrice
              prices={providerPrices}
              loading={providers.isLoading}
              isDefaultLocation={!hasPatientAddress}
            />
          )}
        </FormGroup>
        <GroupSeparator />
        <FormGroup
          name="Referral information"
          description={
            !referral?.confirmationByUser
              ? "The patient will fill out the rest of their referral information, such as safety questions and also choosing a scanning centre."
              : undefined
          }
        >
          <TextArea
            label="Reason for scan"
            placeholder="Please provide as much clinical information as possible that is relevant to the scan."
            rows={5}
            {...register("referral_information", {
              required: "Enter some referral information",
            })}
            errorMessage={errors.referral_information?.message}
          />

          <TextInput
            label="Internal reference"
            hintText="If you would like to add your own internal reference code to this referral, please add it here."
            {...register("internal_reference")}
            errorMessage={errors.internal_reference?.message}
            isOptional
          />
        </FormGroup>

        <GroupSeparator />

        <FormGroup
          name="Documents"
          description=" These aren't required for your referral, but any previous imaging or
           other relevant documentation can be helpful to the clinician when
           assessing the referral."
        >
          <UploadAdditionalDocuments
            referralId={referral.id}
            existingDocuments={additionalDocuments}
          />
        </FormGroup>

        {showConsentCheckboxes ? (
          <>
            <Checkbox
              required
              data-test="t_and_c"
              name="t_and_c"
              label={
                <>
                  I have read the{" "}
                  <a
                    href="https://nationalmriscan.com/docs/terms-and-conditions.pdf"
                    target="_blank"
                    className="text-primary-600 hover:text-primary-500"
                    rel="noreferrer"
                  >
                    terms and conditions
                  </a>
                </>
              }
            />
            <Checkbox
              label="I consent to Scan.com contacting me about future enquiries about the referral"
              data-test="marketing_enabled"
              {...register("marketing_enabled")}
            />
          </>
        ) : null}
        <div className="mt-16 flex flex-wrap justify-end gap-4">
          {onDraft && (
            <Button
              data-type="draft"
              data-test="submit-draft"
              type="submit"
              kind="secondary"
              className="w-full sm:w-fit md:order-2"
            >
              Save draft
            </Button>
          )}
          <Button
            data-test="submit"
            type="submit"
            className="w-full sm:w-fit md:order-2"
          >
            {submitLabel}
          </Button>
        </div>

        {!!validationErrors.length && (
          <>
            <h4 className="font-bold text-red">
              Some errors occurred in your submission:
            </h4>
            <ul className="text-sm text-red">
              {validationErrors.map((error) => (
                <li key={error.attribute}>{error.fullMessage}</li>
              ))}
            </ul>
          </>
        )}
      </form>
    </div>
  );
}

function getProviderPrices(providers?: ImagingProvider[]) {
  if (!providers?.length) return [];

  const possiblePrices = providers
    ?.filter(({ price }) => Number.isFinite(price))
    .map(({ price }) => price! / 100);

  if (!possiblePrices.length) return [];

  const minPrice = Math.min(...possiblePrices);
  const maxPrice = Math.max(...possiblePrices);

  return minPrice === maxPrice ? [minPrice] : [minPrice, maxPrice];
}

export default ScanDetailsForm;
