import React, { HTMLAttributes } from "react";
import classNames from "classnames";
import { IconBell } from "@tabler/icons";

interface Props extends HTMLAttributes<HTMLDivElement> {
  amount: number;
}

export const NotificationsButton = ({ amount, className, ...props }: Props) => {
  const buttonStyles = classNames(
    "flex items-center justify-center cursor-pointer rounded-lg p-2 sm:p-2.5 relative",
    "hover:bg-cypress-900/70 focus:bg-cypress-900/70 focus:outline-none"
  );

  const hasNotifications = amount > 0;

  return (
    <div {...props} className={buttonStyles}>
      <span className="sr-only">View notifications</span>

      <IconBell
        strokeWidth={1.5}
        className="h-6 w-6 text-cream-300"
        aria-hidden
      />

      {hasNotifications && (
        <span className="z-100 absolute top-1 right-1 grid h-4 w-4 place-items-center rounded-full bg-pink-500 text-[8px] font-medium text-white sm:top-1.5 sm:right-1.5">
          {amount}
        </span>
      )}
    </div>
  );
};
