import { Menu, Transition } from "@headlessui/react";
import { CogIcon } from "@heroicons/react/24/outline";
import {
  IconBulb,
  IconInfoCircle,
  IconMessages,
  IconSettings,
  IconUser,
} from "@tabler/icons";
import classNames from "classnames";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import openLiveChat, { emailSupportHref } from "utils/openLiveChat";
import Routes from "utils/Routes";

interface Props {
  showPaymentInfoLink: boolean;
  className?: string;
}

const UserMenuToggle = () => {
  const toggleStyles = classNames(
    "focus:outline-none hover:bg-cypress-900/70 focus:bg-cypress-900/70",
    "flex max-w-xs items-center rounded-lg p-2 sm:p-2.5 shrink-0"
  );

  return (
    <Menu.Button className={toggleStyles}>
      <span className="sr-only">Open user menu</span>
      <CogIcon className="h-6 w-6" aria-hidden />
    </Menu.Button>
  );
};

const Divider = () => <hr className="my-2 w-full bg-cream-300" />;

export const UserMenu = ({ showPaymentInfoLink, className }: Props) => {
  const wrapperStyes = classNames(
    "relative text-sm font-maison font-medium",
    className
  );
  const menuStyles = classNames(
    "absolute origin-top-right focus:outline-none border",
    "bg-white text-cypress-900 shadow-lg border-cream-300",
    "right-0 mt-2 w-[250px] rounded-xl py-2 z-[100]"
  );

  const iconProps = {
    className: "w-5 h-5 m-1.5 text-cypress-800",
    "aria-hidden": true,
    strokeWidth: 1.5,
  };

  return (
    <Menu as="div" className={wrapperStyes}>
      <UserMenuToggle />
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className={menuStyles}>
          <Menu.Item>
            {({ active }) => (
              <Link
                to={Routes.profile}
                className={getLinkStyles(active)}
                aria-current={getAriaCurrent(active)}
              >
                <IconUser {...iconProps} />
                Profile
              </Link>
            )}
          </Menu.Item>

          {showPaymentInfoLink && (
            <Menu.Item>
              {({ active }) => (
                <Link
                  to={Routes.payment}
                  className={getLinkStyles(active)}
                  aria-current={getAriaCurrent(active)}
                >
                  <IconSettings {...iconProps} />
                  Payment
                </Link>
              )}
            </Menu.Item>
          )}

          <Divider />

          <Menu.Item>
            {({ active }) => (
              <Link
                to={Routes.guide}
                className={getLinkStyles(active)}
                aria-current={getAriaCurrent(active)}
              >
                <IconInfoCircle {...iconProps} />
                Getting started
              </Link>
            )}
          </Menu.Item>

          <Menu.Item>
            {({ active }) => (
              <Link
                to={Routes.walkthrough}
                className={getLinkStyles(active)}
                aria-current={getAriaCurrent(active)}
              >
                <IconBulb {...iconProps} />
                Referral walkthrough
              </Link>
            )}
          </Menu.Item>

          <Menu.Item>
            {({ active }) => (
              <a href={emailSupportHref} className={getLinkStyles(active)}>
                <IconMessages {...iconProps} />
                Support
              </a>
            )}
          </Menu.Item>

          <Divider />

          <Menu.Item>
            {({ active }) => (
              <a href={Routes.signOut} className={getLinkStyles(active)}>
                Log out
              </a>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

function getLinkStyles(isActive: boolean) {
  return classNames(
    isActive && "bg-cream-100",
    "flex p-2 gap-x-3 rounded-xl mx-3 items-center w-[calc(100%-1.5rem)]",
    // logout button styles
    "last:text-danger-600 last:font-bold last:p-3.5"
  );
}

function getAriaCurrent(isActive: boolean) {
  return isActive ? "page" : undefined;
}
