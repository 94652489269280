import type { SafetyQuestion } from "../types/common";
import type { RawSafetyQuestion } from "../types/requests";

export function prepSafetyQuestion(
  question?: RawSafetyQuestion | null
): SafetyQuestion | null {
  if (!question) {
    return null;
  }

  return {
    attribute: question.attribute,
    attributeDetails: question.attribute_details ?? "",
    falseOption: question.option_for_false,
    trueOption: question.option_for_true,
    label: question.label,
    labelDetails: question.label_details ?? "",
    requiresDetails: Boolean(question.require_details_if_true),
    clinicianLabel: question.label_for_clinician,
  };
}

export function prepSafetyQuestions(
  rawQuestions?: RawSafetyQuestion[]
): SafetyQuestion[] {
  const questions = rawQuestions
    ?.map(prepSafetyQuestion)
    .filter((question): question is SafetyQuestion => Boolean(question));

  return questions || [];
}
