import { ScanDocument } from "../types/common";
import { RawScanDocument } from "../types/requests";

export function prepScanDocument(doc?: RawScanDocument): ScanDocument | null {
  if (!doc) {
    return null;
  }

  return {
    id: doc.id,
    url: doc.url,
    name: doc.name,
    contentType: doc.content_type,
  };
}

export function prepScanDocuments(rawDocs?: RawScanDocument[]): ScanDocument[] {
  const docs = rawDocs
    ?.map(prepScanDocument)
    .filter((doc): doc is ScanDocument => Boolean(doc));

  return docs || [];
}
