import { PaymentInfo } from "../types/common";
import { RawPaymentInfo } from "../types/requests";

export function prepPaymentInfo(info?: RawPaymentInfo): PaymentInfo | null {
  if (!info) {
    return null;
  }

  return {
    accountName: info.account_name ?? "",
    accountNumber: info.account_number ?? "",
    companyAddress: info.company_address ?? "",
    sortCode: info.sort_code ?? "",
    vatNumber: info.vat_number ?? "",
  };
}
