import { Button } from "@scandotcom/react";
import { IconMail, IconMessages, IconPhone } from "@tabler/icons";
import classNames from "classnames";
import React from "react";

import openLiveChat, { isLiveChatEnabled } from "utils/openLiveChat";

interface ContactUsProps {
  className?: string;
}
export const ContactUs = ({ className }: ContactUsProps) => {
  const wrapperStyles = classNames(
    "border p-10 font-maison rounded-xl",
    "bg-white border-cream-200 text-cypress-900",
    className
  );

  const iconProps = {
    className: "w-6",
    strokeWidth: 1.5,
  };

  const contactLinkStyles = classNames(
    "mb-3 flex flex-row gap-x-4 font-medium"
  );

  const email = "help@uk.scan.com";
  const phoneNumber = "01277 674 889";

  return (
    <div className={wrapperStyles}>
      <h3 className="mb-2 font-maison-extended text-xl font-bold text-cypress-800">
        Need help?
      </h3>
      <p className="mb-6 text-base font-normal">
        Our friendly team are here to help.
      </p>

      {isLiveChatEnabled && (
        <button onClick={() => openLiveChat()} className={contactLinkStyles}>
          <IconMessages {...iconProps} />
          Chat with us
        </button>
      )}

      <a href={`tel:${phoneNumber}`} className={contactLinkStyles}>
        <IconPhone {...iconProps} />
        {phoneNumber}
      </a>

      <a href={`mailto:${email}`} className={contactLinkStyles}>
        <IconMail {...iconProps} />
        {email}
      </a>
    </div>
  );
};
