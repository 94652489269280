import { H1 } from "@scandotcom/react";
import classNames from "classnames";
import React, { ReactNode } from "react";
import { Breadcrumb, BreadcrumbItem } from "./Breadcrumb";

interface PageInfoProps {
  name?: string;
  children?: ReactNode;
  breadcrumbs?: BreadcrumbItem[];
  description?: string;
}

interface PageInfoItemProps {
  children: ReactNode;
  className?: string;
  label: string;
}

export const PageInfo = ({
  name,
  breadcrumbs,
  description,
  children,
}: PageInfoProps) => {
  const containerStyles = classNames(
    "container flex flex-col flex-wrap gap-4 py-4",
    "lg:flex-row lg:flex-nowrap lg:py-0"
  );

  const childStyles = classNames(
    "flex w-full flex-row flex-wrap items-center gap-4",
    "text-sm font-medium text-cypress-900 font-maison",
    "lg:gap-x-10 lg:py-6 lg:pl-8"
  );

  const nameStyles = classNames(
    "lg:py-6",
    children ? "lg:border-r lg:pr-14 space-y-1.5 border-cream-200" : "space-y-4"
  );

  return (
    <div className="mb-6 border-b border-cream-200 bg-white text-cypress-900">
      <div className={containerStyles}>
        <div className={nameStyles}>
          {breadcrumbs && (
            <Breadcrumb
              history={breadcrumbs}
              className="max-w-full sm:min-w-max"
            />
          )}

          <H1 size="2xl">{name}</H1>

          {description && (
            <p className="max-w-[37rem] text-base font-normal text-cypress-800">
              {description}
            </p>
          )}
        </div>

        {children && <div className={childStyles}>{children}</div>}
      </div>
    </div>
  );
};

const Item = ({ className, label, children }: PageInfoItemProps) => {
  const wrapperStyles = classNames(
    "text-cypress-900 font-medium text-sm flex flex-col justify-around min-h-[56px]",
    className
  );

  const labelStyles =
    "font-maison-extended text-xs font-bold uppercase text-cypress-800 tracking-wider";

  return (
    <div className={wrapperStyles}>
      <div className={labelStyles}>{label}</div>
      {children}
    </div>
  );
};

PageInfo.Item = Item;
