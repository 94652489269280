import { Tab } from "@headlessui/react";
import { Heading } from "@scandotcom/react";
import * as React from "react";
import { Link, useLocation } from "react-router-dom";
import { currentUser } from "utils/PortalContext";
import PageUpdatePaymentInformation from "./PageUpdatePaymentInformation";
import PageUpdateProfile from "./PageUpdateProfile";

interface Props {
  defautlTabIndex?: number;
}

const transitionStyle = { transition: "all 300ms ease-in-out" };

function PageAccount({ defautlTabIndex }: Props) {
  const [selectedTab, setSelectedTab] = React.useState(
    () => defautlTabIndex || 0
  );
  const [sliderStyle, setSliderStyle] = React.useState<React.CSSProperties>();
  const activeTabRef = React.useRef<HTMLAnchorElement | null>(null);

  const location = useLocation();
  const userData = currentUser();

  const showPaymentInformationLink = Boolean(userData?.canEditPaymentInfo);

  const handleMouseEnter: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    const el = e.target;

    if (el instanceof HTMLAnchorElement) {
      setSliderStyle((style) => ({
        ...style,
        transform: `translateX(${el.offsetLeft}px)`,
        width: el.clientWidth,
      }));
    }
  };

  const handleMouseLeave = () => {
    initSlider();
  };

  const initSlider = () => {
    const el = activeTabRef.current;
    if (el) {
      setSliderStyle((style) => ({
        ...style,
        transform: `translateX(${el.offsetLeft}px)`,
        width: el.clientWidth,
      }));
      setTimeout(
        () => setSliderStyle((style) => ({ ...style, ...transitionStyle })),
        10
      );
    }
  };

  React.useEffect(() => {
    const { pathname } = location;
    if (pathname === "/account/profile") {
      setSelectedTab(0);
      return;
    }

    if (pathname === "/account/payment") {
      setSelectedTab(1);
      return;
    }
  }, [location]);

  React.useEffect(() => {
    initSlider();
  }, []);

  return (
    <>
      <div className="bg-white pt-6">
        <Heading variant="h1" size="4xl" className="container mb-2">
          Account Settings
        </Heading>
        <p className="container mb-5 text-neutral-600">
          Manage your Scan.com account
        </p>
        <Tab.Group selectedIndex={selectedTab} onChange={setSelectedTab}>
          <Tab.List
            onMouseLeave={handleMouseLeave}
            className="container relative -mb-[1px] space-x-8 font-maison text-sm font-medium text-cream-800"
          >
            <Tab
              as={Link}
              to="/account/profile"
              onMouseEnter={handleMouseEnter}
              className={`inline-block border-b-2 py-3 outline-none ${
                selectedTab === 0
                  ? " font-bold text-cypress-800"
                  : "border-x-2 border-transparent hover:text-cypress-800"
              }`}
              ref={selectedTab === 0 ? activeTabRef : undefined}
            >
              My profile
            </Tab>
            {showPaymentInformationLink && (
              <Tab
                as={Link}
                to="/account/payment"
                onMouseEnter={handleMouseEnter}
                className={`inline-block border-b-2  py-3 outline-none ${
                  selectedTab === 1
                    ? " font-bold text-cypress-800"
                    : "border-x-2 border-transparent hover:text-cypress-800"
                }`}
                ref={selectedTab === 1 ? activeTabRef : undefined}
              >
                Payment
              </Tab>
            )}
            <div
              aria-hidden
              className="absolute left-0 bottom-0 z-0 !m-0 border-b-2 border-pink-300 px-8"
              style={sliderStyle}
            />
          </Tab.List>
          <Tab.Panels className="border-t border-t-cream-300 bg-cream-100">
            <Tab.Panel>
              <PageUpdateProfile />
            </Tab.Panel>
            {showPaymentInformationLink && (
              <Tab.Panel>
                <PageUpdatePaymentInformation />
              </Tab.Panel>
            )}
          </Tab.Panels>
        </Tab.Group>
      </div>
    </>
  );
}

export default PageAccount;
