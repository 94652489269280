import { prepPageInfo } from "@services/scan/utils/prepPageInfo";
import { client } from "../../client";
import type { RawPatient } from "../../types/requests";
import { prepPatients } from "../../utils/prepPatients";

export interface GetPatientsProps {
  order?: "created_at" | "last_name" | "email";
  direction?: "ASC" | "DESC";
  offset?: number;
  limit?: number;
  query?: string;
}

interface RawResponse {
  data: RawPatient[];
  page_info: {
    has_more: boolean;
    limit: number;
    offset: number;
    order: string;
    order_direction: string;
    search_query: string;
    total_count: number;
  };
}

export async function getPatients({
  order = "created_at",
  direction = "DESC",
  offset = 0,
  limit = 50,
  query = "",
}: GetPatientsProps) {
  const response = await client<RawResponse>({
    method: "get",
    url: "/api/v1/patients",
    params: {
      limit,
      order,
      offset,
      search_query: query,
      order_direction: direction,
    },
  });

  const patients = prepPatients(response?.data);
  const pageInfo = prepPageInfo(response?.page_info);

  return { patients, pageInfo };
}
