import type {
  Referral,
  SpecialistConsultation,
  SpecialistConsultationStatus,
} from "../types/common";
import type { RawReferral, RawSpecialistConsultation } from "../types/requests";
import { prepSpecialist } from "./prepSpecialist";

export function prepSpecialistConsultation(
  specialistConsultation?: RawSpecialistConsultation
): SpecialistConsultation | undefined {
  if (!specialistConsultation) {
    return;
  }

  const patient = prepReferralPatient(
    specialistConsultation.patient_id,
    specialistConsultation.patient_information
  );

  const specialist = prepSpecialist(specialistConsultation.specialist);

  return {
    type: "SpecialistConsultation",
    id: specialistConsultation.id,
    status: specialistConsultation.status as SpecialistConsultationStatus,
    specialistType: specialistConsultation.specialist_type,
    reference: specialistConsultation.reference,
    referralInformation: specialistConsultation.referral_information,
    internalReference: specialistConsultation.internal_reference,
    createdAt: specialistConsultation.created_at,
    updatedAt: specialistConsultation.updated_at,
    draftedAt: specialistConsultation.drafted_at,
    referredAt: specialistConsultation.referred_at,
    specialist,
    patient,
  };
}

function prepReferralPatient(
  id: string,
  rawPatient: RawReferral["patient_information"]
): Referral["patient"] {
  const title = rawPatient.title ?? "";
  const firstName = rawPatient.first_name ?? "";
  const lastName = rawPatient.last_name ?? "";
  const fullName = `${firstName} ${lastName}`;

  const email = rawPatient.email ?? "";
  const phone = rawPatient.phone ?? "";
  const alternatePhone = rawPatient.alternate_phone ?? "";
  const gender = rawPatient.gender ?? "";
  const dateOfBirth =
    rawPatient.date_of_birth_iso ?? rawPatient.date_of_birth ?? "";

  const line1 = rawPatient.address_line_1 ?? "";
  const line2 = rawPatient.address_line_2 ?? "";
  const city = rawPatient.address_city ?? "";
  const postcode = rawPatient.address_postcode ?? "";

  const gpDetails = rawPatient.gp_details ?? "";

  return {
    id,
    title,
    firstName,
    lastName,
    fullName,
    email,
    dateOfBirth,
    gender,
    gpDetails,
    phone,
    alternatePhone,
    address: {
      line1,
      line2,
      city,
      postcode,
    },
  };
}
