import { XCircleIcon } from "@heroicons/react/20/solid";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { uploadReferralDocument } from "@services/scan";
import { IconFileDescription } from "@tabler/icons";
import React, { useEffect, useMemo, useState } from "react";
import { useMutation } from "react-query";
import { Doc } from "../UploadAdditionalDocuments";

const Error: React.FC<{ error: string }> = ({ error }) => {
  return (
    <div className="flex w-full gap-1 text-danger-800">
      <InformationCircleIcon className="h-4 w-4" />
      <span className="text-xs">{error}</span>
    </div>
  );
};

interface Props {
  referralId: string;
  file: File;
  removeFile: () => void;
  documentUploaded: (value: Doc) => void;
}

export const UploadingFile: React.FC<Props> = ({
  referralId,
  file,
  removeFile,
  documentUploaded,
}) => {
  const [error, setError] = useState("");
  const [uploadPercent, setUploadPercent] = useState(0);
  const abortController = useMemo(() => new AbortController(), [file]);

  const mutation = useMutation(uploadReferralDocument);

  const uploadFile = () => {
    mutation.mutate(
      {
        file,
        referralId,
        onUploadProgress,
        signal: abortController.signal,
      },
      {
        onSuccess: (data) => {
          if (data.cancelled) return;
          if (data.success && data.document) {
            documentUploaded(data.document);
            return;
          }
          setUploadPercent(100);
          setError(data.errors.map((error) => error.fullMessage).join(", "));
        },
      }
    );
  };

  function onUploadProgress(event: ProgressEvent<EventTarget>) {
    const percent = (event.loaded * 100) / event.total;
    setUploadPercent(Math.round(percent * 0.95));
  }

  const onRemoveFile = () => {
    if (uploadPercent < 100) {
      abortController.abort();
    }

    removeFile();
  };

  useEffect(() => {
    uploadFile();
  }, []);

  return (
    <div className="space-y-2">
      <div className="flex items-center gap-4 rounded-lg border border-cream-300 bg-white p-4">
        <IconFileDescription className="flex-shrink-0" />
        <div className="flex flex-grow items-center">
          <div className="w-1/2 ">
            <p className="w-full truncate text-sm font-semibold">{file.name}</p>
            <p className="text-xs font-medium text-neutral-500">
              {uploadPercent}% Uploaded
            </p>
          </div>

          <div className="relative h-2 w-1/2 rounded-lg bg-neutral-100">
            <div
              className="absolute h-2 rounded-lg bg-neutral-500"
              style={{ width: `${uploadPercent}%` }}
            />
          </div>
        </div>
        <button
          type="button"
          onClick={onRemoveFile}
          className="ml-auto h-5 w-5 flex-shrink-0 text-red"
        >
          <span className="sr-only">Remove document</span>
          <XCircleIcon aria-hidden />
        </button>
      </div>
      {error ? <Error error={error} /> : null}
    </div>
  );
};
