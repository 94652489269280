import { createReferral, updateReferral } from "@services/scan";
import { ReferralMutationFields } from "@services/scan/types/requests";
import { useMutation, useQueryClient } from "react-query";

export function useMutateReferral() {
  const queryClient = useQueryClient();

  const mutation = useMutation(mutateReferral, {
    onSuccess: (data) => {
      if (!data.success || !data.referral) {
        return;
      }

      queryClient.setQueryData(["referral", data.referral.id], data.referral);
    },
  });

  function mutateReferral(fields: Partial<ReferralMutationFields>) {
    return fields?.referralId ? updateReferral(fields) : createReferral(fields);
  }

  return mutation;
}
