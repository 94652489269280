import { Button } from "@scandotcom/react";
import { respondToImageRequests } from "@services/scan";
import { getImageRequests } from "@services/scan/requests/documents/getImageRequests";
import { ImageRequest } from "@services/scan/types/common";
import { IconCheck, IconClock } from "@tabler/icons";
import React, { useMemo } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Panel } from "../Panel";

interface RequestProps {
  referralId: string;
  request: ImageRequest;
}
const Request = ({ referralId, request }: RequestProps) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(respondToImageRequests, {
    onSuccess: () => {
      queryClient.invalidateQueries(["image requests", { referralId }]);
    },
  });

  function onClick(action: "approve" | "deny") {
    mutation.mutate({ requestId: request.id, referralId, action });
  }

  const wrapperStyles =
    "flex flex-wrap items-center gap-x-4 rounded-xl bg-cream-100 p-8 font-maison text-sm font-normal text-cream-800 w-full";

  return (
    <div className={wrapperStyles}>
      <div className="flex flex-col sm:max-w-[80%] sm:justify-center">
        <span className="text-base font-semibold text-cypress-800">
          {request.recipientEmail}
        </span>
      </div>
      <div className="mt-2 h-fit w-full sm:ml-auto sm:mt-0 sm:w-fit">
        {request.approvedAt ? (
          <Button size="sm" kind="secondary" onClick={() => onClick("deny")}>
            Revoke access
          </Button>
        ) : (
          <>
            <Button size="sm" kind="secondary" onClick={() => onClick("deny")}>
              Deny
            </Button>
            <Button
              size="sm"
              onClick={() => onClick("approve")}
              className="ml-4"
            >
              Approve
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

interface ImageRequestsGroupProps {
  referralId: string;
  titleIcon: React.ReactElement;
  title: string;
  requests: ImageRequest[];
}
const ImageRequestsGroup = ({
  referralId,
  titleIcon,
  title,
  requests,
}: ImageRequestsGroupProps) => {
  if (!requests.length) return null;

  const titleStyles =
    "text-cypress-800 font-bold font-maison-extended tracking-wider text-xs flex flex-row gap-2 uppercase items-center mb-2";

  return (
    <>
      <div className={titleStyles}>
        {titleIcon}
        {title}
      </div>
      <div className="mb-6 space-y-3">
        {requests.map((request) => (
          <Request key={request.id} referralId={referralId} request={request} />
        ))}
      </div>
    </>
  );
};

interface ImageRequestsProps {
  referralId: string;
}

export const ImageRequests = ({ referralId }: ImageRequestsProps) => {
  const { data: requests } = useQuery(
    ["image requests", { referralId }],
    () => getImageRequests(referralId),
    { enabled: Boolean(referralId) }
  );

  const [approved, unapproved] = useMemo(
    () => sortRequests(requests),
    [requests]
  );

  if (!requests?.length) return null;

  return (
    <Panel title="Manage Scan Image Access">
      <ImageRequestsGroup
        referralId={referralId}
        titleIcon={<IconCheck className="w-5" strokeWidth={1.5} />}
        title="Active"
        requests={approved}
      />

      <ImageRequestsGroup
        referralId={referralId}
        titleIcon={<IconClock className="w-5" strokeWidth={1.5} />}
        title="Pending Requests"
        requests={unapproved}
      />
    </Panel>
  );
};

function sortRequests(requests: ImageRequest[] = []): ImageRequest[][] {
  return requests?.reduce(
    (acc, req) => {
      if (!req) return acc;
      if (req.approvedAt) acc[0].push(req);
      else acc[1].push(req);
      return acc;
    },
    [[], []] as ImageRequest[][]
  );
}
