import { IconArrowRight, IconChevronDown, IconDots } from "@tabler/icons";
import classNames from "classnames";
import React, { ComponentProps, ReactNode } from "react";
import { Link } from "react-router-dom";
import { ContextMenu } from "@scandotcom/react";

export const Table = ({ className, ...props }: ComponentProps<"table">) => {
  const tableStyles = classNames(
    "w-full rounded-lg shadow font-medium bg-white shadow-cypress-900/10 overflow-x-scroll min-h-[700px]",
    className
  );

  return (
    <div className={tableStyles}>
      <table {...props} className="w-full border-collapse" />
    </div>
  );
};

const Tr = ({ className, onClick, ...props }: ComponentProps<"tr">) => {
  const rowStyles = classNames(
    "text-left",
    onClick &&
      "cursor-pointer border-t border-transparent transition duration-150 hover:bg-cream-50 hover:border-cream-200",
    // fix to get top and bottom borders on hover.
    "[&:hover_+_tr]:border-t-cream-200 [&:hover_+_tr]:border-t",
    className
  );

  return <tr {...props} className={rowStyles} onClick={onClick} />;
};

const Head = ({ className, children, ...props }: ComponentProps<"thead">) => {
  return (
    <thead {...props} className={className}>
      <Tr className="border-b border-cream-200">{children}</Tr>
    </thead>
  );
};

const Th = ({ className, ...props }: ComponentProps<"th">) => {
  const thStyles = classNames(
    "font-maison-extended text-xs font-bold uppercase tracking-wider",
    "px-2 py-4 text-cypress-800 first:pl-4 last:pr-4 whitespace-nowrap",
    className
  );

  return <th {...props} className={thStyles} />;
};

interface SortableThProps extends ComponentProps<"button"> {
  scope?: string;
  direction?: string;
}

const SortableTh = ({
  className,
  children,
  onClick,
  title,
  scope,
  direction,
  ...props
}: SortableThProps) => {
  const thStyles = classNames(
    "font-maison-extended text-xs tracking-wider text-cypress-800",
    "px-2 py-4 first:pl-4 last:pr-4 whitespace-nowrap",
    className
  );

  const chevronStyles = classNames(
    "ml-1.5 h-3 w-3",
    direction !== "DESC" && "rotate-180"
  );

  return (
    <th className={thStyles} scope={scope}>
      <button
        type="button"
        onClick={onClick}
        title={title}
        className="flex items-center font-bold uppercase"
        {...props}
      >
        {children}

        {direction && (
          <IconChevronDown
            aria-hidden
            strokeWidth={3}
            className={chevronStyles}
          />
        )}
      </button>
    </th>
  );
};

const TBody = ({ className, ...props }: ComponentProps<"tbody">) => {
  const bodyStyles = classNames(className, "text-sm");

  return <tbody {...props} className={bodyStyles} />;
};

const Td = ({ className, children, ...props }: ComponentProps<"td">) => {
  const tdStyles = classNames(
    className,
    "px-2 py-4 first:pl-4 last:pr-4",
    !children && "text-cream-800"
  );

  return (
    <td {...props} className={tdStyles}>
      {children || "-"}
    </td>
  );
};

type MenuLink = { name: string; href: string };
type MenuAction = { name: string; onClick: () => void };
interface MenuProps {
  links: (MenuLink | false)[];
  actions: (MenuAction | false)[];
}

const Menu = ({ links, actions }: MenuProps) => {
  const buttonProps = {
    className:
      "grid place-items-center w-9 h-9 rounded-lg bg-transparent border border-transparent transition duration-150 hover:bg-white hover:border-cream-300 hover:shadow-sm",
    children: (
      <>
        <span className="sr-only">Menu</span>
        <IconDots className="mx-auto h-6 w-6 text-cypress-900" />
      </>
    ),
    onClick: (e) => e.stopPropagation(),
  };

  const filteredActions = actions.filter((action): action is MenuAction =>
    Boolean(action)
  );

  const filteredLinks = links.filter((link): link is MenuLink => Boolean(link));

  return (
    <td>
      <ContextMenu buttonProps={buttonProps} hasArrow>
        {filteredLinks?.map(({ name, href }) => (
          <ContextMenu.Item
            as={Link}
            key={name}
            to={href}
            onClick={(e) => e.stopPropagation()}
          >
            {name}
            <IconArrowRight className="h-5 w-5" strokeWidth={1.5} aria-hidden />
          </ContextMenu.Item>
        ))}

        {filteredActions?.length > 0 && (
          <>
            <hr className="mb-3 mt-2 w-full bg-cream-300" />
            <span className="px-4 font-maison-extended text-xxs font-bold uppercase tracking-wider text-neutral-400">
              Actions
            </span>
          </>
        )}

        {filteredActions?.map(({ name, onClick }) => (
          <ContextMenu.Item
            as="button"
            key={name}
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              onClick();
            }}
          >
            {name}
          </ContextMenu.Item>
        ))}
      </ContextMenu>
    </td>
  );
};

Table.Head = Head;
Table.TBody = TBody;
Table.Tr = Tr;
Table.Th = Th;
Table.Td = Td;
Table.Menu = Menu;
Table.SortableTh = SortableTh;
